import { M_Configuration } from "../../shared/models/Models_Configuration";

export const AFE_DEFAULT_CONFIGURATION = {
	...M_Configuration,
	apiURL: `http://box.api.cubesave.cz`,
	devApiURL: `http://172.16.1.5:12250`,
	redirectTimeout: "1m",
	debuggerDuration: "30m",
	updateWaitTime: "10m",
};
