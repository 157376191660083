import { rootConnector } from "./api-connector";
import { GenericServices, QueryStringManager, ResponseHandler } from "@green24/js-utils";

class HASAMMaintenanceServices extends GenericServices {
	stopFillingOrPaymentProcess() {
		return this.sendRequest({
			method: "POST",
			url: `${this.url}/stop`,
		}, ResponseHandler.plain);
	}

	resetDevice() {
		return this.sendRequest({
			method: "POST",
			url: `${this.url}/reset`,
		}, ResponseHandler.plain);
	}

	printTestTicket() {
		return this.sendRequest({
			method: "POST",
			url: `${this.url}/print`,
		}, ResponseHandler.plain);
	}

	initiateManualPayment(cost) {
		return this.sendRequest({
			method: "POST",
			url: `${this.url}/pay${new QueryStringManager({cost})}`,
		}, ResponseHandler.plain);
	}

	moveCash(deviceID) {
		return this.sendRequest({
			method: "POST",
			url: `${this.url}/move${new QueryStringManager({deviceID})}`,
		});
	}

	/**
	 * Move some cash
	 * ---
	 * Moves only some cash to the cashbox
	 * @param {number} deviceID
	 * @param {{
	 *     mustRemain: Array<{count: number, value: number, currency: string}>
	 * }} body
	 * @return {Promise<Response|HttpResponseError>}
	 */
	moveSomeCash(deviceID, body) {
		return this.sendRequest({
			method: "POST",
			url: `${this.url}/moveSome${new QueryStringManager({deviceID})}`,
			body: JSON.stringify(body),
		}, ResponseHandler.plain);
	}

	initiateFillProcess(deviceID) {
		return this.sendRequest({
			method: "POST",
			url: `${this.url}/fill${new QueryStringManager({deviceID})}`,
		}, ResponseHandler.plain);
	}

	clearDeviceCounter(deviceID) {
		return this.sendRequest({
			method: "POST",
			url: `${this.url}/clear${new QueryStringManager({deviceID})}`,
		});
	}

	clearCoinDeviceCounter(deviceID) {
		return this.sendRequest({
			method: "POST",
			url: `${this.url}/coin/clear${new QueryStringManager({deviceID})}`,
		}, ResponseHandler.plain);
	}

	getDevicesStatus() {
		return this.sendRequest({
			url: `${this.url}/status`,
		});
	}

	getDevicesContents() {
		return this.sendRequest({
			url: `${this.url}/content`,
		});
	}

	printDeviceContents() {
		return this.sendRequest({
			method: "POST",
			url: `${this.url}/content/print`,
		}, ResponseHandler.plain);
	}
}

class BoxMaintenanceServices extends GenericServices {
	emergencyOpen() {
		return this.sendRequest({
			method: "POST",
			url: `${this.url}/open/emergency`,
		}, ResponseHandler.plain);
	}
}

export const hasamMaintenanceServices = new HASAMMaintenanceServices(rootConnector, `/maintenance/hasam`);
export const boxMaintenanceServices = new BoxMaintenanceServices(rootConnector, `/maintenance/box`);
