import React from 'react';
import { route } from 'preact-router';

export class Redirect extends React.Component {
	componentDidMount() {
		// eslint-disable-next-line react/prop-types
		route(this.props.to, true);
	}

	render() {
		return null;
	}
}
