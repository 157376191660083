export class StoreThunk {
	constructor(store) {
		this.dispatch = (...params) => store.dispatch(...params);
		this.getState = (...params) => store.getState(...params);
	}

	connect(thunk) {
		return thunk(this.dispatch, this.getState);
	}
}
