import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses, Jiffy } from "@green24/js-utils";
import ThemeHeader from "../../components/Theme/ThemeHeader";
import ButtonsConstructor from "../../components/Input/Button/ButtonsConstructor";
import Interpret from "../../components/Localization/Interpret";
import Keyboard from "../../components/other/Keyboard/Keyboard";
import { E_Keyboard_Type } from "../../models/constants/Enums_Keyboard";
import ButtonComponent from "../../components/Input/Button/ButtonComponent";
import { ComponentStateUtils } from "../../utils/ComponentStateUtils";
import { applicationThunk } from "../../store/store";
import IdleTimeoutCounter from "../../components/other/Idle/IdleTimeoutCounter";

class VoucherManager extends Component {
	constructor() {
		super();

		this.state = {
			vouchers: [],
			selectedVoucherIndex: 0,
		}
	}

	componentDidMount() {
		applicationThunk.setIdleTimeout(Jiffy.formatToMs("2m"));
	}

	render({className, style, onContinue, backButtonProps}, {selectedVoucherIndex, vouchers}) {
		const limit = 5;

		return (
			<section className={combineClasses("voucher-manager", className)} style={style}>
				<ThemeHeader/>
				<div className={"content"}>
					<div className={"vouchers"}>
						<h1><Interpret id={"vouchers_countWithLimit"} params={{
							amount: vouchers.length,
							limit,
						}}/></h1>

						{
							vouchers.map((voucher, i) => {
								return (
									<div className={combineClasses("voucher", i == selectedVoucherIndex && "active")} key={i}>
										<ButtonComponent
											className={"voucher-button"}
											text={voucher || "enterVoucherNumber"}
											textTranslated={!!voucher}
											onClick={() => this.setState({selectedVoucherIndex: i})}
										/>
										<ButtonComponent className={"remove"} icon={"times"} onClick={() => this._removeVoucher(i)}/>
									</div>
								)
							})
						}
						<ButtonsConstructor buttons={[
							vouchers.length < limit &&
							{
								text: vouchers.length > 0 ? "addAnotherVoucher" : "addVoucher",
								action: () => {
									this.setState(state => ({
										vouchers: [...state.vouchers, ''],
										selectedVoucherIndex: vouchers.length,
									}));
								},
							}
						]}/>
					</div>

					<div className={"voucher-detail"}>
						<Keyboard
							type={E_Keyboard_Type.EMAIL}
							view={E_Keyboard_Type.NUMERIC}
							block={true}
							value={vouchers[selectedVoucherIndex]}
							onModify={(value) => this._keyboardOnModify(value)}
							options={{
								maxLength: 16,//TBD: Max length?
							}}
						/>
					</div>
				</div>

				<nav className={"navigation-buttons"}>
					<IdleTimeoutCounter>
						{({secondsRemaining}) => {
							return (
								<ButtonsConstructor buttons={[
									{
										icon: "chevron-left",
										text: secondsRemaining > 0 ? <><Interpret id={"back"}/>&nbsp;({secondsRemaining})</> : "back",
										className: "back",
										...backButtonProps,
									},
									{
										text: "voucherAmountExceedsPriceMessage",
										action: () => null,
										className: "no-style voucher-message",
									},
									{
										icon: "chevron-right",
										className: "continue",
										text: "continue",
										action: () => onContinue(vouchers),
									}
								]}/>
							)
						}}
					</IdleTimeoutCounter>
				</nav>
			</section>
		);
	}

	_keyboardOnModify(value) {
		const {selectedVoucherIndex} = this.state;

		this.setState(state => {
			let newVouchers = [...state.vouchers];
			newVouchers[selectedVoucherIndex] = value;

			return {
				vouchers: newVouchers
			};
		});
	}

	_removeVoucher(index) {
		const {selectedVoucherIndex} = this.state;

		ComponentStateUtils.removeIndex(this, "vouchers", index);

		if(selectedVoucherIndex == index) {
			this.setState({selectedVoucherIndex: Math.max(0, selectedVoucherIndex - 1)});
		}
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			vouchers: PropTypes.array,
			backButtonProps: PropTypes.object,

			onContinue: PropTypes.func,
		}
	}

	static get defaultTypes() {
		return {
			vouchers: [],
			onContinue: () => null,
		}
	}
}

export default VoucherManager;
