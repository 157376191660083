import { IS } from "../classes/IS";
import { Calc } from "../classes/Math/Calc";

/**
 * @typedef TypeofOptions
 * @type {"object"|"string"|"array"|"null","undefined","number","symbol","function","bigint","boolean"}
 */

/**
 * Get
 * ---
 * Returns value from Object[path]
 * @param {Object} object Starting object
 * @param {String|Array} path String/Array path eg. "prop1.prop2.prop.3" or [prop1, prop2, prop3]
 * @param {*} fallback Return value if path IS not string/Array or if the target destination cannot be reached
 * @param {Function} processor Process retrieved value
 * @returns {null|*} Returns value if found or {null} otherwise
 */
export const get = (object, path, fallback = null, processor = v => v) => {
	if (!IS.valid(object)) {
		return fallback;
	}

	let target = object;

	if (typeof path === "string") {
		path = path.split(".");
	}

	if (!Array.isArray(path)) {
		return IS.valid(target) ? processor(target) : fallback;
	}

	for (let i = 0; i < path.length; i++) {
		let pathSegment = `${path[i]}`;

		//Special selector; "$" == select last item
		if (pathSegment === "$") {
			if (Array.isArray(target)) {
				target = target[target.length - 1];
			} else {
				return fallback;
			}
		} else if (pathSegment.match(/^#/g)) { //Special selector; "#0" == select item with id 0 based on simple template where object contains "id" property (like so {id: 0})
			if (Array.isArray(target)) {
				target = target.find(item => item.id == pathSegment.replace("#", ''));
			} else {
				return fallback;
			}
		} else {
			if (IS.valid(target) && IS.property(target, pathSegment)) {
				target = target[pathSegment];
			} else {
				return fallback;
			}
		}
	}
	return processor(target);
};

/**
 * Parse Boolean
 * ---
 * Parse boolean from string if possible
 * @param {String} value Possible string
 * @returns {null|Boolean}
 */
export const parseBool = (value) => {
	if (IS.string(value)) {
		let testableString = value.toLowerCase().trim();

		if (/^((false)|(disabled)|(off)|(0))$/.test(testableString)) {
			return false;
		}
		if (/^((true)|(enabled)|(on)|[1-9]\d*)$/.test(testableString)) {
			return true;
		}
	}
	else if(IS.number(value)) {
		return !!value;
	}
	else if(IS.boolean(value)) {
		return value;
	}

	return null;
};

/**
 * Format as price
 * ---
 * Formats value as a price
 * @param {Number} value
 * @param {String} currency
 * @param {String} locale
 * @param {Boolean} trimDecimals
 * @returns {string}
 */
export function formatAsPrice(value, currency = "CZK", locale = "cs", trimDecimals = false) {
	let result = new Intl.NumberFormat(locale || "cs", {style: "currency", currency: currency}).format(value);

	if(trimDecimals) {
		return result.replace(/\D00(?=\D*$)/g, '');
	}
	return result;
}

/**
 * Copy to clipboard
 * ---
 * Copies the text into the clipboard if the clipboard IS allowed and IS called on HTTPS web page.
 * @param text
 * @returns Promise - Reject returns (writeTextError, clipboardUnavailable) booleans
 */
export const copyToClipboard = (text) => {
	return new Promise(((resolve, reject) => {
		if (navigator.clipboard && navigator.clipboard.writeText) {
			navigator.clipboard.writeText(`${text}`).then(resolve(), reject(true, false));
		} else {
			reject(false, true);
		}
	}))
};

export function formatAsDate(date, locale) {
	return date && new Date(date).toLocaleString(locale);
}

/**
 * Format bytes
 * ---
 * Returns formatted bytes in the closest format e.g. 5_000B => 5kB or 1_000_000B => 1mB
 * @param {Number} bytes
 * @param {Number} decimals
 * @param {Boolean} simpleByteSize If use simple byte size (1000B => 1kB) or real (1024 => 1kB)
 * @returns {string}
 */
export const formatBytes = (bytes, decimals = 2, simpleByteSize = false) => {
	if (bytes === 0) return '0 Bytes';

	const k = simpleByteSize ? 1000 : 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * Wrap with
 * ---
 * Promise wrap
 * @param {String} as
 * @param {Promise<*>} pendingPromise
 * @return {Promise<*>}
 */
export const wrapWith = (as, pendingPromise) => (
	new Promise((resolve, reject) => {
		let result = {};
		pendingPromise.then(data => {
			result[as] = data;
			resolve(result);
		}, data => {
			result[as] = data;
			reject(result);
		});
	})
);

export const openInNewTab = (url) => {
	let a = document.createElement("a");
	a.target = "_blank";
	a.href = url;
	a.rel = "noopener noreferer";
	a.click();
};

export const createSignal = (signalController) => {
	abortSignal(signalController);

	if(typeof AbortController != "undefined") {
		return new AbortController();
	}
};

export const abortSignal = (signalController) => {
	if(signalController && signalController.abort) {
		signalController.abort();
	}
};

export const combineClasses = (...params) => {
	return params.filter(param => !!param && `${param}`.trim()).join(" ");
};

export const isWithinClampRange = (value, min, max) => {
	value = parseFloat(value);
	return value >= min && value <= max;
};

/**
 * Resolve item name
 * ---
 * Resolves item name when only the main object is provided along with a path to the main and alternative result which handles the missing value
 * ```
 *  let data = {name: "abc"}
 *  //Main path value is valid
 *  resolveItemName(data, `name`) => "abc"
 *
 *  data = {id: 2}
 *  //Main path value is invalid so an alternative path will be used
 *  resolveItemName(data, `name`, `id`) => 2
 *
 *  data = {name: "abc", id: 2}
 *  //Main path is valid so there is no need for an alternative value
 *  resolveItemName(data, `name`, `id`) => "abc"
 *
 *  data = {}
 *  //Neither main or alternative path returns valid value so the fallback will be used
 *  resolveItemName(data, `name`, `id`, "fallback value") => "fallback value"
 *
 *  data = {partner: {id: 2, name: "test"}}
 *  //Main path value is valid so the processor can apply any additional modification
 *  resolveItemName(data, `name`, `id`, '', v => v + " value") => "test value"
 *
 *  data = {partner: {id: 2}}
 *  //Main path value is invalid but the alternative is valid so the processor and also the alternative one can apply any additional modification
 *  resolveItemName(data, `name`, `id`, '', v => v + " value", v => "alternative " + v) => "alternative 2 value"
 * ```
 * @param {*} base
 * @param {String|Array} path
 * @param {String|Array} altPath
 * @param {*} fallback
 * @param {Function} processor
 * @param {Function} altProcessor
 * @returns {*}
 * @see get
 */
export const resolveItemName = (base, path = `name`, altPath = `id`, fallback, processor = a => a, altProcessor = a => `#${a}`) => {
	if(IS.valid(get(base, path))) {
		return get(base, path, fallback, processor);
	}

	let alt = get(base, altPath, fallback, altProcessor);
	if(alt) {
		return processor(get(base, altPath, fallback, altProcessor));
	}
	return fallback;
};

export const getTimezone = () => {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * Resolve polymorph variable
 * ---
 * Handles prop data based on options.
 *
 * **Warning!** All options must be defined according "[type]: () => ..." formula
 * @param {*} prop
 * @param {Object} options
 * @param {*} fallback
 * @param {boolean} v2 Use v2
 * @return {*}
 */
export const resolvePolymorphVar = (prop, options, fallback = null, v2 = false) => {
	if(v2) return resolvePolymorphVarV2(prop, options, fallback);

	let type = typeof prop;

	if(type === "object") {
		if(prop === null){
			return IS.property(options, "null") ? options.null() : fallback;
		}
		else if(IS.array(prop)) {
			if(IS.property(options, "arrayMap")) {
				return IS.property(options, "array") ? options.array(
					prop.map(
						item => resolvePolymorphVar(item, options, fallback)
					)
				) : fallback;
			}
			return IS.property(options, "array") ? options.array(prop) : fallback;
		}

		return IS.property(options, "object") ? options.object(prop) : fallback;
	}

	return IS.property(options, type) ? options[type](prop) : fallback;
};

/**
 * Resolve polymorph variable v2
 * ---
 * Handles prop data based on options.
 *
 * **Warning!** All options are to be defined according to the "[type]: () => ..." formula and fallback must be in a Function format e.g. () => [fallback value]
 * @param {*} prop
 * @param {Object<TypeofOptions, function(TypeofOptions)>} options
 * @param {function(TypeofOptions)} fallback
 * @return {*}
 */
const resolvePolymorphVarV2 = (prop, options, fallback = null) => {
	let type = typeof prop;

	if(type === "object") {
		if(IS.array(prop)) {
			return IS.property(options, "array") ? options.array(prop) : fallback(type);
		}
		else if(prop === null) {
			return IS.property(options, "null") ? options.null(prop) : fallback(type);
		}

		return IS.property(options, "object") ? options.object(prop) : fallback(type);
	}

	return IS.property(options, type) ? options[type](prop) : fallback(type);
};

export const objectWithoutPropertiesLoose = (source, ...excluded) => {
	if(source == null) return {};
	let target = {};
	let sourceKeys = Object.keys(source);
	if(IS.array(excluded[0]) && excluded.length === 1) {
		excluded = excluded[0];
	}

	sourceKeys.forEach(key => {
		if(!excluded.includes(key)) {
			target[key] = source[key];
		}
	});

	return target;
};

/**
 * Unique ID
 * ---
 * Returns a Symbol with randomly generated number to differentiate the symbols while visually comparing the values
 * @param [description] Specific Symbol description
 * @return {symbol}
 */
export const uID = description => Symbol(description || Calc.randomWithinRange(0, Number.MAX_SAFE_INTEGER));
