import { Component } from 'preact';
import PropTypes from "prop-types";
import { ThemeContext } from "./ThemeContextProvider";

class ThemeHeader extends Component {
	render(props, {}) {
		return (
			<ThemeContext.Consumer>
				{themeContext => themeContext.getHeader(props)}
			</ThemeContext.Consumer>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
		}
	}
}

export default ThemeHeader;
