/**
 * Currency enum
 * ---
 * @enum string
 * @readonly
 */
export const E_Currency = {
	CZK: "CZK",
	EUR: "EUR",
	RON: "RON",
};
