import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import ComponentBase from "../ComponentBase";
import { RIS } from "../../utils/ReactIS";
import { ComponentUtils } from "../../utils/ComponentUtils";

export const ModalContext = React.createContext({});

class Modal extends ComponentBase {
	constructor(props) {
		super(props);

		this.state = {
			...super.state,
			opened: props.opened,
		};

		if(this.state.opened) {
			props.onOpened();
		}

		this._toBeClosedTimeout = null;
	}

	componentDidMount() {
		super.componentDidMount();

		this.addValueUpdateListener("props.opened", (opened) => {
			clearTimeout(this._toBeClosedTimeout);

			if(opened) {
				this.setState({opened});
			}
			else {
				this.setState({toBeClosed: true});
			}
		});

		this.addValueUpdateListener("state.opened", (opened) => {
			const {onOpened, onClosed} = this.props;

			if(opened) {
				onOpened()
			}
			else {
				onClosed();
			}
		});

		this.addValueUpdateListener("state.toBeClosed", (close) => {
			clearTimeout(this._toBeClosedTimeout);
			if(close) {
				this._toBeClosedTimeout = setTimeout(() => {
					this.setState({opened: false});
				}, 100);
			}
		});
	}

	render({className, style, children, accent}, {toBeClosed, opened}) {
		if(!opened) return null;

		const modalContext = {
			close: () => this.close(),
		};

		return ReactDOM.createPortal(
			<section
				className={combineClasses(
					"modal-wrapper",
					className,
					opened && "opened",
					toBeClosed && "close",
					accent && accent,
				)}
			>
				<div className={"shadow"}/>

				<ModalContext.Provider value={modalContext}>
					<>
						{
							ComponentUtils.propagateProps(children, {
								className: combineClasses(children.props.className, "modal"),
								modalContext: RIS.customComponent(children) ? modalContext : undefined,
								style: {...children.props.style, ...style}
							}, true)
						}
					</>
				</ModalContext.Provider>
			</section>,
			document.querySelector("#modalRoot")
		);
	}

	close() {
		this.setState({toBeClosed: true});
	}

	static get sharedTypes() {
		return {
			opened: PropTypes.bool,
		}
	}

	static get propTypes() {
		return {
			...super.propTypes,
			...Modal.sharedTypes,
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.element,

			accent: PropTypes.string,

			onOpened: PropTypes.func,
			onClosed: PropTypes.func,
		}
	}

	static get stateTypes() {
		return {
			...super.stateTypes,
			...Modal.sharedTypes,
			toBeClosed: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			...super.defaultProps,
			onOpened: () => null,
			onClosed: () => null,
		}
	}
}

export default Modal;
