export const E_Keyboard_Type = {
	EMAIL: "EMAIL",
	DECIMAL: "DECIMAL",
	NUMERIC: "NUMERIC",
	SPECIAL: "SPECIAL",
	PASSWORD: "PASSWORD",
	PHONE: "PHONE",
};

export const E_Keyboard_SpecialKeys = {
	BACKSPACE: "BACKSPACE",
	SPECIAL: "SPECIAL",
	SPACE: "SPACE",
	HIDE_KEYBOARD: "HIDE_KEYBOARD",
	SHIFT: "SHIFT",
	PLACEHOLDER: "PLACEHOLDER",
};

export const E_Keyboard_ShiftState = {
	DISABLED: "DISABLED",
	SINGLE: "SINGLE",
	CAPS_LOCK: "CAPS_LOCK",
};
