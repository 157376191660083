import React from "react";
import PropTypes from "prop-types";
import { E_Language, EO, IS, LanguageResolver, TextTransform } from "@green24/js-utils";
import { ComponentUtils } from "../../utils/ComponentUtils";

//Languages
import { AppUtils } from "../../utils/AppUtils";
import withConfiguration from "../hoc/withConfiguration";

export const TranslationContext = React.createContext({});

class TranslationProvider extends React.Component {
	constructor(props) {
		super(props);

		let availableLanguages = props.translations;
		let activeLanguageKey = E_Language.CZECH;

		this.state = {
			activeLanguage: this._resolveLanguageData(activeLanguageKey, availableLanguages),
			availableLanguages,
		};
	}

	render({children}, {activeLanguage, availableLanguages}) {
		return (
			<TranslationContext.Provider value={{
				availableLanguages: this._returnAvailableLanguagesList(availableLanguages, activeLanguage),
				changeLanguage: (language) => this._changeLanguage(language),
				activeLanguage,
				translate: (key, solverOrData) => this._translate(key, solverOrData),
				getActiveLanguageISOCode: () => this._getActiveLanguageISOCode(activeLanguage),
			}}>
				{children}
			</TranslationContext.Provider>
		);
	}

	_returnAvailableLanguagesList(availableLanguages, activeLanguage) {
		return Object.keys(availableLanguages).map(languageKey => ({
			key: languageKey,
			active: languageKey == activeLanguage.key,
		}));
	}

	_resolveLanguageData(key, availableLanguages = this.state.availableLanguages) {
		return {
			codes: LanguageResolver.getISOCodes(key),
			key,
			data: availableLanguages[key],
		}
	}

	_changeLanguage(languageKey) {
		localStorage.setItem("lang", languageKey);
		this.setState( state => ({
			activeLanguage: this._resolveLanguageData(languageKey, state.availableLanguages),
		}));
	}

	_translate(key, translationSolver) {
		const {activeLanguage} = this.state;

		let rawTranslation = activeLanguage.data[key];
		if(rawTranslation) {
			rawTranslation = TextTransform.replaceVariables(
				rawTranslation,
				missingKey => {
					if(key == missingKey) {
						const errorContent = "Cannot translate self with self. Used key: " + missingKey;

						//If in production, prevent application crash and instead just return the raw key
						if(AppUtils.isDev) {
							throw Error(errorContent);
						}
						else {
							console.error(errorContent);
							return '#' + missingKey;
						}
					}

					return ComponentUtils.resolvePolymorphProp(
						translationSolver,
						{
							object: o => this._translate(missingKey, o),
							array: arr => this._translate(missingKey, EO({}).fillFromArray(arr)),
							function: f => f(missingKey),
						},
						() => this._translate(missingKey),
					)
				},
				'@{',
				"}@"
			);

			if(IS.valid(translationSolver)) {
				return TextTransform.replaceVariables(rawTranslation, translationSolver);
			}
			return rawTranslation;
		}
		return '#' + key;
	}

	_getActiveLanguageISOCode(activeLanguage) {
		return activeLanguage.codes[0];
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			translations: PropTypes.object,

			//withConfiguration
			configuration: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			activeLanguage: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			translations: {},
		}
	}
}

export default withConfiguration(TranslationProvider);
