import { E_APIMethod, E_HttpErrorCode } from "../../models/constants/APIConnectorConstants";
import { get, resolvePolymorphVar } from "../../functions/generic";
import { M_APIConnector_RequestOptions } from "../../models/Models_APIConnector";
import { RequestHeaders } from "./RequestHeaders";
import { XHRUploader } from "./XHRUploader";
import { ResponseHandlers } from "./ResponseHandlers";
import { AdvancedPromise } from "../AdvancedPromise";
import { BatchManager } from "../Batch/BatchManager";
import { IS } from "../IS";
import { QS } from "../QueryStringManager";

/**
 * @typedef {{
 *     error: String,
 *     message: String,
 *     statusCode: Number,
 *     time: Date,
 *     timestamp: Number,
 *     validationErrors?: Array<{
 *         field: String,
 *         message: String,
 *         constraint: String,
 *         errorValue: String,
 *     }>
 * }} HttpResponseError
 */

export class APIConnector {
	/**
	 * @param {Function|String} apiURL API URL path or solver
	 * @param {Function} tokenSolver Token solver
	 * @param {function(data: *, options: M_APIConnector_RequestOptions)} onGlobalError
	 */
	constructor(apiURL, tokenSolver, onGlobalError) {
		this._apiURL = apiURL;
		this._tokenSolver = tokenSolver;
		this._onGlobalError = onGlobalError;
	}

	get api() {
		return resolvePolymorphVar(
			this._apiURL,
			{
				function: f => f(),
			},
			this._apiURL,
		);
	}

	get token() {
		return resolvePolymorphVar(
			this._tokenSolver,
			{
				function: f => f(),
			},
			this._tokenSolver,
		);
	}

	/**
	 * Send request
	 * ---
	 * @param {M_APIConnector_RequestOptions} configuration
	 * @param {ResponseHandlers} responseHandler
	 * @param {E_HttpErrorCode} [errorSimulationCode]
	 * @returns {Promise<*|HttpResponseError>}
	 */
	sendRequest(configuration, responseHandler = ResponseHandlers.json, errorSimulationCode) {
		return APIConnector.sendRequest(
			this.api,
			configuration,
			this.token,
			responseHandler,
			this._onGlobalError,
			errorSimulationCode,
		);
	}

	/**
	 * Send request
	 * ---
	 * @param {String} apiURL
	 * @param {M_APIConnector_RequestOptions} configuration
	 * @param {String} token
	 * @param {ResponseHandlers} responseHandler
	 * @param {function(data: *, options: M_APIConnector_RequestOptions)} onFetchError
	 * @param {E_HttpErrorCode} [errorSimulationCode]
	 * @returns {Promise<*|HttpResponseError>}
	 */
	static sendRequest(apiURL, configuration, token, responseHandler, onFetchError = () => null, errorSimulationCode) {
		if(errorSimulationCode) {
			return Promise.reject(APIConnector.getErrorFromCode(errorSimulationCode));
		}

		const options = APIConnector.constructRequestOptions(apiURL, token, configuration);

		let batchIdentifier;

		if(options.batch) {
			if(!window.__BATCH_MANAGER__) {
				window.__BATCH_MANAGER__ = new BatchManager();
			}

			batchIdentifier = {
				apiURL: options.apiURL,
				url: options.url,
				method: options.method,
				body: options.body,
				responseType: options.responseType,
			};

			let group = window.__BATCH_MANAGER__.find(batchIdentifier);
			if(group) {
				return group.add(new AdvancedPromise())[0];
			}
		}

		let promise = new AdvancedPromise((resolve, reject) => {
			fetch(
				options.apiURL + options.url,
				{
					method: options.method,
					headers: options.headers,
					body: options.body,
					signal: options.signal,
				}
			).then(response => {
				if(response.ok) {
					responseHandler(response).then(parsedResponse => {
						resolve(parsedResponse);
					}, res => {
						reject(res);
					});
				}
				else {
					response.json().then((data) => {
						onFetchError(data, options);

						reject(data);
					}, () => {
						reject(APIConnector.getErrorFromCode(response.status));
					});
				}
			}, response => {
				//Ignore error thrown by AbortController
				if(response.name == "AbortError") return;

				if(response.name == "TypeError" && response.message == "Failed to fetch") {
					response = new Response(new Blob(), {
						status: E_HttpErrorCode.SERVER_UNREACHABLE,
						statusText: `Could not reach the server on the address "${options.apiURL}". URL for the server might be incorrect/missing or the server itself is down.`,
					});
				}

				reject(response);
			});
		});

		if(batchIdentifier) {
			return window.__BATCH_MANAGER__.add(batchIdentifier, promise)[0];
		}
		return promise;
	}

	/**
	 * Send XHR request
	 * ---
	 * @param {M_APIConnector_RequestOptions} configuration
	 * @param {ResponseHandlers} responseHandler
	 * @param {E_HttpErrorCode} [errorSimulationCode]
	 * @returns {Promise<*|HttpResponseError>}
	 */
	sendXHRRequest(configuration, responseHandler, errorSimulationCode) {
		return APIConnector.sendXHRRequest(
			this.api,
			configuration,
			this.token,
			responseHandler,
			this._onGlobalError,
			errorSimulationCode,
		);
	}

	/**
	 * Send XHR request
	 * ---
	 * @param {String} apiURL
	 * @param {M_APIConnector_RequestOptions} configuration
	 * @param {String} token
	 * @param {ResponseHandlers} responseHandler
	 * @param {function(data: *, options: M_APIConnector_RequestOptions)} onFetchError
	 * @param {E_HttpErrorCode} [errorSimulationCode]
	 * @returns {Promise<*|HttpResponseError>}
	 */
	static sendXHRRequest(apiURL, configuration, token, responseHandler, onFetchError = () => null, errorSimulationCode) {
		if(errorSimulationCode) {
			return Promise.reject(APIConnector.getErrorFromCode(errorSimulationCode));
		}

		const options = APIConnector.constructRequestOptions(apiURL, token, {
			method: E_APIMethod.POST,
			headers: RequestHeaders.file,
			...configuration,
		});

		return new XHRUploader(options, responseHandler);
	}

	static constructRequestOptions(apiURL, token, configuration) {
		let options = {
			...M_APIConnector_RequestOptions,
			apiURL,
			token,
			...resolvePolymorphVar(
				configuration,
				{
					function: f => f(),
					string: s => ({url: s}),
					array: arr => ({
						url: arr[0],
						method: arr[1],
						body: arr[2],
						file: arr[2],
						useToken: arr[3],
					}),
				},
				configuration
			),
		};

		if(!options.useToken) {
			delete options.token;
		}
		else {
			//Get token
			options.token = resolvePolymorphVar(
				options.token,
				{
					function: f => f(token),
				},
				options.token,
			)
		}

		//Handle external request (starts with http)
		if(/^(https?:\/\/)/.test(options.url)) {
			options.apiURL = '';
		}

		if(!(options.headers instanceof Headers)) {
			options.headers = resolvePolymorphVar(
				options.headers,
				{
					function: f => f(options.token),
					object: o => new Headers(o),
				},
				() => RequestHeaders.json(options.token),
				true
			);
		}

		if(options.token && !options.headers.has("x-auth-token")) {
			options.headers.append("x-auth-token", options.token);
		}

		if(options.query) {
			options.url += resolvePolymorphVar(
				options.query,
				{
					function: f => f(options),
					string: s => s,
					object: o => {
						if(get(options, "queryOptions.raw", false)) {
							return QS(o).toString();
						}

						return QS(o).toValidDataString();
					}
				}
			);
		}

		if(options.body && !IS.string(options.body) && !get(options, "bodyOptions.raw", false)) {
			options.body = JSON.stringify(options.body);
		}

		if(IS.fnc(options.apiURL)) {
			options.apiURL = options.apiURL(options);
		}

		return options;
	}

	/**
	 * Get error from code
	 * ---
	 * Returns simulated but valid HTTP error body from provided code.
	 * @param {E_HttpErrorCode} code
	 * @param {HttpResponseError} dataOverride
	 * @returns {HttpResponseError}
	 */
	static getErrorFromCode(code, dataOverride) {
		const timestamp = new Date().toString();

		return {
			timestamp,
			statusCode: code,
			...APIConnector.constructDynamicErrorPartsFromCode(code),
			...dataOverride,
		}
	}

	/**
	 * @protected
	 * Construct dynamic error parts from code
	 * ---
	 * Returns unique parts of the error for appropriate error code.
	 * @param {E_HttpErrorCode} code
	 * @returns {{
	 *     error: String,
	 *     message: String,
	 * }}
	 * @see https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
	 */
	static constructDynamicErrorPartsFromCode(code) {
		switch (code) {
			case E_HttpErrorCode.BAD_REQUEST:
				return {
					error: "BAD_REQUEST",
					message: "The server cannot or will not process the request due to an apparent client error (e.g., malformed request syntax, size too large, invalid request message framing, or deceptive request routing).",
				};
			case E_HttpErrorCode.UNAUTHORIZED:
				return {
					error: "INVALID_TOKEN",
					message: "The user does not have valid authentication credentials for the target resource.",
				};
			case E_HttpErrorCode.FORBIDDEN:
			case 511:
				return {
					error: "FORBIDDEN",
					message: "The request contained valid data and was understood by the server, but the server is refusing action.",
				};
			case E_HttpErrorCode.NOT_FOUND:
				return {
					error: "NOT_FOUND",
					message: "The requested resource could not be found but may be available in the future.",
				};
			case E_HttpErrorCode.REQUEST_TIMED_OUT:
				return {
					error: "REQUEST_TIMED_OUT",
					message: "The server timed out waiting for the request",
				};
			case E_HttpErrorCode.INTERNAL_SERVER_ERROR:
			case 501:
			case 502:
			case 503:
				return {
					error: "INTERNAL_SERVER_ERROR",
					message: "A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.",
				};
			default:
				return {
					error: "UNKNOWN_ERROR_" + code,
					message: "Unknown error code: " + code,
				};
		}
	}
}
