import { Component } from 'preact';
import PropTypes from "prop-types";
import withConfiguration from "../hoc/withConfiguration";
import root from "window-or-global";
import { GreenSocket } from "@green24/js-utils";
import { ValueUpdateListeners } from "../../utils/ValueUpdateListeners";
import Loading from "../Loading";

class SocketListener extends Component {
	constructor() {
		super();

		this._valueUpdateListener = new ValueUpdateListeners();
	}

	componentDidMount() {
		this._valueUpdateListener.getValueWithUpdateListener(this, "props.configuration.apiURL", () => {
			this._initializeSocket();
		});
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		this._valueUpdateListener.componentDidUpdate(previousProps, previousState, this.props, this.state);
	}

	render({className, style}, {loading}) {
		return loading && <Loading title={"connecting"} className={"with-background full-page big"} style={{zIndex: 1}}/>;
	}

	_initializeSocket() {
		if(root.greenSocket && root.greenSocket.isConnected) {
			root.greenSocket.disconnect("Api change");
		}

		const {configuration} = this.props;

		// noinspection HttpUrlsUsage
		let wsUrl = configuration.apiURL.replace("https://", "wss://").replace("http://", "ws://");

		let socket = root.greenSocket = new GreenSocket(`${wsUrl}/box/ws-connect`);

		this.setState({loading: true});
		socket.connect(undefined, () => {
			this.setState({loading: false});
		});
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			configuration: PropTypes.object,
		}
	}
}

export default withConfiguration(SocketListener);
