import React from "react";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";

class Spinner extends React.Component {
	render({className, style, block}) {
		return (
			<section
				className={combineClasses(
					"spinner",
					block && "block",
					className
				)}
				style={style}
			>
				<div className={"spinner-content"}/>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,

			block: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			block: false,
		}
	}
}

export default Spinner;
