/**
 * Configuration model
 * ---
 * @type {{
 *  apiURL: string,
 *  updateWaitTime: number|string,
 *  redirectTimeout: number|string,
 *  devApiURL: string,
 * }}
 */
export const M_Configuration = {
	apiURL: undefined,
	devApiURL: undefined,
	redirectTimeout: "1m",
	updateWaitTime: "10m",
}
