import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses, StoreHelpers } from "@green24/js-utils";
import { E_Keyboard_SpecialKeys, E_Keyboard_Type } from "../../../models/constants/Enums_Keyboard";
import { M_Keyboard_OptionsDefaults } from "../../../models/Models_Keyboard";
import ButtonComponent from "../../Input/Button/ButtonComponent";
import Interpret from "../../Localization/Interpret";
import root from "window-or-global";
import KeyboardKeys from "./KeyboardKeys";
import { ValueUpdateListeners } from "../../../utils/ValueUpdateListeners";
import { AppUtils } from "../../../utils/AppUtils";

class Keyboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			type: props.type,
			view: props.view || props.type || E_Keyboard_Type.EMAIL,
			opened: props.block,
			onModify: props.onModify || (() => null),
			value: props.value,
			placeholder: props.placeholder,
			options: StoreHelpers.deepMerge(M_Keyboard_OptionsDefaults, props.options, true),
		};

		if(!props.block) {
			root.keyboard = this;
		}

		this._valueUpdateListeners = new ValueUpdateListeners();

		this._handleOnKey = AppUtils.withID(this._handleOnKey.bind(this));
	}

	componentDidMount() {
		this._valueUpdateListeners.add("state.value", (value) => {
			if(value === this.props.value) return;

			const {type} = this.state;
			if(type === E_Keyboard_Type.NUMERIC) {
				this.state.onModify(parseFloat(value));
			}
			else {
				this.state.onModify(value);
			}
		});

		this._valueUpdateListeners.add("state.opened", (opened) => {
			const {options, value} = this.state;

			if(opened) {
				options.onOpened();
			}
			else {
				options.onClosed(value);
			}
		});

		this._valueUpdateListeners.add("props.value", (value) => {
			this.setState({value});
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this._valueUpdateListeners.componentDidUpdate(prevProps, prevState, this.props, this.state);
	}

	componentWillUnmount() {
		this._valueUpdateListeners.clear();
	}

	_renderInputValue() {
		const {value, type} = this.state;

		switch (type) {
			case E_Keyboard_Type.PASSWORD:
				return Array((value || '').length).fill('*');
			/*case E_Keyboard_Type.PHONE:
				return <PhoneNumber
					number={'+' + (value || '')}
					validate={true}
					showValidationCheck={false}
					suffix={
						({valid}) => <ButtonsConstructor buttons={[
							{
								icon: "check",
								className: combineClasses("no-style validation", valid && "valid"),
								action: () => this.close(),
							}
						]}/>
					}
				/>;*/
			default:
				return value;
		}
	}

	_renderHeader() {
		const {placeholder, options, type, value} = this.state;
		const defaultInput = (
			<div className={combineClasses("input", value && "has-value")}>
				<p>{this._renderInputValue() || <Interpret id={placeholder} params={options}/>}</p>

				<ButtonComponent
					icon={"times-circle"}
					className={"clear-value inset"}
					elevation={true}
					accent={"error"}
					onClick={() => this.setState({value: ''})}
				/>
			</div>
		);

		switch (type) {
			/*case E_Keyboard_Type.PHONE:
				return (
					<div className={"phone-number-input-wrapper"}>
						<h1><Interpret id={placeholder} params={options}/></h1>
						<p><Interpret id={"internationalPhoneNumberRequirementMessage"}/></p>

						{defaultInput}
					</div>
				);*/
			default:
				return defaultInput;
		}
	}

	render({block, valueDisplay}, {view, opened, type, options}) {
		return (
			<section
				className={combineClasses(
					"keyboard",
					opened && "opened",
					block && "block",
				)}
				data-view={view}
			>
				{
					valueDisplay &&
					<header>{this._renderHeader()}</header>
				}

				{
					opened &&
					<KeyboardKeys
						type={type}
						view={view}
						block={block}
						onKey={this._handleOnKey}
						keyboardKeySharedProps={options.keyboardKeySharedProps}
					/>
				}
			</section>
		);
	}

	open(type, value, placeholder, onModify, options = {}) {
		this.setState({
			opened: true,
			type,
			view: type,
			onModify,
			value,
			placeholder,
			options: StoreHelpers.deepMerge(M_Keyboard_OptionsDefaults, options, true),
		});
	}

	close() {
		this.setState({
			opened: false,
			onModify: () => null,
			value: null,
			placeholder: null,
		});
	}

	_handleOnKey(key) {
		switch (key) {
			case E_Keyboard_SpecialKeys.HIDE_KEYBOARD:
				this.props.onSubmit();

				if(this.props.block) return;
				return this.close();
			case E_Keyboard_SpecialKeys.SPACE:
				return this._add(' ')
			case E_Keyboard_SpecialKeys.BACKSPACE:
				return this._remove();
			default:
				return this._add(key);
		}
	}

	_add(char) {
		const {value, type, options} = this.state;

		if(![
			E_Keyboard_Type.DECIMAL,
			E_Keyboard_Type.NUMERIC
		].includes(type)) {
			let {maxLength} = options;

			if(maxLength && `${value}`.length >= maxLength) return;
		}

		this.setState(state => {
			let newValue = `${state.value || ''}${char}`;

			if(state.type === E_Keyboard_Type.NUMERIC) {
				if(parseFloat(newValue) > state.options.max) {
					newValue = `${state.options.max}`;
				}
				else if(parseFloat(newValue) < state.options.min) {
					newValue = `${state.options.min}`;
				}
			}

			return {
				value: newValue,
			}
		})
	}

	_remove() {
		this.setState(state => {
			let newValue = `${state.value || ''}`.slice(0, -1);

			if(state.type === E_Keyboard_Type.NUMERIC) {
				if(parseFloat(newValue) > state.options.max) {
					newValue = `${state.options.max}`;
				}
				else if(parseFloat(newValue) < state.options.min) {
					newValue = `${state.options.min}`;
				}
			}

			return {
				value: newValue,
			}
		});
	}

	static get sharedTypes() {
		return {
			view: PropTypes.oneOf(Object.values(E_Keyboard_Type)),
			type: PropTypes.oneOf(Object.values(E_Keyboard_Type)),
			value: PropTypes.any,
			onModify: PropTypes.func,
			options: PropTypes.object,
		}
	}

	static get propTypes() {
		return {
			...this.sharedTypes,

			block: PropTypes.bool,
			valueDisplay: PropTypes.bool,
		}
	}

	static get stateTypes() {
		return {
			...this.sharedTypes,
			opened: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			block: false,
			valueDisplay: true,
			onSubmit: () => null,
		}
	}
}

export default Keyboard;
