import { KVElektroGenericServices } from "../../shared/services/api-connector";

class AfeOrderServices extends KVElektroGenericServices {
	getItem(itemID, simulate) {
		return super.getItem(itemID);
	}

	finalizeOrder(orderNumber, emails, contact, pin, pinEnterTime) {
		return this.sendRequest({
			url: `${this.url}/${orderNumber}`,
			method: "POST",
			body: {
				emails,
				contact,
				pin,
				pinEnterTime
			}
		});
	}

	sendPinToContact(orderNumber, contact, locale = "cs") {
		return this.sendRequest({
			url: `${this.url}/${orderNumber}/pin`,
			method: "POST",
			body: contact,
			query: {locale},
		});
	}

	applyVouchersToOrder(orderNumber, coupons) {
		return this.sendRequest({
			url: `${this.url}/${orderNumber}/coupons`,
			method: "POST",
			body: {coupons},
		});
	}
}

export const afeOrderServices = new AfeOrderServices(`/afe/order`);
