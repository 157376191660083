export const E_MaintenanceAction = {
	DEVICES_STATUS: "DEVICES_STATUS",
	QUICK_ACTIONS: "QUICK_ACTIONS",
	MANUAL_PAYMENT: "MANUAL_PAYMENT",
	MOVE_CASH_TO_CASHBOX: "MOVE_CASH_TO_CASHBOX",
	INITIATE_FILLING_PROCESS: "INITIATE_FILLING_PROCESS",
	RESET_CASHBOX_COUNTER: "RESET_CASHBOX_COUNTER",
	RESET_COIN_COUNTER: "RESET_COIN_COUNTER",
	GET_DEVICES_CONTENTS: "GET_DEVICES_CONTENTS",
	MOVE_SOME_CASH_TO_CASHBOX: "MOVE_SOME_CASH_TO_CASHBOX",
}
