import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { M_Configuration } from "../../models/Models_Configuration";
import { configurationSelector } from "../../store/selectors/selectors";

/**
 * A public higher-order component to access the imperative API
 */
export const withConfiguration = Component => {
	class HOC extends React.Component {
		render({configurationStates}) {
			return (
				<Component
					{...this.props}
				/>
			);
		}

		static get displayName() {
			return "withConfiguration(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}

		static get propTypes() {
			return {
				configuration: PropTypes.object,
			}
		}

		static get defaultProps() {
			return {
				configuration: M_Configuration,
			}
		}
	}

	const mapStateToProps = state => {
		return {
			configurationStates: configurationSelector.selectStates(state),
			configuration: configurationSelector.selectData(state),
		};
	};

	return connect(mapStateToProps)(HOC);
};

export default withConfiguration;
