export const M_Keyboard_Layout_Email = [
	[
		0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
		'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p',
		'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l',
		"SHIFT", 'z', 'x', 'c', 'v', 'b', 'n', 'm', "BACKSPACE",
		"SPECIAL", '@', "SPACE", '.', ".cz", ".com", "HIDE_KEYBOARD"
	],
];

export const M_Keyboard_Layout_Numeric = [
	[
		7, 8, 9,
		4, 5, 6,
		1, 2, 3,
		0, "BACKSPACE", "HIDE_KEYBOARD",
	],
];

export const M_Keyboard_Layout_Decimal = [
	[
		7, 8, 9,
		4, 5, 6,
		1, 2, 3, "BACKSPACE",
		0, '.', "HIDE_KEYBOARD",
	],
];

export const M_Keyboard_Layout_Special = [
	[
		'!', '@', '#', '$', '%', '^', '&', '*', '(', ')',
		'+', '-', '/', '=', '?', '_', '{', '}', ',', ':', "BACKSPACE",
		"SPECIAL", '@', "SPACE", '.', ".cz", ".com", "HIDE_KEYBOARD"
	],
];

export const M_Keyboard_OptionsDefaults = {
	//Numeric keyboard only
	min: undefined,
	//Numeric keyboard only
	max: undefined,

	//Non-numeric keyboard only
	maxLength: 10,

	keyboardKeySharedProps: undefined,

	onOpened: () => null,
	onClosed: (value) => null,
};
