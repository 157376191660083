import { APIConnector, LocalStorageEditor } from "@green24/js-utils";
import { KVElektroGenericServices } from "./api-connector";
import { AppUtils } from "../utils/AppUtils";
import { M_Configuration } from "../models/Models_Configuration";

class ConfigurationServices extends KVElektroGenericServices {
	getItem(configurationPath = "configuration.json", defaultConfiguration = M_Configuration) {
		if(AppUtils.isDev) {
			let localConfig = LocalStorageEditor.get("localConfiguration");
			if(localConfig) {
				return Promise.resolve(localConfig);
			}
		}

		return new Promise(resolve => {
			this.sendRequest({
				url: configurationPath,
				apiURL: '/',
			}).then(
				data => resolve(data),
				() => resolve(defaultConfiguration)
			);
		});
	}

	saveItem(data) {
		if(AppUtils.isDev) {
			LocalStorageEditor.set("localConfiguration", data);
			return Promise.resolve(data);
		}

		return new Promise((resolve, reject) => {
			const xHttp = new XMLHttpRequest();

			xHttp.onreadystatechange = () => {
				if (xHttp.readyState == 4) {
					if(xHttp.status == 200) {
						resolve(data);
					}
					else {
						try {
							reject(JSON.stringify(xHttp.responseText));
						}
						catch (e) {
							reject(APIConnector.getErrorFromCode(500));
						}
					}
				}
			}

			xHttp.open("POST", "update_configuration.php", true);
			xHttp.setRequestHeader("Content-Type", "application/json");
			xHttp.send(JSON.stringify(data));
		});
	}
}


export const configurationServices = new ConfigurationServices();
