import { get, IS } from "@green24/js-utils";
import { RIS } from "./ReactIS";

export class ValueUpdateListeners {
	constructor(root) {
		this._root = root;
		this._valueUpdateListeners = [];
	}

	get root() {
		return this._root || {};
	}

	/**
	 * Add
	 * ---
	 * Adds a value update listener
	 * @param {string} path
	 * @param {function(newValue, oldValue)} callback
	 * @param {boolean|string} blocking
	 * @param {function(a, b, isEqual: ValueUpdateListeners._isEqual)} comparator Is equal comparator, true; equal = no refresh, false; not equal = call refresh callback
	 */
	add(path, callback, blocking = false, comparator) {
		this._valueUpdateListeners.push({
			path,
			callback,
			blocking,
			comparator,
		});
	}

	remove(path) {
		this._valueUpdateListeners = this._valueUpdateListeners.filter(listener => listener.path !== path);
	}

	getValueWithUpdateListener(root, path, callback, blocking = false) {
		this.add(path, callback, blocking);

		callback(get(root, path), undefined);
	}

	componentDidUpdate(prevProps, prevState, newProps = this.root.props, newState = this.root.state) {
		if(!newProps || !newState) {
			throw Error("New component state is not valid! Either provide newProps and newState manually or include a reference to the component during ValueUpdateListener construction.");
		}

		let stop = false;
		let blockedListenerGroups = [];

		this._valueUpdateListeners.forEach(listener => {
			if(stop === false && !blockedListenerGroups.includes(listener.blocking)) {
				let pathParts = IS.array(listener.path) ? listener.path : listener.path.split(".");
				let newValue = get(pathParts[0] === "props" ? newProps : newState, pathParts.slice(1));
				let prevValue = get(pathParts[0] === "props" ? prevProps : prevState, pathParts.slice(1));

				if(
					IS.fnc(listener.comparator) ? !listener.comparator(prevValue, newValue, (a, b) => this._isEqual(a, b)) : !this._isEqual(prevValue, newValue)
				) {
					if(IS.fnc(listener.callback)) {
						listener.callback(newValue, prevValue);
					}

					//If blocking = true, it should block all following listeners to prevent unnecessary re-triggering with the same value
					// (if the async cannot update it up in time)
					// (useful if listener contains value update which affects other values that are listened for later on)
					if(listener.blocking === true) {
						stop = true;
					}
					else if (listener.blocking) {
						//Block only for a specific group
						blockedListenerGroups.push(listener.blocking);
					}
				}
			}
		});
	}

	clear() {
		this._valueUpdateListeners = [];
	}

	_isEqual(a, b) {
		if(!a && !b) {
			return a === b;
		}

		if(IS.fnc(a) && IS.fnc(b)) {
			return a && b && a.id && b.id && a.id === b.id;
		}

		//Symbol is a unique per instance object and cannot be parsed by the JSON (.stringify() will omit the entry)
		if(typeof a === "symbol" && typeof b === "symbol") {
			return a === b;
		}

		const componentAliases = {};
		const replacer = (k, v) => {
			if(RIS.component(v) || v instanceof Symbol) {
				if(componentAliases[v]) return componentAliases[v];

				return componentAliases[v] = - Date.now() - Math.random();
			}

			return v;
		};

		//Nested object **string** comparison; This WILL FAIL if the objects are not 100% the same (structure, order, types, ...)
		return JSON.stringify(a, replacer) == JSON.stringify(b, replacer);
	}
}
