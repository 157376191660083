import { StoreThunk } from "../../../shared/store/thunk/StoreThunk";
import { afeOrderServices } from "../../services/afe-order-services";
import { orderActions } from "../../../shared/store/actions/actions";
import { orderSelector } from "../../../shared/store/selectors/selectors";
import { APIConnector } from "@green24/js-utils";

export class Thunk_AFE_Order extends StoreThunk {
	set(orderData) {
		this.dispatch(orderActions.getFetchFulfilled(orderData));
	}

	getOrder(orderID, simulate = false) {
		this.dispatch(orderActions.getFetchPending());

		return new Promise((resolve, reject) => {
			afeOrderServices.getItem(orderID, simulate).then(order => {
				this.dispatch(orderActions.getFetchFulfilled(order));

				resolve(order);
			}, (res) => {
				this.dispatch(orderActions.getFetchRejected(res));

				reject(res);
			});
		});
	}

	clear() {
		this.dispatch(orderActions.getItemClear());
	}

	applyVouchers(vouchers) {
		this.dispatch(orderActions.getEditPending());

		return new Promise((resolve, reject) => {
			const order = orderSelector.selectData(this.getState());

			if(order && order.orderNumber) {
				afeOrderServices.applyVouchersToOrder(order.orderNumber, vouchers).then((partialOrder) => {
					const newOrder = {
						...order,
						...partialOrder,
					};

					this.dispatch(orderActions.getEditFulfilled(newOrder));
					resolve(newOrder);
				}, (res) => {
					this.dispatch(orderActions.getEditRejected(res));
					reject(res);
				});
			}
			else {
				const error = APIConnector.getErrorFromCode(400);

				this.dispatch(orderActions.getEditRejected(error));
				reject(error);
			}
		});
	}
}
