import { StoreThunk } from "../../../shared/store/thunk/StoreThunk";
import { configurationActions } from "../../../shared/store/actions/actions";
import { AFE_DEFAULT_CONFIGURATION } from "../../models/Models_AFE_Configuration";
import { ObjectUtils, StoreHelpers } from "@green24/js-utils";
import { configurationServices } from "../../../shared/services/configuration-services";
import { configurationSelector } from "../../../shared/store/selectors/selectors";
import { locationThunk } from "../../../shared/store/store";
import { M_Configuration } from "../../../shared/models/Models_Configuration";

export class Thunk_AFE_Configuration extends StoreThunk {
	getConfiguration() {
		this.dispatch(configurationActions.getFetchPending());

		configurationServices.getItem(`./assets/configuration.json`, AFE_DEFAULT_CONFIGURATION).then(data => {
			data = StoreHelpers.deepMerge(M_Configuration, data, true);

			this.dispatch(configurationActions.getFetchFulfilled(data));
		});
	}

	saveConfiguration(data) {
		this.dispatch(configurationActions.getEditPending());

		let oldConfiguration = configurationSelector.selectData(this.getState());
		let newConfiguration = StoreHelpers.deepMerge(M_Configuration, data, true);

		configurationServices.saveItem(newConfiguration).then(() => {
			this.dispatch(configurationActions.getEditFulfilled(newConfiguration));

			this._handleCleanupAfterChangedConfiguration(newConfiguration, oldConfiguration);
		}, (res) => {
			this.dispatch(configurationActions.getEditRejected(res));
		});
	}

	clearConfiguration() {
		return this.saveConfiguration(AFE_DEFAULT_CONFIGURATION);
	}

	_handleCleanupAfterChangedConfiguration(newConfiguration, oldConfiguration) {
		let {changed} = ObjectUtils.diff(newConfiguration, oldConfiguration);

		ObjectUtils.forEach(changed, key => {
			switch (key) {
				case "apiURL":
				case "locationID":
					locationThunk.clearLocation();
					break;
			}
		});
	}
}
