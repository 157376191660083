import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import Router, { route } from "preact-router";
import { connect } from "react-redux";
import { afeOrderThunk, applicationThunk } from "../../../shared/store/store";
import { orderSelector } from "../../../shared/store/selectors/selectors";
import Loading from "../../../shared/components/Loading";
import AfeOrderWizardDetail from "./AfeOrderWizardDetail";
import AfeOrderWizardEmailSelect from "./AfeOrderWizardEmailSelect";
import { AFE_ROUTE } from "../../models/constants/AfeRoutes";
import { Redirect } from "../../../shared/components/Redirect";
import { afeOrderServices } from "../../services/afe-order-services";
import AfeOrderWizardComplete from "./AfeOrderWizardComplete";
import AfeOrderWizardPartnerCheck from "./AfeOrderWizardPartnerCheck";
import AfeOrderWizardPin from "./AfeOrderWizardPin";
import VoucherManager from "../../../shared/routes/Voucher/VoucherManager";
import AfeOrderWizardPaymentSelect from "./AfeOrderWizardPaymentSelect";
import AfeOrderWizardPayment from "./AfeOrderWizardPayment";
import AfeOrderWizardCashAmountSelect from "./AfeOrderWizardCashAmountSelect";
import MessageModal from "../../../shared/components/Modal/Instances/MessageModal";
import { ErrorUtils } from "../../../shared/utils/ErrorUtils";

class AfeOrderWizard extends Component {
	constructor() {
		super();

		this.state = {
			opening: false,
			openedBoxes: [],
			contact: undefined,
			couponsApplied: false,
			pin: undefined,
			pinEnterTime: undefined,
		}
	}

	componentWillUnmount() {
		afeOrderThunk.clear();
	}

	render({className, style, order, orderID, orderStates}, {opening, openedBoxes, contact, couponsApplied}) {
		if(!order) return <Redirect to={AFE_ROUTE.BASE}/>;

		return (
			<section className={combineClasses("afe-order-wizard order-wizard", className)} style={style}>
				{
					opening &&
					<Loading title={"openingBoxes"} className={"with-background full-page big"} style={{zIndex: 2}}/>
				}
				{
					orderStates.fetching &&
					<Loading title={"updatingOrderData"} className={"with-background full-page big"} style={{zIndex: 2}}/>
				}

				{/*TBD: Remove orderID from the routes?*/}
				<Router onChange={() => applicationThunk.setIdleTimeout()}>
					<AfeOrderWizardComplete
						path={AFE_ROUTE.ORDER_WIZARD_COMPLETE(orderID)}

						boxes={openedBoxes}
					/>

					<AfeOrderWizardPayment
						path={AFE_ROUTE.ORDER_WIZARD_PAYMENT(orderID)}

						order={order}
					/>

					<AfeOrderWizardCashAmountSelect
						path={AFE_ROUTE.ORDER_WIZARD_PAYMENT_CASH_AMOUNT_SELECT(orderID)}

						order={order}
					/>

					<AfeOrderWizardPaymentSelect
						path={AFE_ROUTE.ORDER_WIZARD_PAYMENT_SELECT(orderID)}

						order={order}
					/>

					<AfeOrderWizardPin
						path={AFE_ROUTE.ORDER_WIZARD_PIN(orderID)}

						contact={contact}
						order={order}
						onContinue={(pin) => this._setPin(pin)}
					/>

					<AfeOrderWizardPartnerCheck
						path={AFE_ROUTE.ORDER_WIZARD_PARTNER_CHECK(orderID)}

						order={order}
						onConfirmed={contact => this._applyContact(contact)}
					/>

					<AfeOrderWizardEmailSelect
						path={AFE_ROUTE.ORDER_WIZARD_EMAILS(orderID)}

						order={order}
						onSubmit={(emails) => this._finalizeOrder(emails)}
					/>

					<VoucherManager
						path={AFE_ROUTE.ORDER_WIZARD_VOUCHERS(orderID)}

						backButtonProps={{
							href: AFE_ROUTE.ORDER_WIZARD_DETAIL(orderID),
						}}

						onContinue={(vouchers) => this._applyVouchers(vouchers)}
					/>

					<AfeOrderWizardDetail
						default

						order={order}
						couponsApplied={couponsApplied}
					/>
				</Router>
			</section>
		);
	}

	_setPin(pin) {
		const {orderID} = this.props;

		this.setState({
			pin,
			pinEnterTime: (new Date()).toISOString(),
		});

		route(AFE_ROUTE.ORDER_WIZARD_EMAILS(orderID))
	}

	_applyVouchers(vouchers) {
		const {orderID} = this.props;

		afeOrderThunk.applyVouchers(vouchers).then(() => {
			this.setState({couponsApplied: true});

			route(AFE_ROUTE.ORDER_WIZARD_PAYMENT_SELECT(orderID, {justStarted: true}));
		}, (res) => {
			if(res.error === "POPUP_ERROR") {
				ErrorUtils.handlePopupErrorResponse(res);
			}
			else {
				window.modal.open(
					<MessageModal
						title={"vouchers.apply.error.title"}
						message={"vouchers.apply.error.message"}
					/>
				);
			}

		});
	}

	_applyContact(contact) {
		const {orderID} = this.props;

		this.setState({contact});

		route(AFE_ROUTE.ORDER_WIZARD_PIN(orderID));
	}

	_finalizeOrder(emails) {
		const {order} = this.props;
		const {contact, pin, pinEnterTime} = this.state;

		this.setState({opening: true});
		afeOrderServices.finalizeOrder(order.orderNumber, emails, contact, pin, pinEnterTime).then((openedBoxes) => {
			//Save the boxes that are opening, so they can be displayed in the COMPLETE page
			this.setState({openedBoxes});

			route(AFE_ROUTE.ORDER_WIZARD_COMPLETE(order.orderNumber));
		}, (res) => {
			if(res.error === "POPUP_ERROR") {
				ErrorUtils.handlePopupErrorResponse(res);
			}
		}).finally(() => {
			this.setState({opening: false});
		});
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			order: PropTypes.object,
			orderStates: PropTypes.object,

			//Router
			orderID: PropTypes.string,
		}
	}

	static get stateTypes() {
		return {
			opening: PropTypes.bool,
			openedBoxes: PropTypes.array,
			contact: PropTypes.object,
			couponsApplied: PropTypes.bool,
			pin: PropTypes.string,
			pinEnterTime: PropTypes.string,
		}
	}

	static get defaultProps() {
		return {

		}
	}
}

const mapStateToProps = state => {
	return {
		orderStates: orderSelector.selectStates(state),
		order: orderSelector.selectData(state),
	};
};

export default connect(mapStateToProps)(AfeOrderWizard);
