export class Refresher {
	constructor(callback, refreshTime = 10000, callbackParams, triggerOnConstruct = true) {
		this.refreshTime = refreshTime;
		this._callback = callback;
		this._timeout = null;
		this._stopped = false;

		if(triggerOnConstruct) {
			this._callback(callbackParams);
		}

		this.trigger(refreshTime);
	}

	trigger(params, refreshTime = this.refreshTime) {
		this._clearTimeout();

		//Hard stop, even if trigger is called, it should not create a timeout
		if(this._stopped) return;

		this._timeout = setTimeout(() => {
			this._callback(params);
		}, refreshTime);
	}

	forceTrigger(params) {
		this._clearTimeout();

		//Hard stop, even if trigger is called, it should not create a timeout
		if(this._stopped) return;

		this._callback(params);
	}

	stop() {
		this._stopped = true;
		this._clearTimeout();
	}

	reset() {
		this._stopped = false;
		this._callback();
	}

	_clearTimeout() {
		clearTimeout(this._timeout);
	}
}
