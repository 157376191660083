import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses, get } from "@green24/js-utils";
import { hasamMaintenanceServices } from "../../services/maintenance-services";
import Loading from "../../components/Loading";
import ErrorBlock from "../../components/ErrorBlock";
import { APP_ROUTE } from "../../models/constants/AppConstants";
import MaintenanceActions from "./MaintenanceActions";
import ThemeHeader from "../../components/Theme/ThemeHeader";

class MaintenanceDetail extends Component {
	constructor() {
		super();

		this.state = {
			devicesStatus: null,
			fetching: false,
			error: null,
		};
	}

	componentDidMount() {
		this._getStatus();
	}

	_renderContent() {
		const {devicesStatus, fetching, error} = this.state;

		if(fetching) {
			return <Loading title={"loading"} className={"with-background"} style={{zIndex: 1}}/>;
		}

		if(error) {
			return <ErrorBlock
				error={error}
				actionButtons={[
					{
						text: "return",
						href: APP_ROUTE.BASE,
					}
				]}
				onRetry={() => this._getStatus()}
			/>
		}

		return (
			<>
				<ThemeHeader homeButton={true} motiv={false}/>

				<MaintenanceActions
					devicesStatus={devicesStatus}
					devices={get(devicesStatus, "devices", [])}

					onDevicesStatus={devicesStatus => this.setState({devicesStatus})}
				/>
			</>
		)
	}

	render({className, style}, {}) {
		return (
			<section className={combineClasses("maintenance-detail", className)} style={style}>
				{this._renderContent()}
			</section>
		);
	}

	_getStatus() {
		this.setState({fetching: true});
		hasamMaintenanceServices.getDevicesStatus().then(devicesStatus => {
			this.setState({devicesStatus});
		}, res => {
			// this.setState({error: res});//TODO: Uncomment
		}).finally(() => {
			this.setState({fetching: false});
		});
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,
		}
	}
}

export default MaintenanceDetail;
