import { ArrayUtils } from "@green24/js-utils";

export class ComponentStateUtils {
	static toggleBoolean(target, key) {
		target.setState(state => ({[key]: !state[key]}));
	}

	static removeIndex(target, key, index) {
		target.setState(state => {
			return {
				[key]: ArrayUtils.removeIndex(state[key], index, true, true),
			};
		});
	}
}
