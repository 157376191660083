import React from "react";
import PropTypes from "prop-types";
import { RouteUtils } from "../utils/RouteUtils";
import root from "window-or-global";
import Match from "preact-router/match";

class PathRestricted extends React.Component {
	render({blacklist, whitelist, children}) {
		return <Match>
			{o => RouteUtils.isGraylisted(o.path, blacklist, whitelist, root.location) ? children : null}
		</Match>;
	}

	static get propTypes() {
		return {
			blacklist: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.func]),
			whitelist: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.func]),
		}
	}

	static get defaultProps() {
		return {
			children: null,
		}
	}
}

export default PathRestricted;
