import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import { Redirect } from "./Redirect";
import { APP_ROUTE } from "../models/constants/AppConstants";

class MaintenanceTrigger extends Component {
	constructor() {
		super();

		this.state = {
			count: 0,
		}
	}

	render({className, style}, {count}) {
		return (
			<section
				className={combineClasses("maintenance-trigger", className)}
				style={style}

				onClick={() => this._addToCounter()}
			>
				{
					count > 9 &&
					<Redirect to={APP_ROUTE.MAINTENANCE}/>
				}
			</section>
		);
	}

	_addToCounter() {
		this.setState(state => ({count: state.count + 1}));
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,
		}
	}
}

export default MaintenanceTrigger;
