import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { ArrayUtils, combineClasses, get, IS, ObjectUtils, StoreHelpers } from "@green24/js-utils";
import { M_PropTypes_Shared } from "../../../models/Models_PropTypes";
import { ComponentUtils } from "../../../utils/ComponentUtils";
import { M_ActionButton, M_ButtonPresets } from "../../../models/Models_Shared";
import ButtonComponent from "./ButtonComponent";
import { Link } from 'preact-router/match';
import { RIS } from "../../../utils/ReactIS";
import PathRestricted from "../../PathRestricted";

class ButtonsConstructor extends React.Component {
	_wrapInLink(body, button) {
		if(IS.string(button.href)) {
			return (
				<Link
					style={button.style}
					disabled={button.disabled}
					isActive={button.active}
					href={!button.disabled && button.href}
					exact={button.exact}
					returnURL={button.returnURL}
					delay={button.delay}
					{...button.linkProps}
					className={combineClasses(get(button, "linkProps.className"), "constructed-button")}
				>
					{body}
				</Link>
			);
		}
		return body;
	}

	_wrapInPathRestricted(body, button) {
		if(button.routeWhitelist || button.routeBlacklist) {
			return (
				<PathRestricted
					whitelist={button.routeWhitelist}
					blacklist={button.routeBlacklist}
				>
					{body}
				</PathRestricted>
			);
		}
		return body;
	}

	_renderButton(button) {
		if(RIS.component(button)) return button;

		let isActive = false;

		if(button.active) {
			if(IS.fnc(button.active)) {
				isActive = button.active(button);
			}
			else {
				isActive = button.active;
			}
		}

		return this._wrapInPathRestricted(
			this._wrapInLink(
				<ButtonComponent
					{...button}
					className={combineClasses(
						!IS.string(button.href) && "constructed-button",
						button.className,
						isActive && button.activeClass,
					)}
					onClick={(e) => {
						button.action && button.action(e);
						button.onClick && button.onClick(e);
					}}
				/>,
				button
			),
			button
		);
	}

	_renderButtons() {
		const {buttons, children, prefix, suffix} = this.props;

		return (
			<Fragment>
				{prefix}
				{
					this._processButtons(buttons).map(
						(button, i) => (
							<Fragment key={(button && button.id) || i}>
								{this._renderButton(button)}
							</Fragment>
						)
					)
				}
				{children}
				{suffix}
			</Fragment>
		);
	}

	render({className, style, wrapper}) {
		return ComponentUtils.wrapWithWrapper(this._renderButtons(), wrapper, {
			className,
			style
		});
	}

	_processButtons(buttons) {
		return ArrayUtils.mapValid(buttons, button => {
			if(!button) return;
			if(RIS.component(button)) return button;

			let buttonData = ObjectUtils.resolvePresets(this._unifyButtonProps(button), M_ButtonPresets);
			if(buttonData.hidden) return;

			return buttonData;
		});
	}

	_unifyButtonProps(button) {
		const {sharedProps} = this.props;

		return {
			...M_ActionButton,
			...StoreHelpers.deepMerge(sharedProps, button, true, (ct, key, aValue, bValue) => {
				if(RIS.component(bValue)) {
					return bValue;
				}
				return ct;
			}),
		};
	}

	static get propTypes() {
		return {
			...M_PropTypes_Shared,
			wrapper: PropTypes.element,
			/**
			 * @type {{buttons: Array<M_ActionButton>}}
			 */
			buttons: PropTypes.array.isRequired,
			sharedProps: PropTypes.object,//Props that are shared across all buttons/links
		}
	}

	static get defaultProps() {
		return {
			wrapper: null,
			sharedProps: {}
		}
	}
}

export default ButtonsConstructor;
