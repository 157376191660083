import { Component } from 'preact';
import PropTypes from "prop-types";
import IdleTimeout from "../../shared/components/other/Idle/IdleTimeout";
import RouteListener from "../../shared/components/other/RouteListener";
import GlobalModal from "../../shared/components/other/GlobalModal";
import Toaster from "../../shared/components/other/Toast/Toaster";
import Keyboard from "../../shared/components/other/Keyboard/Keyboard";
import SocketListener from "../../shared/components/other/SocketListener";
import ThemeContextProvider from "../../shared/components/Theme/ThemeContextProvider";
import IdleTimeoutCounter from "../../shared/components/other/Idle/IdleTimeoutCounter";
import AfeHeader from "./other/AfeHeader";
import AfeRouterSwitch from "./other/AfeRouterSwitch";

class AfeApp extends Component {
	render({className, style}, {}) {
		return (
			<div id={"root"}>
				<IdleTimeout/>
				<RouteListener/>
				<GlobalModal/>
				<Toaster/>
				<Keyboard/>
				<SocketListener/>

				<main>
					<ThemeContextProvider
						onHeader={(props) => <AfeHeader homeButton={true} motiv={false} {...props}/>}
						onPaymentSelectRoute={() => /*AFE_ROUTE.ORDER_WIZARD(E_AF_Order_Sections.PAYMENT_SELECT)*/null}//TODO
					>
						<AfeRouterSwitch/>
					</ThemeContextProvider>

					<IdleTimeoutCounter/>
				</main>

				<div className={"version"}>{process.env.PACKAGE_VERSION}</div>

				<div id={"modalRoot"}/>
			</div>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,
		}
	}
}

export default AfeApp;
