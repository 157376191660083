import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses, get } from "@green24/js-utils";
import ButtonComponent from "../../../shared/components/Input/Button/ButtonComponent";
import CombineComponentsFromTranslation from "../../../shared/components/Localization/CombineComponentsFromTranslation";
import Interpret from "../../../shared/components/Localization/Interpret";
import ButtonsConstructor from "../../../shared/components/Input/Button/ButtonsConstructor";
import Price from "../../../shared/components/Price/Price";
import ThemeHeader from "../../../shared/components/Theme/ThemeHeader";
import { AFE_ROUTE } from "../../models/constants/AfeRoutes";
import { E_AFE_Order_ProcessType } from "../../models/constants/afeOrderConstants";

class OrderDetail extends Component {
	render({className, style, order, onContinue, couponsApplied}, {}) {
		const isFree = (order.price || 0) == 0;

		return (
			<section className={combineClasses("order-wizard-detail", className)} style={style}>
				<ThemeHeader motiv={false} homeButton={true} customer={order.customerName}/>

				<h1><Interpret id={"orderDetail"}/></h1>

				<div className={"content"}>
					<div className={"items"}>
						{
							get(order, "items", []).map(item => {
								return (
									<div className={"item"} key={item.id}>
										<label>{item.name}</label>

										<div>
											<span>{item.numberOfItems}</span>
											<span>{item.numberOfItemsSuffix}</span>
										</div>
									</div>
								)
							})
						}
					</div>

				</div>

				<div>
					{
						order.processType == E_AFE_Order_ProcessType.PAY &&
						<div className={"footer"}>
							<div className={"price-total"}>
								<CombineComponentsFromTranslation id={isFree ? "paidInAdvance" : "toPay"} onString={s => {
									if(isFree) {
										return <b className={"price"}>{s}</b>
									}

									return <span>{s}</span>;
								}}>
									{{
										price: (
											<b className={"price"}><Price value={order.price}/></b>
										)
									}}
								</CombineComponentsFromTranslation>
							</div>

							{
								!isFree &&
								<a className={"voucher-button"} href={couponsApplied ? null : AFE_ROUTE.ORDER_WIZARD_VOUCHERS(order.orderNumber)}>
									<ButtonComponent
										text={"addVouchers"}
										disabled={couponsApplied}
									/>
								</a>
							}
						</div>
					}

					<nav className={"navigation-buttons"}>
						<ButtonsConstructor buttons={[
							{
								text: "back",
								href: AFE_ROUTE.BASE,
							},
							{
								text: "continue",
								href: this._getContinueHrefFromOrder(order),
							}
						]}/>
					</nav>
				</div>
			</section>
		);
	}

	_getContinueHrefFromOrder(order) {
		switch (order.processType) {
			case E_AFE_Order_ProcessType.PAY:
				return AFE_ROUTE.ORDER_WIZARD_PAYMENT_SELECT(order.orderNumber, {justStarted: true});
			case E_AFE_Order_ProcessType.PIN:
				return AFE_ROUTE.ORDER_WIZARD_PARTNER_CHECK(order.orderNumber);
			case E_AFE_Order_ProcessType.PAYED:
				return AFE_ROUTE.ORDER_WIZARD_EMAILS(order.orderNumber);
		}
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			order: PropTypes.object,
			couponsApplied: PropTypes.bool,

			onContinue: PropTypes.func,
		}
	}

	static get defaultProps() {
		return {
			couponsApplied: false,
		}
	}
}

export default OrderDetail;
