import React from "react";
import PropTypes from "prop-types";
import { combineClasses, StoreHelpers } from "@green24/js-utils";
import ButtonsConstructor from "./Input/Button/ButtonsConstructor";
import Interpret from "./Localization/Interpret";
import { ErrorUtils } from "../utils/ErrorUtils";
import Fa from "./Fa";
import { ComponentUtils } from "../utils/ComponentUtils";

class ErrorBlock extends React.Component {
	render({className, style, error, errorOverrides, onRetry, suffix, actionButtons, showLogo, icon, title, message, retry}) {
		const data = StoreHelpers.deepMerge(this._processError(error), errorOverrides[error.statusCode], true);
		const retryButton = {
			text: "retry",
			elevation: 1,
			action: () => onRetry(),
		};
		const defaultButtons = [
			(data.retry || retry) && onRetry && retryButton,
		]

		return (
			<section className={combineClasses("error-block", className)} style={style}>
				{
					showLogo &&
					<Fa icon={"kv-elektro-logo"} className={"mono logo"}/>
				}

				<Fa icon={icon || data.icon || "exclamation-triangle"} className={"icon"}/>

				<h1><Interpret id={title || data.title}/></h1>

				<p>{message || data.message}</p>

				<ButtonsConstructor buttons={ComponentUtils.resolvePolymorphProp(
					actionButtons,
					{
						array: arr => [...defaultButtons, ...arr],
						function: f => f({data, defaultButtons, retryButton}),
					},
					() => defaultButtons,
				)}/>

				{suffix}
			</section>
		);
	}

	_processError(error) {
		return ErrorUtils.getErrorFromResponse(error);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			/**
			 * @type HttpResponseError
			 */
			error: PropTypes.object.isRequired,
			errorOverrides: PropTypes.object,
			onRetry: PropTypes.func,
			actionButtons: PropTypes.any,
			showLogo: PropTypes.bool,
			retry: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			errorOverrides: {},
			onRetry: null,
		}
	}
}

export default ErrorBlock;
