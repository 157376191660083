import React from "react";
import PropTypes from "prop-types";
import { ComponentUtils } from "../../utils/ComponentUtils";
import { withTranslationContext } from "./withTranslationContext";

class Interpret extends React.Component {
	render({children, id, translationContext, fallback, params}) {
		if(id) {
			const translation = ComponentUtils.resolvePolymorphProp(
				id,
				{
					string: s => translationContext.translate(s, params),
					array: a => (a.find(translation => translationContext.activeLanguage.codes.includes(translation.languageCode)) || a[0] || {}).text,
					function: f => f(translationContext),
				},
				() => translationContext.translate(id, params),
			) || null;

			if(fallback && (translation === null || translation === '#' + id)) {
				return <bdi>{fallback}</bdi>;
			}
			return <bdi>{translation}</bdi>;
		}

		return ComponentUtils.resolvePolymorphProp(
			children,
			{
				function: f => f(translationContext),
				component: c => React.cloneElement(c, translationContext),
			},
			() => children,
		) || null;
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			id: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.func]),
			params: PropTypes.any,
			fallback: PropTypes.string,

			//withTranslationContext
			translationContext: PropTypes.object,
		}
	}
}

export default withTranslationContext(Interpret);
