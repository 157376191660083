import { StoreThunk } from "../../../shared/store/thunk/StoreThunk";
import { locationActions } from "../../../shared/store/actions/actions";
import { locationServices } from "../../services/location-services";

export class Thunk_Location extends StoreThunk {
	getLocation(background = false) {
		this.dispatch(background ? locationActions.getBackgroundFetchPending() : locationActions.getFetchPending());

		return new Promise((resolve, reject) => {
			locationServices.getItem().then(location => {
				this.dispatch(locationActions.getFetchFulfilled(location));
				resolve(location);
			}, res => {
				this.dispatch(locationActions.getFetchRejected(res));
				reject(res);
			});
		});
	}

	clearLocation() {
		this.dispatch(locationActions.getItemClear());
	}
}
