import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import { AF_ROUTE } from "../../models/constants/AfRoutes";
import Fa from "../../../shared/components/Fa";
import ButtonsConstructor from "../../../shared/components/Input/Button/ButtonsConstructor";

class AfHeader extends Component {
	render({className, style, logoProps, homeButton, motiv, customer}, {}) {
		return (
			<header className={combineClasses("header", className)} style={style}>
				<a className={"logo"} href={homeButton && AF_ROUTE.BASE}>
					<Fa icon={"kv-elektro-logo"} {...logoProps}/>
				</a>

				<div className={"right"}>
					<div className={"customer"}>{customer}</div>

					<nav className={"header-buttons"}>
						<ButtonsConstructor buttons={[
							homeButton && {
								icon: "home",
								className: "home",
								href: AF_ROUTE.BASE,
								routeBlacklist: [AF_ROUTE.BASE],
							}
						]}/>
					</nav>

					{
						motiv &&
						<Fa icon={"motiv"} className={"motiv"}/>
					}
				</div>
			</header>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			logoProps: PropTypes.object,
			homeButton: PropTypes.bool,
			motiv: PropTypes.bool,

			customer: PropTypes.string,
		}
	}

	static get defaultProps() {
		return {
			motiv: true,
		}
	}
}

export default AfHeader;
