import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import ButtonsConstructor from "../../components/Input/Button/ButtonsConstructor";
import Interpret from "../../components/Localization/Interpret";
import root from "window-or-global";
import { E_Keyboard_Type } from "../../models/constants/Enums_Keyboard";
import { authServices } from "../../services/auth-services";
import Loading from "../../components/Loading";
import ThemeHeader from "../../components/Theme/ThemeHeader";
import { ErrorUtils } from "../../utils/ErrorUtils";
import { AppUtils } from "../../utils/AppUtils";

class MaintenanceLogin extends Component {
	constructor() {
		super();

		this.state = {
			username: '',
			password: '',
			loggingIn: false,
		}
	}

	render({className, style}, {username, password, loggingIn}) {
		return (
			<section className={combineClasses("maintenance-login", className)} style={style}>
				{
					loggingIn &&
					<Loading title={"loggingIn"} className={"with-background"} style={{zIndex: 1}}/>
				}

				<ThemeHeader homeButton={true} motiv={false}/>

				<div className={"form"}>
					<div>
						<h1><Interpret id={"username"}/></h1>
						<ButtonsConstructor buttons={[
							{
								text: username || "-",
								textTranslated: true,
								action: () => this._openUsernameKeyboard(),
							}
						]}/>
					</div>

					<div>
						<h1><Interpret id={"password"}/></h1>
						<ButtonsConstructor buttons={[
							{
								text: password.replace(/./g, '*') || '-',
								textTranslated: true,
								action: () => this._openPasswordKeyboard(),
							}
						]}/>
					</div>

					<nav>
						<ButtonsConstructor buttons={[
							{
								text: "logIn",
								action: () => this._logIn()
							},
							AppUtils.isDev &&
							{
								text: "Auto login",
								textTranslated: true,
								style: {marginTop: "1rem"},
								action: () => this._logIn("admin", "admin")
							}
						]}/>
					</nav>
				</div>
			</section>
		);
	}

	_openUsernameKeyboard() {
		root.keyboard.open(E_Keyboard_Type.EMAIL, this.state.username, undefined, username => this.setState({username}), {
			maxLength: false,
		});
	}

	_openPasswordKeyboard() {
		root.keyboard.open(E_Keyboard_Type.PASSWORD, this.state.password, undefined, password => this.setState({password}), {
			maxLength: false,
		});
	}

	_logIn(username = this.state.username, password = this.state.password) {
		const {onLoggedIn} = this.props;

		this.setState({loggingIn: true});
		authServices.login(username, password).then(headers => {
			onLoggedIn(headers.get("x-auth-token"));
		}, (res) => {
			//TODO: Error handle
			if(res.error === "POPUP_ERROR") {
				ErrorUtils.handlePopupErrorResponse(res);
			}

			this.setState({loggingIn: false});
		});
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			onLoggedIn: PropTypes.func.isRequired,
		}
	}

	static get stateTypes() {
		return {
			username: PropTypes.string,
			password: PropTypes.string,
			loggingIn: PropTypes.bool,
		}
	}
}

export default MaintenanceLogin;
