import { get } from "../functions/generic";
import { CONSOLE_ERROR_BOLD, E_Modification } from "../models/constants/SharedContants";
import { IS } from "./IS";
import { StoreHelpers } from "./StoreHelpers";
import { ArrayUtils } from "./ArrayUtils";
import { ObjectUtils } from "./ObjectUtils";

/**
 * Extended Object
 * ---
 */
export class ExtendedObject {
	constructor(data) {
		Object.assign(this, {...data});
	}

	/**
	 * Clone
	 * ---
	 * Deep copy of the object
	 * @returns {ExtendedObject}
	 */
	clone() {
		return StoreHelpers.deepClone(this);
	}

	/**
	 * Modify
	 * ---
	 * Modifies abject according to the {modificationType} along the {path} with {modification}
	 * @param {Object<E_Modification>} modificationType Modification type
	 * @param {String|Array<String>} path Modification path
	 * @param {*} modification Modification value
	 */
	modify(modificationType, path, modification) {
		path = (IS.string(path) ? path.split(".") : path);

		if (!Array.isArray(path)) {
			return console.error("%cPath is not an Array!", CONSOLE_ERROR_BOLD, `type:`, typeof path, `path:`, path);
		}

		const __handleRemoveByID = (removePath) => {
			let parent = get(this, ArrayUtils.select(removePath, 0, "len-1"));
			let removeIndex = ArrayUtils.findByID(parent, `${ArrayUtils.lastItem(removePath)}`.replace('#', ''), true);

			parent.splice(removeIndex, 1);
		};
		const __handleItemRemove = (removePath, removeTarget) => {
			let partialPath = ArrayUtils.select(removePath, 0, "len-1");
			let parent = IS.empty(partialPath) ? this : get(this, partialPath);

			if (IS.array(parent)) {
				ArrayUtils.removeItem(parent, removeTarget);
			}
			else {
				delete parent[ArrayUtils.lastItem(path)];
			}
		};
		const __handleArrayPush = (pushPath, pushModification) => {
			const ___modify = (p, data) => {
				if(IS.array(data)) {
					get(this, p).push(...data);
				} else {
					get(this, p).push(data);
				}
			};

			if(!IS.valid(get(this, pushPath))) {
				this.set(pushPath, []);
			}
			___modify(pushPath, pushModification);
		};

		switch (modificationType) {
			case E_Modification.ARRAY_PUSH:
				__handleArrayPush(path, modification);
				return;
			case E_Modification.ARRAY_SPLICE:
				/**
				 * @param {Number[]|Number} modification
				 * ```
				 *  modification = [2, 3]; => removes 3 items starting from index 2
				 *  modification = 4; => removes 1 item starting from index 4
				 * ```
				 */
				get(this, path).splice(Array.isArray(modification) ? modification[0] : modification, modification[1] || 1);
				return;
			case E_Modification.ARRAY_REMOVE_BY_ID:
				__handleRemoveByID(path, modification);
				return;
			case E_Modification.ITEM_REMOVE:
				__handleItemRemove(path, modification);
				return;
			case E_Modification.ARRAY_CLEAR:
				get(this, path, []).splice(0, get(this, path, []).length);
				return;
			default:
				this.set(path, modification)
		}
	}

	/**
	 * Modify (v2)
	 * ---
	 * Modifies an object or it's properties based on path and type arguments
	 * @param {*} value
	 * @param {string} path
	 * @param {E_Modification} type
	 * @return {ExtendedObject}
	 */
	modifyV2(value, path, type = E_Modification.ITEM_SET) {
		const __handleArrayPush = (p, v) => {
			const root = get(this, p);

			if(IS.property(v, "__modificationOptions__")) {
				if(v.__modificationOptions__.spread === true) {
					return root.push(...v);
				}
			}

			root.push(v);
		};

		const __handleArraySplice = (p, v) => {
			const root = get(this, p);

			if(IS.array(v)) {
				root.splice(v[0], v[1]);
			}
			else {
				root.splice(v, 1);
			}
		};

		const __handleArrayRemoveByID = (p, v) => {
			ArrayUtils.removeID(get(this, p), v, false, false);
		};

		const __handleItemRemove = (p, v) => {
			const root = get(this, p, {});

			if(IS.array(root)) {
				ArrayUtils.removeItem(root, v, false, false);
			}
			else if(IS.object(root)) {
				delete root[v];
			}
			else {
				console.warn("Unknown root type", root);
			}
		};

		const __handleArrayClear = (p) => {
			this.set(p, []);
		};

		switch (type) {
			case E_Modification.ARRAY_PUSH:
				__handleArrayPush(path, value);
				break;
			case E_Modification.ARRAY_SPLICE:
				__handleArraySplice(path, value);
				break;
			case E_Modification.ARRAY_REMOVE_BY_ID:
				__handleArrayRemoveByID(path, value);
				break;
			case E_Modification.ITEM_REMOVE:
				__handleItemRemove(path, value);
				break;
			case E_Modification.ARRAY_CLEAR:
				__handleArrayClear(path);
				break;
			default:
				this.set(path, value);
				break;
		}

		return this;
	}

	/**
	 * Set
	 * ---
	 * Set data at the end of the path. If the end of the path cannot be reached it will create what is missing.
	 * ```
	 *  a = EO({b: {}})
	 *  a.set("b.c.0.d", 1) => {b:{c:[{d:1}]}}
	 *  ...
	 *  a = EO({b:[{id: 5}]})
	 *  a.set("b.#5.a.0.d", 1) => {b:[{id: 5, a:[0:{d:1}]}]}
	 * ```
	 *
	 * **Notice!** Standalone numbers in a path will be processed as an Array index.
	 *
	 * **Notice!** When using a #{Number} (e.g. #4) in the path, it ALWAYS will be placed inside an {} since it's expecting {id: {Number}, ...}
	 *
	 * @param path Path
	 * @param value Value to set
	 * @param allowArrays
	 */
	set(path, value, allowArrays = true) {
		ObjectUtils.set(this, path, value, allowArrays);
		return this;
	}

	/**
	 * To Array
	 * ---
	 * Converts object to array
	 * ```
	 *  ({a:1, b:2}).toArray() => [{key: "a", value: 1}, {key: "b", value: 2}]
	 *  ({a:1, b:2}).toArray(true) => [1, 2]
	 * ```
	 * @param {Boolean} onlyValues Return only values
	 * @returns {Array}
	 */
	toArray(onlyValues = false) {
		let result = [];
		for (let key in this) {
			if (this.hasOwnProperty(key)) {
				if (onlyValues) {
					result.push(this[key]);
				} else {
					result.push({
						key,
						value: this[key]
					});
				}
			}
		}
		return result;
	}

	fillFromArray(arr) {
		arr.forEach((item, i) => {
			this[item.key || i] = IS.property(item, "value") ? item.value : item;
		});
		return this;
	}

	/**
	 * Find
	 * ---
	 * Finds item in object based on the match condition
	 *
	 * **Note** Items gets converted to Array in {key: {String}, value: {*}}
	 * ```
	 *  ({a:1, b:2}).find(item => item.value == 2) => {key: "b", value: 2}
	 * ```
	 * @param {function(*,Number):Boolean} matchCondition
	 * @returns {*}
	 * @see ExtendedObject.toArray
	 * @see Array.find
	 */
	find(matchCondition) {
		let data = this.toArray();
		return data.find(matchCondition);
	}

	/**
	 * Filter
	 * ---
	 * @see Array.filter
	 * @param {function(*,Number):Boolean} filterCondition
	 * @param {Boolean} keepInArray Return filtered items as an array or EO
	 * @returns {ExtendedObject|*[]}
	 */
	filter(filterCondition, keepInArray = false) {
		let data = this.toArray().filter(filterCondition);

		if (keepInArray) {
			return data
		}
		return new ExtendedObject().fillFromArray(data);
	}

	/**
	 * Remove contents
	 * ---
	 * Deletes property (or multiple) from this object
	 * @param {String|function(this):null} resolver
	 * @returns {ExtendedObject}
	 */
	removeContents(resolver) {
		if (IS.string(resolver)) {
			resolver.split(";").forEach(path => {
				path = path.trim().split(".");
				let targetRoot = get(this, path.select(0, "len-1"));
				delete targetRoot[path.lastItem()];
			});
		} else if (IS.fnc(resolver)) {
			resolver(this);
		}
		return this;
	}

	/*TODO ************* VALIDATE WHAT THIS SHOULD MEAN ******************/
	/**
	 * Finds object by ID
	 * ---
	 * @param {Number} id
	 * @param {Boolean} returnIndex
	 * @returns {Number|*}
	 * @see Array.findByID
	 */
	findByID(id, returnIndex = false) {
		let data = this.toArray(true);
		return data.findByID(id, returnIndex);
	}

	/**
	 * Contains ID
	 * ---
	 * Returns true if the provided ID is contained inside the object
	 * @param {Number} id
	 * @returns {Boolean}
	 * @see Array.findByID
	 */
	containsID(id) {
		return this.findByID(id, true) >= 0;
	}
	/*******************************************************************/

	/**
	 * For each
	 * @param {Function} iterator Iterator function
	 */
	forEach(iterator) {
		if(!IS.fnc(iterator)) {
			return;
		}

		this.toArray().forEach((item, i) => {
			if(iterator.length == 1) {
				iterator({item, i});
			} else {
				iterator({key: item.key, i}, item.value);
			}
		});
	}

	map(iterator) {
		return this.toArray().map((item, i) => {
			return iterator({key: item.key, value: item.value, item, i});
		})
	}
}

/**
 * @constructor
 * Extended Object
 * ---
 * @param {Object} data
 * @returns {ExtendedObject}
 * @see ExtendedObject
 */
export const EO = (data) => {
	return IS.instanceOf(data, ExtendedObject) ? data : new ExtendedObject(data);
};
