import { APP_ROUTE } from "../../../shared/models/constants/AppConstants";
import { QS } from "@green24/js-utils";
import { E_OrderWizard_PaymentMethod } from "../../../shared/models/constants/Enums_OrderWizard";

export const E_AFE_OrderWizard_Section = {
	DETAIL: "DETAIL",
	VOUCHERS: "VOUCHERS",
	EMAILS: "EMAILS",
	COMPLETE: "COMPLETE",
	PARTNER_CHECK: "PARTNER_CHECK",
	PIN: "PIN",
	PAYMENT_SELECT: "PAYMENT_SELECT",
	PAYMENT: "PAYMENT",
	PAYMENT_CASH_AMOUNT_SELECT: "PAYMENT_CASH_AMOUNT_SELECT",
};

const baseRoutes = {
	...APP_ROUTE,
	ORDER_WIZARD: (orderID = ":orderID", subsection = ":subsection*") => `/afe/order/${orderID}/${subsection}`,
}

export const AFE_ROUTE = {
	...baseRoutes,
	ORDER_WIZARD_DETAIL: orderNumber => `${baseRoutes.ORDER_WIZARD(orderNumber, E_AFE_OrderWizard_Section.DETAIL)}`,
	ORDER_WIZARD_VOUCHERS: orderNumber => `${baseRoutes.ORDER_WIZARD(orderNumber, E_AFE_OrderWizard_Section.VOUCHERS)}`,
	ORDER_WIZARD_EMAILS: orderNumber => `${baseRoutes.ORDER_WIZARD(orderNumber, E_AFE_OrderWizard_Section.EMAILS)}`,
	ORDER_WIZARD_COMPLETE: orderNumber => `${baseRoutes.ORDER_WIZARD(orderNumber, E_AFE_OrderWizard_Section.COMPLETE)}`,
	ORDER_WIZARD_PARTNER_CHECK: orderNumber => `${baseRoutes.ORDER_WIZARD(orderNumber, E_AFE_OrderWizard_Section.PARTNER_CHECK)}`,
	ORDER_WIZARD_PIN: orderNumber => `${baseRoutes.ORDER_WIZARD(orderNumber, E_AFE_OrderWizard_Section.PIN)}`,
	ORDER_WIZARD_PAYMENT_SELECT: (orderNumber, query) => `${baseRoutes.ORDER_WIZARD(orderNumber, E_AFE_OrderWizard_Section.PAYMENT_SELECT)}${QS(query)}`,
	ORDER_WIZARD_PAYMENT: (orderNumber, paymentMethod = ":paymentMethod") => `${baseRoutes.ORDER_WIZARD(orderNumber, E_AFE_OrderWizard_Section.PAYMENT)}/${paymentMethod}`,
	ORDER_WIZARD_PAYMENT_COMBINED: (orderNumber, cashAmount) => `${AFE_ROUTE.ORDER_WIZARD_PAYMENT(orderNumber, E_OrderWizard_PaymentMethod.COMBINED)}${QS({cashAmount})}`,
	ORDER_WIZARD_PAYMENT_CASH_AMOUNT_SELECT: orderNumber => `${AFE_ROUTE.ORDER_WIZARD(orderNumber, E_AFE_OrderWizard_Section.PAYMENT_CASH_AMOUNT_SELECT)}`,
};
