import { E_TimeString, E_TimeString_Format, E_TimeString_FormatDuration } from "../../models/constants/JiffyContants";
import { IS } from "../IS";
import { Calc } from "../Math/Calc";
import { M_MsToDuration_DefaultOptions } from "../../models/Models_Process";
import { StoreHelpers } from "../StoreHelpers";
import { Process } from "../Process";

/**
 * Time format processor
 * ---
 * Unifies provided time data into milliseconds which then can be formatted
 */
export class TimeFormatProcessor {
	/**
	 * Constructor
	 * ---
	 * Compute milliseconds from **data**
	 * @param {string|number} data Time data
	 */
	constructor(data) {
		if(IS.string(data) || IS.number(data)) {
			this._ms = this._getTime(data);
		}
		else {
			throw TypeError("Unable to process an " + typeof data, data);
		}
	}

	get ms() {
		return this._ms;
	}

	formatted(format, decimals) {
		return Calc.round(this.ms / this._getDuration(this._getTimeFormatKey(format)), decimals);
	}

	/**
	 * @deprecated
	 * Ms to duration
	 * ---
	 * Converts milliseconds to readable duration format
	 * @param {M_MsToDuration_DefaultOptions} options
	 * @returns {string|{seconds: number, hours: number, ms: *, minutes: number, days: number}}
	 * @see Jiffy.readableDuration
	 */
	toDuration(options) {
		if(!IS.number(this.ms)) return;

		options = StoreHelpers.deepMerge(M_MsToDuration_DefaultOptions, options, true);

		let {returnString, hideEmpty, showSec, daysSuffix, hoursSuffix, minutesSuffix, secondsSuffix, daysPadding, hoursPadding, minutesPadding, secondsPadding} = options;

		let seconds = Math.floor(this.ms / 1000);
		let minutes = Math.floor(seconds / 60);
		seconds = seconds % 60;
		let hours = Math.floor(minutes / 60);
		minutes = minutes % 60;
		let days = Math.floor(hours / 24);
		hours = hours % 24;
		let ms = Math.floor(hours * 24 + minutes * 60 + seconds * 60) % 1000;

		if(returnString) {
			let resultString = '';
			if(days > 0 || !hideEmpty) resultString+= Process.pad(days, daysPadding, '0') + daysSuffix + ' ';
			if(hours > 0 || !hideEmpty) resultString+= Process.pad(hours, hoursPadding, '0') + hoursSuffix + ' ';
			if(minutes > 0 || !hideEmpty) resultString+= Process.pad(minutes, minutesPadding, '0') + minutesSuffix + ' ';
			if((seconds > 0 || !hideEmpty) && showSec) resultString+= Process.pad(seconds, secondsPadding, '0') + secondsSuffix + ' ';
			return resultString;
		}

		return {
			totalMs: this.ms, ms, seconds, minutes, hours, days,
		};
	}

	_getTime(timeString) {
		//Number is automatically resolved as time in milliseconds
		if(IS.number(timeString)) return timeString;

		let time = 0;
		let data = this._parseTimeString(timeString);

		data.forEach(item => {
			time+= item.time * this._getDuration(item.format);
		});

		return time;
	}

	_parseTimeString(timeString) {
		const timeStringFormats = Object.values(E_TimeString_Format).sort(
			//Must sort from the longest to the shortest (regex will match f.e. "ms" as 'm' since 'm' is also a target pattern)
			(a, b) => b.length - a.length
		).join(')|(');

		//The form should look like "/\d+((ms)|(y)|(w)|(d)|(h)|(m)|(s))?/gi"
		const timeFormatRegex = new RegExp(
			`\\d+((${timeStringFormats}))?`,
			'ig'
		);

		const timeData = [];
		const matches = timeString.match(timeFormatRegex) || [];

		matches.forEach(match => {
			let time = parseFloat(match.replace(/[a-zA-Z]]/g, ''));
			timeData.push({
				time,
				format: this._getTimeFormatKey(match.replace(time, '')) || E_TimeString.MILLISECOND,
			});
		});

		return timeData.filter(item => !!item.format);
	}

	_getTimeFormatKey(char) {
		return Object.keys(E_TimeString_Format).find(key => E_TimeString_Format[key] == char);
	}

	_getDuration(format) {
		return E_TimeString_FormatDuration[format];
	}
}
