import { E_GenericAction } from "../actions/genericActions";
import { E_Modification, EO, get } from "@green24/js-utils";

function handleGenericModify(state, action, selector) {
	const {type, path, value} = action.modification || {};
	let newEditData = EO(selector(state).edit).clone(true);
	newEditData.modify(type, path, value);

	return {
		...state,
		edit: newEditData,
	};
}

const handleGenericModificationFulfilled = (state, action, deleteState, modificationState) => {
	if (get(action, `payload.type`) === E_Modification.ITEM_REMOVE) { //Handle delete
		return deleteState;
	}

	return modificationState;
}

export const Reducer_Generic_Item = (actionPrefix, selector = state => state, editOnly = false, customReducer = state => undefined, initialStateData) => {
	const initialState = {
		data: null,
		edit: null,
		sending: false,
		fetching: false,
		fetched: false,
		failed: false,
		error: null,
		deleted: null,
		lastUpdate: null,
		editOnly,
		...initialStateData,
	};

	return (state = initialState, action) => {
		const custom = customReducer(state, action);
		if(custom) {
			return custom;
		}

		switch (action.type) {
			case `${actionPrefix}_${E_GenericAction.FETCH_PENDING}`:
				return {
					...state,
					data: null,
					fetched: false,
					fetching: true,
					failed: false,
					error: null,
				};
			case `${actionPrefix}_${E_GenericAction.BACKGROUND_FETCH_PENDING}`:
				return {
					...state,
					fetching: true,
				};
			case `${actionPrefix}_${E_GenericAction.FETCH_FULFILLED}`:
				return {
					...initialState,
					data: !editOnly ? action.payload : null,
					edit: editOnly ? action.payload : null,
					fetching: false,
					fetched: true,
					lastUpdate: Date.now(),
				};
			case `${actionPrefix}_${E_GenericAction.FETCH_REJECTED}`:
				return {
					...state,
					fetching: false,
					failed: true,
					error: action.payload,
				};
			case `${actionPrefix}_${E_GenericAction.EDIT_INIT}`:
				return {
					...state,
					edit: action.payload || EO(state.data).clone(true),
				};
			case `${actionPrefix}_${E_GenericAction.EDIT_CANCEL}`:
				return {
					...state,
					edit: null,
				};
			case `${actionPrefix}_${E_GenericAction.EDIT_MODIFY}`:
				return handleGenericModify(state, action, selector);
			case `${actionPrefix}_${E_GenericAction.EDIT_PENDING}`:
				return {
					...state,
					sending: true,
					fetching: true,
					failed: false,
					error: null,
				};
			case `${actionPrefix}_${E_GenericAction.EDIT_FULFILLED}`:
				return handleGenericModificationFulfilled(
					state,
					action,
					{
						deleted: get(action, `payload.id`),
						fetching: false,
						sending: false,
						data: null,
						edit: null,
						lastUpdate: Date.now(),
					},
					{
						...state,
						fetching: false,
						sending: false,
						data: !editOnly ? EO(action.payload || action.modification).clone(true) : null,
						edit: editOnly ? EO(action.payload || action.modification).clone(true) : null,
						lastUpdate: Date.now(),
					}
				)
			case `${actionPrefix}_${E_GenericAction.EDIT_REJECTED}`:
				return {
					...state,
					fetching: false,
					sending: false,
					failed: true,
					error: action.payload,
				};
			case `${actionPrefix}_${E_GenericAction.ITEM_CLEAR}`:
				return {
					...initialState,
				};
			case `${actionPrefix}_${E_GenericAction.CACHE_CLEAR}`:
				return {
					...state,
					lastUpdate: initialState.lastUpdate,
				};
			default:
				return state;
		}
	}
}
