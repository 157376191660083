import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses, IS } from "@green24/js-utils";
import Interpret from "../../../shared/components/Localization/Interpret";
import Price from "../../../shared/components/Price/Price";
import ButtonsConstructor from "../../../shared/components/Input/Button/ButtonsConstructor";
import { E_OrderWizard_PaymentMethod } from "../../../shared/models/constants/Enums_OrderWizard";
import PaymentContextProvider from "../../../shared/components/Payment/PaymentContextProvider";
import { AFE_ROUTE } from "../../models/constants/AfeRoutes";
import { Redirect } from "../../../shared/components/Redirect";
import ThemeHeader from "../../../shared/components/Theme/ThemeHeader";

class AfeOrderWizardPayment extends Component {
	constructor() {
		super();

		this.state = {
			paid: false,
		}
	}

	_renderPaymentContent(paymentContext, cost) {
		const {leftToAccept, leftToReturn, totalAccepted, totalReturned} = paymentContext.paymentData || {};

		switch (paymentContext.paymentMethod) {
			case E_OrderWizard_PaymentMethod.CARD:
				return (
					<>
						<h2><Interpret id={"paymentInstructions_" + E_OrderWizard_PaymentMethod.CARD}/></h2>

						<div className={"grid"}>
							<label><Interpret id={"toBePaid"}/>:</label>
							<span className={"price"}><Price value={IS.array(cost) ? cost[1] : cost}/></span>
						</div>
					</>
				);
			case E_OrderWizard_PaymentMethod.CASH:
				return (
					<>
						<h2><Interpret id={"paymentInstructions_" + E_OrderWizard_PaymentMethod.CASH}/></h2>

						<div className={"grid"}>
							<label><Interpret id={"toBePaid"}/>:</label>
							<span className={"price"}><Price value={Math.round(IS.array(cost) ? cost[0] : cost)} fractionDigits={false}/></span>

							<label><Interpret id={"paid"}/>:</label>
							<span className={"paid"}><Price value={totalAccepted} fractionDigits={false}/></span>

							<label><Interpret id={"remaining"}/>:</label>
							<span className={"remaining"}><Price value={leftToAccept} fractionDigits={false}/></span>

							<hr/>

							<label><Interpret id={"toReturn"}/>:</label>
							<span className={"return"}><Price value={leftToReturn} fractionDigits={false}/></span>

							<label><Interpret id={"returned"}/>:</label>
							<span className={"returned"}><Price value={totalReturned} fractionDigits={false}/></span>
						</div>
					</>
				);
		}
	}

	_renderContent({order, paymentMethod, cashAmount}, {}) {
		cashAmount = parseFloat(cashAmount);
		const cost = cashAmount ? [cashAmount, order.price - cashAmount] : order.price;

		return (
			<PaymentContextProvider
				paymentMethod={paymentMethod}
				orderID={order.orderNumber}
				startPaymentOnMount={true}
				cost={cost}
				paymentSelectRoute={AFE_ROUTE.ORDER_WIZARD_PAYMENT_SELECT(order.orderNumber)}

				onPaymentSuccess={() => this.setState({paid: true})}
			>
				{paymentContext => {
					return (
						<>
							{this._renderPaymentContent(paymentContext, cost)}

							<nav className={"navigation-buttons"}>
								<ButtonsConstructor buttons={[
									paymentContext.paymentInProgress &&
									{
										text: paymentContext.cancelling ? "cancelling" : "cancel",
										action: () => paymentContext.cancelPayment(),
										disabled: paymentContext.cancelling || paymentContext.cancelled,
									}
									||
									{
										text: "back",
										href: AFE_ROUTE.ORDER_WIZARD_PAYMENT_SELECT(order.orderNumber),
									}
								]}/>
							</nav>
						</>
					)
				}}
			</PaymentContextProvider>
		);
	}

	render({className, style, order}, {paid}) {
		return (
			<section className={combineClasses("order-wizard-payment", className)} style={style}>
				{
					paid &&
					<Redirect to={AFE_ROUTE.ORDER_WIZARD_EMAILS(order.orderNumber)}/>
				}

				<ThemeHeader homeButton={false} motiv={true}/>

				<h1>
					<Interpret id={this._getTitleFromPaymentMethod()}/>
				</h1>

				{this._renderContent(this.props, this.state)}
			</section>
		);
	}

	_getTitleFromPaymentMethod() {
		const {paymentMethod} = this.props;

		switch (paymentMethod) {
			case E_OrderWizard_PaymentMethod.CARD:
				return "cardPayment";
			case E_OrderWizard_PaymentMethod.CASH:
				return "cashPayment";
			case E_OrderWizard_PaymentMethod.COMBINED:
				return "combinedPayment";
		}
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			order: PropTypes.object,
			paymentMethod: PropTypes.oneOf(Object.values(E_OrderWizard_PaymentMethod)),
		}
	}

	static get stateTypes() {
		return {
			paid: PropTypes.bool,
		}
	}
}

export default AfeOrderWizardPayment;
