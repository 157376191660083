import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import ButtonsConstructor from "../../../shared/components/Input/Button/ButtonsConstructor";
import Fa from "../../../shared/components/Fa";
import { AFE_ROUTE } from "../../models/constants/AfeRoutes";
import Interpret from "../../../shared/components/Localization/Interpret";
import ThemeHeader from "../../../shared/components/Theme/ThemeHeader";

class AfeOrderWizardEmailSelect extends Component {
	constructor(props) {
		super();

		this.state = {
			emails: [],
		}
	}

	render({className, style, order, onSubmit}, {emails}) {
		return (
			<section className={combineClasses("order-wizard-email-select", className)} style={style}>
				<ThemeHeader motiv={false} homeButton={true} customer={order.customerName}/>

				<h1><Interpret id={"sendToEmails"}/></h1>

				<div className={"options"}>
					<ButtonsConstructor buttons={order.emails.map(email => {
						return {
							className: "no-style",
							text: (
								<>
									<Fa className={"checkbox"} icon={"check"}/>

									<span className={"title"}>{email}</span>
								</>
							),
							active: emails.includes(email),
							action: () => this._toggleEmail(email),
						}
					})}/>
				</div>

				<nav className={"navigation-buttons"}>
					<ButtonsConstructor buttons={[
						{
							text: "back",
							href: AFE_ROUTE.ORDER_WIZARD_DETAIL(order.orderNumber),
						},
						{
							text: "continue",
							action: () => onSubmit(emails),
						}
					]}/>
				</nav>
			</section>
		);
	}

	_toggleEmail(email) {
		this.setState(state => {
			if(state.emails.includes(email)) {
				return {
					emails: state.emails.filter(e => e != email),
				}
			}

			return {
				emails: [...state.emails, email],
			}
		});
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			order: PropTypes.object.isRequired,

			onSubmit: PropTypes.func.isRequired,
		}
	}
}

export default AfeOrderWizardEmailSelect;
