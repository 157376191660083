import React from "react";
import PropTypes from "prop-types";
import { get, IS } from "@green24/js-utils";
import { M_PropTypes_Shared } from "../models/Models_PropTypes";

class ComponentBase extends React.Component {
	constructor(props) {
		super(props);

		this.setRef = this.setRef.bind(this);
		this._valueUpdateListeners = [];
		this._eventListeners = [];
	}

	componentDidMount() {
		this._isMounted = true;
		this.props.onMount && this.props.onMount();
	}

	componentWillUnmount() {
		this._isMounted = false;
		this._valueUpdateListeners = [];
		this._eventListeners.forEach(listener => listener.remove());
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this._verifyValueUpdates(prevProps, prevState);
	}

	//Abstract functions; Must be declared to prevent exceptions when calling super.functionName() from children
	/**
	 * @abstract
	 * @param error
	 * @param errorInfo
	 */
	componentDidCatch(error, errorInfo) {}

	/**
	 * @abstract
	 * @param nextProps
	 * @param nextState
	 * @param nextContext
	 * @return {boolean}
	 */
	shouldComponentUpdate(nextProps, nextState, nextContext) {return true;}

	setRef(element) {
		if (!element) {return;}

		const {onRefSet} = this.props;
		let refName;
		if(element.getAttribute) {
			refName = element.getAttribute("data-ref-name") || element.getAttribute("name") || "root";
		}
		else {
			refName = get(element, "props.name", "root");
			element = element.getRef && element.getRef();
		}

		this[refName] = element;
		onRefSet(element, refName);
	}

	mountedSetState(...props) {
		//Must compare with Bool false.
		// There is a possibility that the component is already able to setState but it's not mounted yet, thus resulting in this._isMounted = undefined
		// This condition should prevent only setting state to component that is no longer mounted or valid whatsoever
		if(this._isMounted !== false) {
			this.setState(...props);
		}
	}

	addValueUpdateListener(path, callback, blocking = false) {
		this._valueUpdateListeners.push({
			path,
			callback,
			blocking,
		});
	}

	removeValueUpdateListener(path) {
		this._valueUpdateListeners = this._valueUpdateListeners.filter(listener => listener.path !== path);
	}

	getValueWithUpdateListener(path, callback, blocking = false) {
		this.addValueUpdateListener(path, callback, blocking);

		callback(get(this, path), undefined);
	}

	_verifyValueUpdates(prevProps, prevState) {
		let stop = false;
		let blockedListenerGroups = [];
		this._valueUpdateListeners.forEach(listener => {
			if(stop === false && !blockedListenerGroups.includes(listener.blocking)) {
				let pathParts = IS.array(listener.path) ? listener.path : listener.path.split(".");
				let newValue = get(this, listener.path);
				let prevValue = get(pathParts[0] === "props" ? prevProps : prevState, pathParts.splice(1, pathParts.length));

				if(!IS.equal(prevValue, newValue)) {
					if(IS.fnc(listener.callback)) {
						listener.callback(newValue, prevValue);
					}

					//If blocking = true, it should block all following listeners to prevent unnecessary re-triggering with the same value (if the async cannot update it up in time)
					// (useful if listener contains value update which affects other values that are listened for later on)
					if(listener.blocking === true) {
						stop = true;
					}
					else if (listener.blocking) {
						//Block only for specific group
						blockedListenerGroups.push(listener.blocking);
					}
				}
			}
		});
	}

	addListener(target, eventName, callback, options) {
		if(target) {
			target.addEventListener(eventName, callback, options);

			let remove = () => {
				target.removeEventListener(eventName, callback, options);
			};
			this._eventListeners.push({
				eventName,
				options,
				remove
			});
			return remove;
		}
		console.error("Target is undefined for the event:", eventName, options);
		return null;
	}

	static get propTypes() {
		//Warning! If modified, check every child component for {...this.props} or similar spread. Console error will raise on native components
		//e.g. Warning: Unknown event handler property `onRefSet`. It will be ignored.
		return {
			...M_PropTypes_Shared,
			onRefSet: PropTypes.func,
			onMount: PropTypes.func,
		}
	}

	static get defaultProps() {
		return {
			onRefSet: () => null,
		}
	}

}

export default ComponentBase;
