import { get } from "@green24/js-utils";

export class Selector {
	constructor(rootPath, state) {
		this._rootPath = rootPath;
		this._state = state;
	}

	selectRoot(state = this._state) {
		return Selector.selectRoot(state, this._rootPath);
	}

	static selectRoot(state, rootPath) {
		return get(state, rootPath);
	}

	selectData(state = this._state) {
		return Selector.selectData(state, this._rootPath);
	}

	static selectData(state, rootPath) {
		let root = Selector.selectRoot(state, rootPath);

		return get(root, root.editOnly ? "edit" : "data");
	}

	selectEdit(state = this._state) {
		return Selector.selectEdit(state, this._rootPath);
	}

	static selectEdit(state, rootPath) {
		return get(Selector.selectRoot(state, rootPath), "edit");
	}

	selectStates(state = this._state) {
		return Selector.selectStates(state, this._rootPath);
	}

	static selectStates(state, rootPath) {
		const {fetching, fetched, failed, error, deleted, } = Selector.selectRoot(state, rootPath) || {};
		return {fetching, fetched, failed, error, deleted, };
	}
}
