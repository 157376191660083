import { KVElektroGenericServices } from "../../shared/services/api-connector";

class AfBoxServices extends KVElektroGenericServices {
	getOrderForBox(boxID) {
		return this.sendRequest({
			url: `${this.url}/${boxID}/order`,
		});
	}

	openForProcessing(boxID, simpleInvoice, vouchers) {
		return this.sendRequest({
			url: `${this.url}/${boxID}/order`,
			method: "POST",
			body: JSON.stringify({
				simpleInvoice,
				vouchers,
			}),
		});
	}
}

export const afBoxServices = new AfBoxServices(`/af/box`);
