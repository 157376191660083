import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import ThemeHeader from "../../../shared/components/Theme/ThemeHeader";
import ButtonsConstructor from "../../../shared/components/Input/Button/ButtonsConstructor";
import CombineComponentsFromTranslation from "../../../shared/components/Localization/CombineComponentsFromTranslation";
import Price from "../../../shared/components/Price/Price";
import { AFE_ROUTE } from "../../models/constants/AfeRoutes";
import { E_OrderWizard_PaymentMethod } from "../../../shared/models/constants/Enums_OrderWizard";
import withConfiguration from "../../../shared/components/hoc/withConfiguration";
import Loading from "../../../shared/components/Loading";
import ErrorBlock from "../../../shared/components/ErrorBlock";
import Interpret from "../../../shared/components/Localization/Interpret";
import { afeOrderThunk } from "../../../shared/store/store";
import { orderServices } from "../../../shared/services/order-services";

class AfeOrderWizardPaymentSelect extends Component {
	constructor() {
		super();

		this.state = {
			loading: false,
			error: null,
		}
	}

	componentDidMount() {
		const {justStarted} = this.props;

		if(!justStarted) {
			//FFS: This can update the price if the CASH payment method will ever be enabled
			// this._refreshPrice();
		}
	}

	_renderContent() {
		const {order, configuration} = this.props;

		return (
			<>
				<h1>
					<CombineComponentsFromTranslation id={"toPay"}>
						{{
							price: <Price value={order.price} currency={order.currency}/>
						}}
					</CombineComponentsFromTranslation>
				</h1>

				<nav className={"payment-options"}>
					<ButtonsConstructor buttons={[
						configuration.paymentMethods.includes(E_OrderWizard_PaymentMethod.CARD) &&
						{
							text: "cardPayment",
							href: AFE_ROUTE.ORDER_WIZARD_PAYMENT(order.orderNumber, E_OrderWizard_PaymentMethod.CARD),
						},
						configuration.paymentMethods.includes(E_OrderWizard_PaymentMethod.CASH) &&
						{
							text: "cashPayment",
							href: AFE_ROUTE.ORDER_WIZARD_PAYMENT(order.orderNumber, E_OrderWizard_PaymentMethod.CASH),
						},
						configuration.paymentMethods.includes(E_OrderWizard_PaymentMethod.CARD) &&
						configuration.paymentMethods.includes(E_OrderWizard_PaymentMethod.CASH) &&
						{
							text: "combinedPayment",
							href: AFE_ROUTE.ORDER_WIZARD_PAYMENT_CASH_AMOUNT_SELECT(order.orderNumber),
						},
						{
							text: "back",
							//TBD: Back where???
							href: AFE_ROUTE.ORDER_WIZARD_DETAIL(order.orderNumber),
						}
					]}/>
				</nav>
			</>
		);
	}

	render({className, style}, {loading, error}) {
		return (
			<section className={combineClasses("order-wizard-payment-select", className)} style={style}>
				<ThemeHeader homeButton={true} motiv={false}/>

				{
					loading &&
					<Loading title={"loadingPrice"} className={"with-background big"} style={{zIndex: 1}}/>
					||
					error &&
					<ErrorBlock
						error={{statusCode: -1}}
						title={"error_payment_amountFetchTitle"}
						message={<Interpret id={"error_payment_amountFetchMessage"}/>}
						onRetry={() => this._refreshPrice()}
					/>
					||
					this._renderContent()
				}
			</section>
		);
	}

	_refreshPrice() {
		const {order} = this.props;

		this.setState({loading: true, error: null});
		orderServices.getItem(order.orderID).then(data => {
			afeOrderThunk.set({
				...order,
				price: data.amount - data.paidByCash,
			});
		}, (error) => {
			this.setState({error});
		}).finally(() => {
			this.setState({loading: false});
		});
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			order: PropTypes.object,

			//Query parameter
			justStarted: PropTypes.string,

			//withConfiguration
			configuration: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			loading: PropTypes.bool,
			error: PropTypes.object,
		}
	}
}

export default withConfiguration(AfeOrderWizardPaymentSelect);
