import { APP_ROUTE } from "../../../shared/models/constants/AppConstants";
import { E_OrderWizard_PaymentMethod } from "../../../shared/models/constants/Enums_OrderWizard";
import { QS } from "@green24/js-utils";

export const E_AFN_OrderWizard_Section = {
	ENTER_NUMBER: "ENTER_NUMBER",
	CONFIRM: "CONFIRM",
	PAYMENT_SELECT: "PAYMENT_SELECT",
	PAYMENT: "PAYMENT",
	PAYMENT_COMPLETED: "PAYMENT_COMPLETED",
	PAYMENT_CASH_AMOUNT_SELECT: "PAYMENT_CASH_AMOUNT_SELECT",
	VOUCHERS: "VOUCHERS",
};

/**
 * @readonly
 * @enum {String}
 */
export const AFN_ROUTE = {
	...APP_ROUTE,
	ORDER_WIZARD: (section = ":section*", query) => `/afn/order/${section}${QS(query)}`,
	ORDER_WIZARD_PAYMENT_METHOD: (paymentMethod = ":paymentMethod") => `${AFN_ROUTE.ORDER_WIZARD(E_AFN_OrderWizard_Section.PAYMENT)}/${paymentMethod}`,
	ORDER_WIZARD_PAYMENT_COMBINED: (cashAmount) => `${AFN_ROUTE.ORDER_WIZARD_PAYMENT_METHOD(E_OrderWizard_PaymentMethod.COMBINED)}${QS({cashAmount}).toString()}`,
}
