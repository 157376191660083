import React from "react";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import { M_Icons } from "../models/Models_Icons";

class Fa extends React.Component {
	render({className, style, icon}) {
		let svg = M_Icons[icon];
		if(!svg) {
			throw Error("Unknown icon: " + icon);
		}

		//svgr (webpack plugin) exports SVGs as functional components
		if(typeof svg === "function") {
			//Initialize the component so it can be used in the React.cloneElement()
			svg = svg();
		}

		return React.cloneElement(
			svg,
			{
				className: combineClasses("fa", className),
				icon,
				style,
			},
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,

			/**
			 * @type M_Icons
			 */
			icon: PropTypes.oneOf(Object.keys(M_Icons)),
		}
	}
}

export default Fa;
