import { IS } from "../IS";
import { Calc } from "./Calc";

export class Vec2 extends Array {
	/**
	 * @constructor
	 * @name Vec2 constructor Vec2 instance
	 * @param {Vec2} vector
	 */
	/**
	 * @constructor
	 * @name Vec2 constructor native array
	 * @param {Array} array
	 */
	/**
	 * @constructor
	 * @name Vec2 constructor 2 args
	 * @param {Number} x
	 * @param {Number} y
	 */
	constructor(x = 0, y = 0) {
		super(2);

		this.set(...Vec2.resolveValueAsVector(x, y));
	}

	static get NULL() {
		return Vec2.static(0, 0);
	}

	static get ONE() {
		return Vec2.static(1, 1);
	}

	static static(x, y) {
		return new Vec2(x, y);
	}

	/* Components */

	get x() {
		return this[0];
	}

	get y() {
		return this[1];
	}

	/**
	 * Magnitude
	 * ---
	 * @returns {number} Magnitude
	 */
	get magnitude() {
		return Math.sqrt(this.x * this.x + this.y * this.y);
	}

	/**
	 * Normalized
	 * ---
	 * Returns normalized vector
	 * @returns {Vec2}
	 */
	get normalized() {
		let m = this.magnitude;
		if(m > 0) {
			return this.divide(m, true);
		}
		return Vec2.NULL;
	}

	/**
	 * Set
	 * ---
	 * @param {Number} x
	 * @param {Number} y
	 */
	set(x, y) {
		this.length = 0;
		this.push(x, y);
	}

	static resolveValueAsVector(value, secondScalarValue = value) {
		if(value instanceof Vec2) return [value.x, value.y];
		if(IS.array(value)) return value;
		return [value, secondScalarValue];
	}

	/* Comparison */

	/**
	 * Equals
	 * ---
	 * @param {Vec2} vector
	 * @returns {Boolean}
	 */
	equals(vector) {
		return this.x == vector.x && this.y == vector.y;
	}

	/* Basic operations */

	/**
	 * Modify vector
	 * ---
	 * @param {Vec2} vector
	 * @param {Vec2|Number} value
	 * @param {'+'|'-'|'*'|'/'} operator
	 * @returns {Vec2}
	 */
	static modify(vector, value, operator) {
		let x = 0;
		let y = 0;

		switch (operator) {
			case "+":
				if(value instanceof Vec2) {
					x = vector.x + value.x;
					y = vector.y + value.y;
				}
				else if(IS.number(value)) {
					x = vector.x + value;
					y = vector.y + value;
				}
				break;
			case "-":
				if(value instanceof Vec2) {
					x = vector.x - value.x;
					y = vector.y - value.y;
				}
				else if(IS.number(value)) {
					x = vector.x - value;
					y = vector.y - value;
				}
				break;
			case "*":
				if(value instanceof Vec2) {
					x = vector.x * value.x;
					y = vector.y * value.y;
				}
				else if(IS.number(value)) {
					x = vector.x * value;
					y = vector.y * value;
				}
				break;
			case "/":
				if(value instanceof Vec2) {
					x = vector.x / value.x;
					y = vector.y / value.y;
				}
				else if(IS.number(value)) {
					x = vector.x / value;
					y = vector.y / value;
				}
				break;
		}

		return new Vec2(x, y);
	}

	/**
	 * Add
	 * ---
	 * @param {Vec2|Number} value
	 * @param {Boolean} immutable
	 * @returns {Vec2}
	 */
	add(value, immutable = true) {
		const [x, y] = Vec2.modify(this, value, '+');
		if(immutable) {
			return new Vec2(x, y);
		}
		this.set(x, y);
		return this;
	}

	/**
	 * Subtract
	 * ---
	 * @param {Vec2|Number} value
	 * @param {Boolean} immutable
	 * @returns {Vec2}
	 */
	subtract(value, immutable = true) {
		const [x, y] = Vec2.modify(this, value, '-');
		if(immutable) {
			return new Vec2(x, y);
		}
		this.set(x, y);
		return this;
	}

	/**
	 * Multiply
	 * ---
	 * @param {Vec2|Number} value
	 * @param {Boolean} immutable
	 * @returns {Vec2}
	 */
	multiply(value, immutable = true) {
		const [x, y] = Vec2.modify(this, value, '*');
		if(immutable) {
			return new Vec2(x, y);
		}
		this.set(x, y);
		return this;
	}

	/**
	 * Divide
	 * ---
	 * @param {Vec2|Number} value
	 * @param {Boolean} immutable
	 * @returns {Vec2}
	 */
	divide(value, immutable = true) {
		const [x, y] = Vec2.modify(this, value, '/');
		if(immutable) {
			return new Vec2(x, y);
		}
		this.set(x, y);
		return this;
	}

	/**
	 * Normalize
	 * ---
	 * Normalizes current vector
	 */
	normalize() {
		this.set(...this.normalized);
		return this;
	}

	/* Advanced operations */

	/**
	 * Point on circle
	 * ---
	 * Returns position of the point along the circle
	 * @param {Vec2} center Center of the circle
	 * @param {Number} radius Radius of the circle
	 * @param {Number} angleRad Angle offset of the point (in radians)
	 * @returns {Vec2}
	 * @see https://en.wikipedia.org/wiki/Circle#Equations Parametric form
	 */
	static onCircleRad(center, radius, angleRad) {
		return new Vec2(
			center.x + radius * Math.cos(angleRad),
			center.y + radius * Math.sin(angleRad)
		);
	};

	/**
	 * Point on circle
	 * ---
	 * Returns position of the point along the circle
	 * @param {Vec2} center Center of the circle
	 * @param {Number} radius Radius of the circle
	 * @param {Number} angle Angle offset of the point (in degrees)
	 * @returns {Vec2}
	 * @see https://en.wikipedia.org/wiki/Circle#Equations Parametric form
	 */
	static onCircle(center, radius, angle) {
		return Vec2.onCircleRad(center, radius, angle * Math.PI / 180);
	}

	static getDirection(point1, point2) {
		return new Vec2(
			point2.x - point1.x,
			point2.y - point1.y
		);
	}

	/**
	 * Offset point along vector
	 * ---
	 * Offsets point along the directional vector
	 * @param {Vec2} vector Directional vector
	 * @param {Number} distance Distance
	 * @param {Boolean} immutable
	 * @returns {Vec2}
	 */
	offsetAlongVector(vector, distance = 1, immutable = true) {
		let normalized = immutable ? vector.normalized : vector.normalize();
		return normalized.multiply(distance, immutable).add(this, immutable);
	};

	lerp(alpha, vector, immutable = true) {
		let x = Calc.lerp(alpha, this.x, vector.x);
		let y = Calc.lerp(alpha, this.y, vector.y);

		if(immutable) {
			return new Vec2(x, y);
		}
		this.set(x, y);
		return this;
	}

	invLerp(value, vector, immutable = true) {
		let x = Calc.invLerp(value.x, this.x, vector.x);
		let y = Calc.invLerp(value.y, this.y, vector.y);

		if(immutable) {
			return new Vec2(x, y);
		}
		this.set(x, y);
		return this;
	}

	mapRange(low, high, lowOut = 0, highOut = 1, immutable = true) {
		let lowR = Vec2.resolveValueAsVector(low);
		let highR = Vec2.resolveValueAsVector(high);
		let lowOutR = Vec2.resolveValueAsVector(lowOut);
		let highOutR = Vec2.resolveValueAsVector(highOut);

		let x = Calc.mapRange(this.x, lowR[0], highR[0], lowOutR[0], highOutR[0]);
		let y = Calc.mapRange(this.y, lowR[1], highR[1], lowOutR[1], highOutR[1]);

		if(immutable) {
			return new Vec2(x, y);
		}
		this.set(x, y);
		return this;
	}

	/* Style outputs */

	toStyleVariables(xVar = "--x", yVar = "--y", unit = null) {
		return {
			[xVar]: this.x + unit,
			[yVar]: this.y + unit,
		}
	}

	toPositionStyleVariables(unit) {
		return this.toStyleVariables("--x", "--y", unit);
	}

	toSizeStyleVariables(unit) {
		return this.toStyleVariables("--width", "--height", unit);
	}

	/* String outputs */

	// noinspection SpellCheckingInspection
	toString(prettyfy = false) {
		if(prettyfy) return `[${this.x}, ${this.y}]`;
		return `${this.x},${this.y}`;
	}
}
