import { applicationActions } from "../actions/actions";
import { E_Modification } from "@green24/js-utils";
import { StoreThunk } from "./StoreThunk";
import { applicationSelector } from "../selectors/selectors";

const Thunk_modifyApplicationData = (value, path, type = E_Modification.ITEM_SET) => {
	return (dispatch) => {
		dispatch(applicationActions.getEditModify(value, path, type));
	}
};

const Thunk_setIdleTimeout = (time) => {
	return (dispatch, getState) => {
		if(time === undefined) {
			time = applicationSelector.selectData(getState()).redirectTimeout;
		}

		dispatch(applicationActions.getEditModify({time, signature: Symbol()}, "idleTimeout", E_Modification.ITEM_SET));
	}
}

export class Thunk_Application extends StoreThunk {
	modifyApplicationData(value, path, type = E_Modification.ITEM_SET) {
		return this.connect(Thunk_modifyApplicationData(value, path, type));
	}

	setIdleTimeout(time) {
		if(time === undefined) {
			time = applicationSelector.selectData(this.getState()).redirectTimeout;
		}

		return this.connect(Thunk_setIdleTimeout(time));
	}

	setIdleEffect(effect) {
		this.dispatch(applicationActions.getEditModify(effect, "idleEffect", E_Modification.ITEM_SET));
	}
}
