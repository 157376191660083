import { rootConnector } from "./api-connector";
import { GenericServices, QueryStringManager, RequestHeaders, ResponseHandlers } from "@green24/js-utils";

class AuthServices extends GenericServices {
	/**
	 * Login
	 * ---
	 * @param {string} username
	 * @param {string} password
	 * @return {Promise<Headers|HttpResponseError>}
	 */
	login(username, password) {
		return this.sendRequest({
			url: `${this.url}/login`,
			method: "POST",
			headers: RequestHeaders.urlEncoded(),
			body: decodeURI(new QueryStringManager({username, password}).toString(false)),
		}, ResponseHandlers.headers);
	}
}

export const authServices = new AuthServices(rootConnector, `/auth`);
