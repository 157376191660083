import React, { Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { ArrayUtils, combineClasses } from "@green24/js-utils";
import Toast from "./Toast";
import { M_Toast } from "../../../models/Models_Toast";
import { ComponentUtils } from "../../../utils/ComponentUtils";
import root from "window-or-global";

class Toaster extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			toasts: [],
		};

		root.toaster = this;
	}

	render({className, style}, {toasts}) {
		return (
			<section className={combineClasses("toaster", className)} style={style}>
				{
					toasts.map((toast) => {
						return (
							<Fragment key={toast.id}>
								<Toast data={toast} accent={toast.accent} onClose={() => this._removeToast(toast.id)}/>
							</Fragment>
						)
					})
				}
			</section>
		);
	}

	/**
	 * Show toast
	 * ---
	 * @param {M_Toast} toastData
	 */
	showToast(toastData) {
		let data = ComponentUtils.resolvePolymorphProp(
			toastData,
			{
				component: c => ({
					title: c,
				}),
				string: s => ({
					title: s,
				}),
				array: arr => ({
					title: arr[0],
					message: arr[1],
					lifespan: arr[2],
					icon: arr[3]
				}),
			},
			() => toastData,
		);

		this._addToast(data);
	}

	_addToast(toastData) {
		this.setState(state => {
			return {
				toasts: ArrayUtils.push(state.toasts, true, {
					...M_Toast,
					id: Math.random() + Date.now(),
					...toastData,
				}),
			}
		});
	}

	_removeToast(toastID) {
		this.setState(state => {
			return {
				toasts: ArrayUtils.removeID(state.toasts, toastID, true, true),
			}
		});
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,
		}
	}

	static get stateTypes() {
		return {
			toasts: PropTypes.array,
		}
	}
}

export default Toaster;
