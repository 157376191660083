import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import MaintenanceLogin from "./MaintenanceLogin";
import MaintenanceDetail from "./MaintenanceDetail";
import { AppUtils } from "../../utils/AppUtils";

class Maintenance extends Component {
	componentWillUnmount() {
		if(!AppUtils.keepTokenOnReload) {
			localStorage.removeItem("token");
		}
	}

	render({className, style, header}, {}) {
		if(!localStorage.getItem("token")) {
			return <MaintenanceLogin
				onLoggedIn={(token) => {
					localStorage.setItem("token", token);
					this.forceUpdate();
				}}
			/>
		}

		return (
			<section className={combineClasses("maintenance", className)} style={style}>
				<MaintenanceDetail/>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			header: PropTypes.any,
		}
	}
}

export default Maintenance;
