import { Component, toChildArray } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";

class WidestContent extends Component {
	render({className, style, children}, {}) {
		return (
			<section className={combineClasses("widest-content", className)} style={style}>
				<div className={"dummy"}>{children}</div>

				<div className={"visible-content"}>
					{
						toChildArray(children).find(child => child.props.visible === true) || '?'
					}
				</div>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,
		}
	}
}

export default WidestContent;
