import { APIConnector, GenericServices } from "@green24/js-utils";
import root from "window-or-global";
import { configurationSelector } from "../store/selectors/selectors";
import { ErrorUtils } from "../utils/ErrorUtils";

export const getApiUrl = () => localStorage.getItem("apiUrl") || `${(configurationSelector.selectData(root.store.getState()) || {}).apiURL}`;

let connector = new APIConnector(
	() => `${getApiUrl()}/kv`,
	() => (configurationSelector.selectData(root.store.getState()) || {}).token,
	res => {
		if(res.error === "INTERNAL_SERVER_ERROR") {
			ErrorUtils.handleUnexpectedError(res);
		}
	}
);

export class KVElektroGenericServices extends GenericServices {
	constructor(rootURL, receiveProcessor, uploadProcessor) {
		super(connector, rootURL, receiveProcessor, uploadProcessor);
	}
}

export const rootConnector = new APIConnector(
	() => getApiUrl(),
	() => localStorage.getItem("token"),
	res => {
		if(res.error === "INTERNAL_SERVER_ERROR") {
			ErrorUtils.handleUnexpectedError(res);
		}
	}
);
