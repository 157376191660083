export class BasicServices {
	/**
	 * @constructor
	 * @param {APIConnector} apiConnector
	 * @param {String} rootURL API methods root (or shared) URL
	 */
	constructor(apiConnector, rootURL) {
		this._apiConnector = apiConnector;
		this._URL = rootURL;
	}

	get apiConnector() {
		return this._apiConnector;
	}

	get url() {
		return this._URL;
	}

	/**
	 * Make request
	 * ---
	 * Generic function that uses stored ApiConnector instance and API URL
	 * @param {M_APIConnector_RequestOptions} configuration
	 * @param {ResponseHandlers} responseHandler
	 * @param {E_HttpErrorCode} [errorSimulationCode]
	 * @returns {Promise<Response|HttpResponseError>}
	 */
	sendRequest(configuration, responseHandler, errorSimulationCode) {
		return this.apiConnector.sendRequest(configuration, responseHandler, errorSimulationCode);
	}

	uploadFile(url, file, onSuccess, onError, onProgress, additionalConfiguration) {
		return BasicServices.uploadFile(this.apiConnector, url, file, onSuccess, onError, onProgress, additionalConfiguration);
	}

	/**
	 * Construct file upload request
	 * ---
	 * Returns XHR upload request ready to be sent
	 * @param {APIConnector} ApiConnector
	 * @param {String} url API method URL
	 * @param {Blob} file
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {XHRUploader} XHRUploader instance
	 */
	static constructFileUploadRequest(ApiConnector, url, file, additionalConfiguration) {
		return ApiConnector.sendXHRRequest({
			url,
			file,
			...additionalConfiguration,
		});
	}

	/**
	 * Construct file upload request
	 * ---
	 * Returns XHR upload request ready to be sent
	 * @param {APIConnector} ApiConnector
	 * @param {String} url API method URL
	 * @param {Blob} file File to upload
	 * @param {function(data: Response)} onSuccess On success callback
	 * @param {function(error: HttpResponseError)} onError On error callback
	 * @param {function(states: XHRUploaderStates)} onProgress On progress callback
	 * @param {Object} additionalConfiguration Additional API request configuration
	 * @returns {XHRUploader} XHRUploader instance
	 */
	static uploadFile(ApiConnector, url, file, onSuccess = () => null, onError = () => null, onProgress = () => null, additionalConfiguration) {
		let xhrRequest = this.constructFileUploadRequest(ApiConnector, url, file, additionalConfiguration);

		if(onSuccess || onError || onProgress) {
			//Register listeners
			xhrRequest.on(onSuccess, onError, onProgress);
		}

		//Send request
		xhrRequest.send();
		return xhrRequest;
	}
}
