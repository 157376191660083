import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import ThemeHeader from "../../../shared/components/Theme/ThemeHeader";
import Interpret from "../../../shared/components/Localization/Interpret";
import PinInput from "../../../shared/components/Input/PinInput";
import ButtonsConstructor from "../../../shared/components/Input/Button/ButtonsConstructor";
import { AFE_ROUTE } from "../../models/constants/AfeRoutes";
import MessageModal from "../../../shared/components/Modal/Instances/MessageModal";
import Fa from "../../../shared/components/Fa";
import { applicationThunk } from "../../../shared/store/store";

class AfeOrderWizardPartnerCheck extends Component {
	constructor() {
		super();

		this.state = {
			number: '',
		}
	}

	render({className, style, order}, {number}) {
		return (
			<section className={combineClasses("order-wizard-partner-check", className)} style={style}>
				<ThemeHeader motiv={false} homeButton={true}/>

				<h1><Interpret id={"scanCustomerCard.title"}/></h1>
				<p><Interpret id={"scanCustomerCard.message"}/></p>

				<div className={"pin-input-wrapper"}>
					<div className={"scan-card-graphics"}>
						<Fa icon={"address-card"}/>

						<div className={"border top-left"}/>
						<div className={"border top-right"}/>
						<div className={"border top-left-down"}/>
						<div className={"border top-right-down"}/>

						<div className={"border bottom-left"}/>
						<div className={"border bottom-right"}/>
						<div className={"border bottom-left-up"}/>
						<div className={"border bottom-right-up"}/>
					</div>

					<PinInput
						value={number}
						onModify={number => this.setState({number})}
						maxLength={10}
						onSubmit={() => this._verify()}
					/>

					<div className={"scan-card-graphics dummy"}>
						<Fa icon={"address-card"}/>
					</div>
				</div>

				<nav className={"navigation-buttons"}>
					<ButtonsConstructor buttons={[
						{
							text: "back",
							href: AFE_ROUTE.ORDER_WIZARD_DETAIL(order.orderNumber),
						},
						{
							text: "continue",
							action: () => this._verify(),
						}
					]}/>
				</nav>
			</section>
		);
	}

	_verify() {
		const {order, onConfirmed} = this.props;
		const {number} = this.state;

		const contact = order.contacts.find(contact => contact.cardID == number);
		if(contact) {
			onConfirmed(contact);
		}
		else {
			applicationThunk.setIdleTimeout();

			window.modal.open(
				<MessageModal
					title={"partnerCardNumber.invalid.title"}
					message={"partnerCardNumber.invalid.message"}
				/>
			);
		}
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			order: PropTypes.object.isRequired,

			onConfirmed: PropTypes.func.isRequired,
		}
	}

	static get stateTypes() {
		return {
			number: PropTypes.string,
		}
	}
}

export default AfeOrderWizardPartnerCheck;
