import { Component } from "preact";
import Router from "preact-router";
import { AFE_ROUTE } from "../../models/constants/AfeRoutes";
import Maintenance from "../../../shared/routes/Maintenance/Maintenance";
import { APP_CONSTANTS } from "../../../shared/models/constants/AppConstants";
import AfeHeader from "./AfeHeader";
import EnterOrderNumberPage from "../../routes/EnterOrderNumberPage/afeEnterOrderNumberPage";
import AfeOrderWizard from "../../routes/OrderWizard/AfeOrderWizard";

class AfeRouterSwitch extends Component {
	render() {
		return (
			<Router
				onChange={(e) => {
					window.dispatchEvent(new CustomEvent(APP_CONSTANTS.ROUTE_UPDATE_EVENT_NAME, {detail: e}));
				}}
			>
				<Maintenance path={AFE_ROUTE.MAINTENANCE} header={<AfeHeader className={"mono"} homeButton={true} motiv={false}/>}/>
				<AfeOrderWizard path={AFE_ROUTE.ORDER_WIZARD()}/>

				<EnterOrderNumberPage default/>
			</Router>
		);
	}
}

export default AfeRouterSwitch;
