/**
 * StoreActions Button model
 * ---
 * @type {{
 *  icon: M_Icons,
 *  action: (function(): null),
 *  className: String,
 *  styles: Object,
 *  text: String|Element,
 *  disabled: Boolean,
 *  preset: String,
 *  linkProps: Object,
 *  activeClass: String,
 *  href: String,
 *  routeWhitelist: Array<String>,
 *  routeBlacklist: Array<String>,
 * }}
 */
export const M_ActionButton = {
	className: null,
	style: null,
	icon: null,
	text: null,
	action: () => null,
	disabled: false,
	preset: null,
	applyRestrictions: true,
	//NavLink props
	linkProps: null,
	activeClass: "active",
	href: null,
	routeWhitelist: null,
	routeBlacklist: null,
};

export const M_ButtonPresets = {

};

/**
 * Display text model
 * ---
 * @type {{
 *  id: Number,
 *  text: String,
 *  languageCode: String
 * }}
 */
export const M_DisplayText = {
	id: null,
	languageCode: null,
	text: null
};
