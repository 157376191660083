import { Component } from 'preact';
import PropTypes from "prop-types";
import withConfiguration from "../../../shared/components/hoc/withConfiguration";
import Loading from "../../../shared/components/Loading";
import OutOfOrderPage from "../../../AF/routes/Location/OutOfOrderPage";
import { afeConfigurationThunk } from "../../../shared/store/store";

class AfeConfigurationLoader extends Component {
	componentDidMount() {
		afeConfigurationThunk.getConfiguration();
	}

	render({children, configurationStates}) {
		let {fetching, error} = configurationStates;

		if(fetching) return <Loading title={"initializing"} className={"full-page big"}/>;

		if(error) return <OutOfOrderPage style={{height: "100%"}}/>;

		return children;
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			configurationStates: PropTypes.object,
		}
	}
}

export default withConfiguration(AfeConfigurationLoader);
