import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import Fa from "../../Fa";
import Interpret from "../../Localization/Interpret";
import ButtonsConstructor from "../../Input/Button/ButtonsConstructor";
import { ComponentUtils } from "../../../utils/ComponentUtils";
import withModalContext from "../hoc/withModalContext";
import { M_Icons } from "../../../models/Models_Icons";

class MessageModal extends React.Component {
	render({className, style, icon, iconProps, title, titleTranslated, message, messageTranslated, actionButtons, modalContext, titleProps, messageProps, closeButtonProps}) {
		const defaultActionButtons = [
			{
				text: "close",
				className: "close",
				action: () => modalContext.close(),
				...closeButtonProps,
			}
		]
		const __resolveAsString = (s, translated, translationProps) => translated ? s : <Interpret id={s} {...translationProps}/>;
		const __resolveMessageAsString = s => (
			<p>
				{__resolveAsString(s, messageTranslated, messageProps)}
			</p>
		);
		const __resolveTitleAsString = s => (
			<h1>
				{
					icon &&
					<Fa icon={icon} {...iconProps}/>
				}
				{
					s &&
					__resolveAsString(s, titleTranslated, titleProps)
				}
			</h1>
		);

		return (
			<section className={combineClasses("message-modal", className)} style={style}>
				{
					title &&
					ComponentUtils.resolvePolymorphProp(
						title,
						{
							string: __resolveTitleAsString,
							number: __resolveTitleAsString,
							function: f => f(this.props),
						},
						() => title,
					)
				}

				{
					message &&
					ComponentUtils.resolvePolymorphProp(
						message,
						{
							string: __resolveMessageAsString,
							number: __resolveMessageAsString,
							function: f => f(this.props),
						},
						() => message,
					)
				}

				<nav className={"action-buttons"}>
					<ButtonsConstructor
						buttons={ComponentUtils.resolvePolymorphProp(
							actionButtons,
							{
								component: c => [c],
								function: f => f(defaultActionButtons),
								array: arr => [...defaultActionButtons, ...arr],
								boolean: b => b ? defaultActionButtons : [],
							},
							() => defaultActionButtons
						)}
					/>
				</nav>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			icon: PropTypes.oneOf(Object.keys(M_Icons)),
			iconProps: PropTypes.object,
			title: PropTypes.any,
			titleTranslated: PropTypes.bool,
			titleProps: PropTypes.object,
			message: PropTypes.any,
			messageTranslated: PropTypes.bool,
			messageProps: PropTypes.object,
			actionButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.element, PropTypes.func, PropTypes.array]),
			closeButtonProps: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			titleTranslated: false,
			messageTranslated: false,
			actionButtons: [],
			titleProps: {},
			messageProps: {},
			iconProps: {},
		}
	}
}

export default withModalContext(MessageModal);
