import { Component, createContext } from 'preact';
import PropTypes from "prop-types";
import { combineClasses, IS } from "@green24/js-utils";
import { EventListeners } from "../../../utils/EventListeners";
import root from "window-or-global";
import { APP_CONSTANTS } from "../../../models/constants/AppConstants";

export const IdleTimeoutCounterContext = createContext();

class IdleTimeoutCounter extends Component {
	constructor() {
		super();

		this._eventListeners = new EventListeners();
	}

	componentDidMount() {
		this._mounted = true;

		this._eventListeners.add(root, APP_CONSTANTS.IDLE_TICK_EVENT_NAME, e => {
			const {remaining} = e.detail;
			this._mounted && this.setState({remaining});
		});

		this._eventListeners.add(root, APP_CONSTANTS.IDLE_RESET, () => {
			this._mounted && this.setState({remaining: 0});
		});
	}

	componentWillUnmount() {
		this._mounted = false;
		this._eventListeners.clear();
	}

	render({className, style, children}, {remaining}) {
		const secondsRemaining = this._getSecondsRemaining();

		if(children ? false : IS.nan(secondsRemaining) || secondsRemaining <= 0) return null;

		const contextData = {
			secondsRemaining,
			remaining,
		}

		if(children) {
			if(IS.fnc(children)) {
				return children(contextData);
			}

			return (
				<IdleTimeoutCounterContext.Provider value={contextData}>
					{children}
				</IdleTimeoutCounterContext.Provider>
			)
		}

		return (
			<section className={combineClasses("idle-timeout-counter", className)} style={style}>
				<span className={combineClasses(secondsRemaining < 10 && "time-low")}>
					{secondsRemaining}
				</span>
			</section>
		);
	}

	_getSecondsRemaining() {
		const {remaining} = this.state;
		return Math.round(remaining / 1000)
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,
		}
	}
}

export default IdleTimeoutCounter;
