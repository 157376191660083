import { Jiffy } from "@green24/js-utils";

/**
 * Service simulator
 * ---
 * Utility service to simulate the different states of the request
 */
export class ServiceSimulator {
	/**
	 *
	 * @param {{
	 *  resolved: *,
	 *  rejected: *,
	 *  aborted: *,
	 * }} states Resolution states definition
	 * @param {boolean} shouldResolve If after the duration the state should resolve or reject if not cancelled (true = resolve, false = reject)
	 * @param {string|number} duration Service duration
	 * @param {function} onCompleted Callback to call after the simulator has completed its cycle.
	 */
	constructor(states, shouldResolve = true, duration, onCompleted) {
		this.abortShouldResolve = false;
		this.aborted = false;
		this.completed = false;

		this.promise = new Promise((resolve, reject) => {
			let time = Jiffy.formatToMs(duration);
			this._intervalTick = setInterval(() => {
				time-= 10;

				if(this.aborted) {
					clearTimeout(this._abortTimeout);
					clearInterval(this._intervalTick);
					this.completed = true;
					if(this.abortShouldResolve) {
						resolve(states.aborted || states.rejected);
					}
					else {
						reject(states.aborted || states.rejected);
					}
					onCompleted();
				}
				else if(time <= 0) {
					clearInterval(this._intervalTick);
					this.completed = true;

					clearTimeout(this._abortTimeout);
					if(shouldResolve) {
						resolve(states.resolved);
					}
					else {
						reject(states.rejected);
					}
					onCompleted();
				}
			}, 10);
		});
	}

	then(onSuccess, onError) {
		return this.promise.then(onSuccess, onError);
	}

	catch(callback) {
		return this.promise.catch(callback);
	}

	finally(callback) {
		return this.promise.finally(callback);
	}

	abort(shouldResolve = false, delay = 1000) {
		clearTimeout(this._abortTimeout);
		this._abortTimeout = setTimeout(() => {
			this.abortShouldResolve = shouldResolve;
			this.aborted = true;
		}, delay);
	}
}
