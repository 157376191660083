import PropTypes from "prop-types";
import { combineClasses, get } from "@green24/js-utils";
import { Component, Fragment } from "preact";
import ButtonsConstructor from "../../Input/Button/ButtonsConstructor";
import { E_Keyboard_ShiftState, E_Keyboard_SpecialKeys, E_Keyboard_Type } from "../../../models/constants/Enums_Keyboard";
import { M_Keyboard_Layout_Decimal, M_Keyboard_Layout_Email, M_Keyboard_Layout_Numeric, M_Keyboard_Layout_Special } from "../../../models/Models_Keyboard";
import { EventListeners } from "../../../utils/EventListeners";
import root from "window-or-global";

class KeyboardKeys extends Component {
	constructor(props) {
		super(props);

		this.state = {
			shiftState: E_Keyboard_ShiftState.DISABLED,
			view: props.view,
		}

		this._backspaceWaitTimeout = null;
		this._backspaceRepeatInterval = null;

		this._listeners = new EventListeners();
	}

	componentDidMount() {
		this._initializeHardwareKeyboardListener();
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		const {view, type} = this.props;

		if(view != previousProps.view || (type != previousProps.type && this.state.view == previousProps.type)) {
			this.setState({view});
		}
	}

	componentWillUnmount() {
		this._cancelHardwareKeyboardListener();
		this._listeners.clear();
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		let r = [
			this.props.className != nextProps.className,
			this.props.style != nextProps.style,
			this.props.block != nextProps.block,
			this.props.view != nextProps.view,
			this.props.type != nextProps.type,
			get(this.props, "onKey.id", Symbol()) != get(nextProps, "onKey.id", Symbol()),
			this.state.shiftState != nextState.shiftState,
			this.state.view != nextState.view,
		];
		return r.some(v => v);
	}

	render({className, style, block, keyboardKeySharedProps}, {view, shiftState}) {
		return (
			<section
				className={combineClasses("keyboard-wrapper", className)}
				data-view={view}
				data-shift-state={shiftState}
				style={style}
			>
				{
					this._getLayout(view).map((row,i) => {
						return <Fragment key={i}>
							<ButtonsConstructor buttons={this._resolveButtons(row)} sharedProps={keyboardKeySharedProps}/>
						</Fragment>
					})
				}
				{
					[
						E_Keyboard_Type.NUMERIC,
						E_Keyboard_Type.PHONE,
					].includes(view) &&
					<ButtonsConstructor buttons={this._resolveButtons(["BACKSPACE", !block && "HIDE_KEYBOARD"])} sharedProps={keyboardKeySharedProps}/>
				}
			</section>
		);
	}

	_resolveButtons(row) {
		return row.filter(v => v !== false).map(key => {
			let keyData = this._resolveSpecialButton(key);
			return {
				...keyData,
				className: combineClasses(this._getClassNameForKey(key), keyData.className),
				style: {
					gridArea: this._getGridAreaForKey(key),
					...keyData.style,
				}
			}
		});
	}

	_resolveSpecialButton(key) {
		const {onKey, type} = this.props;
		const {view, shiftState} = this.state;
		const isSpecial = view === E_Keyboard_Type.SPECIAL;

		switch (key) {
			case E_Keyboard_SpecialKeys.HIDE_KEYBOARD:
				return {
					icon: "keyboard-down",
					action: () => onKey(E_Keyboard_SpecialKeys.HIDE_KEYBOARD),
				};
			case E_Keyboard_SpecialKeys.SPECIAL:
				return {
					text: isSpecial ? "abc" : "?@#",
					textTranslated: true,
					action: () => this.setState(state => ({
						view: state.view === E_Keyboard_Type.SPECIAL ? type : E_Keyboard_Type.SPECIAL,
					})),
				};
			case E_Keyboard_SpecialKeys.SPACE:
				return {
					text: '',
					textTranslated: true,
					action: () => onKey(E_Keyboard_SpecialKeys.SPACE),
				}
			case E_Keyboard_SpecialKeys.BACKSPACE:
				// noinspection JSUnusedGlobalSymbols
				return {
					icon: "backspace",
					buttonProps: {
						onMouseDown: () => this._startBackspaceLoop(),
						onMouseUp: () => this._stopBackspaceLoop(),
						onTouchStart: () => this._startBackspaceLoop(),
						onTouchEnd: () => this._stopBackspaceLoop(),
					},
				};
			case E_Keyboard_SpecialKeys.SHIFT:
				return {
					icon: shiftState == E_Keyboard_ShiftState.CAPS_LOCK ? "arrow-alt-square-up-solid" : "arrow-alt-square-up-regular",
					action: () => this._changeShiftCase(),
				};
			case E_Keyboard_SpecialKeys.PLACEHOLDER:
				return {
					text: '',
					action: () => null,
				}
			default:
				return {
					text: <span>
						<span key={1} className={"placeholder-dummy"}>{key}</span>
						<span key={2} className={"key-dummy"}>{key}</span>
					</span>,
					action: () => onKey(key),
				}
		}
	}

	_getClassNameForKey(key) {
		const map = {
			".com": "COM",
			".cz": "CZ"
		}

		return "key-" + (map[key] || key);
	}

	_getGridAreaForKey(key) {
		if(parseInt(key) == key) return 'k' + key;

		const map = {
			".com": "COM",
			"@": "AT",
			"!": "EXCLAMATION",
			"#": "HASH",
			"$": "DOLLAR",
			"%": "PERCENT",
			"^": "EXP",
			"&": "AMP",
			"*": "STAR",
			"(": "BRACKET_LEFT",
			")": "BRACKET_RIGHT",
			"{": "CURLY_BRACKET_LEFT",
			"}": "CURLY_BRACKET_RIGHT",
			"+": "PLUS",
			"-": "MINUS",
			"/": "DIVIDE",
			"=": "EQUAL",
			"?": "QUESTION",
			"_": "UNDERSCORE",
			":": "COLON",
			".cz": "CZ",
			",": "COMMA",
			".": "DOT",
		}

		return map[key] || key;
	}

	_getLayout(type) {
		switch (type) {
			case E_Keyboard_Type.PASSWORD:
			case E_Keyboard_Type.EMAIL:
				return M_Keyboard_Layout_Email;
			case E_Keyboard_Type.NUMERIC:
			case E_Keyboard_Type.PHONE:
				return M_Keyboard_Layout_Numeric;
			case E_Keyboard_Type.DECIMAL:
				return M_Keyboard_Layout_Decimal;
			case E_Keyboard_Type.SPECIAL:
				return M_Keyboard_Layout_Special;
		}
	}

	_startBackspaceLoop() {
		const {onKey} = this.props;

		onKey(E_Keyboard_SpecialKeys.BACKSPACE);

		this._stopBackspaceLoop();
		this._backspaceWaitTimeout = setTimeout(() => {
			clearInterval(this._backspaceRepeatInterval);
			this._backspaceRepeatInterval = setInterval(() => {
				onKey(E_Keyboard_SpecialKeys.BACKSPACE);
			}, 100);
		}, 500);
	}

	_stopBackspaceLoop() {
		clearTimeout(this._backspaceWaitTimeout);
		clearInterval(this._backspaceRepeatInterval);
	}

	_changeShiftCase() {
		this.setState(state => {
			switch(state.shiftState) {
				case E_Keyboard_ShiftState.DISABLED:
					return {shiftState: E_Keyboard_ShiftState.SINGLE};
				case E_Keyboard_ShiftState.SINGLE:
					return {shiftState: E_Keyboard_ShiftState.CAPS_LOCK};
				case E_Keyboard_ShiftState.CAPS_LOCK:
					return {shiftState: E_Keyboard_ShiftState.DISABLED};
			}
		})
	}

	_initializeHardwareKeyboardListener() {
		const {onKey} = this.props;

		this._keyboardKeyUpInputListener = this._listeners.add(root, "keyup", (e) => {
			switch (e.key) {
				case "Control":
				case "AudioVolumeUp":
				case "AudioVolumeDown":
					return;
				case "Backspace":
					return this._stopBackspaceLoop();
				case "Shift":
					return this.setState({shiftState: E_Keyboard_ShiftState.DISABLED});
				case "Escape":
				case "Enter":
					return onKey(E_Keyboard_SpecialKeys.HIDE_KEYBOARD);
				default:
					return onKey(e.key);
			}
		});

		this._keyboardKeyDownInputListener = this._listeners.add(root, "keydown", (e) => {
			switch (e.key) {
				case "Backspace":
					return onKey(E_Keyboard_SpecialKeys.BACKSPACE);
				case "Shift":
					return this.setState({shiftState: E_Keyboard_ShiftState.CAPS_LOCK});
			}
		});
	}

	_cancelHardwareKeyboardListener() {
		this._keyboardKeyUpInputListener && this._keyboardKeyUpInputListener();
		this._keyboardKeyDownInputListener && this._keyboardKeyDownInputListener();
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			block: PropTypes.bool,
			type: PropTypes.oneOf(Object.values(E_Keyboard_Type)),
			view: PropTypes.oneOf(Object.values(E_Keyboard_Type)),
			onKey: PropTypes.func.isRequired,

			keyboardKeySharedProps: PropTypes.object,
		}
	}
}

export default KeyboardKeys;
