import React from "react";
import { ModalContext } from "../Modal";

/**
 * A public higher-order component to access the imperative API
 */
export const withModalContext = Component => {
	class HOC extends React.Component {
		render() {
			return (
				<ModalContext.Consumer>
					{(modalContext) => (
						<Component
							modalContext={modalContext}
							{...this.props}
						/>
					)}
				</ModalContext.Consumer>
			);
		}

		static get displayName() {
			return "withModalContext(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}
	}

	return HOC;
};

export default withModalContext;
