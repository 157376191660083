import kvElektroLogo from "../media/icons/kv-elektro-logo.svg";
import motiv from "../media/icons/motiv.svg";
import keyboardDown from "../media/icons/keyboard-down.svg";
import backspace from "../media/icons/fontawesome/solid/backspace.svg";
import cog from "../media/icons/fontawesome/solid/cog.svg";
import construction from "../media/icons/fontawesome/solid/construction.svg";
import telescope from "../media/icons/fontawesome/solid/telescope.svg";
import stopwatch from "../media/icons/fontawesome/solid/stopwatch.svg";
import server from "../media/icons/fontawesome/solid/server.svg";
import eraser from "../media/icons/fontawesome/solid/eraser.svg";
import check from "../media/icons/fontawesome/solid/check.svg";
import save from "../media/icons/fontawesome/solid/save.svg";
import home from "../media/icons/fontawesome/solid/home.svg";
import timesCircle from "../media/icons/fontawesome/solid/times-circle.svg";
import arrowAltSquareUpRegular from "../media/icons/fontawesome/regular/arrow-alt-square-up.svg";
import arrowAltSquareUpSolid from "../media/icons/fontawesome/solid/arrow-alt-square-up.svg";
import exclamationTriangle from "../media/icons/fontawesome/solid/exclamation-triangle.svg";
import lockOpen from "../media/icons/fontawesome/solid/lock-open.svg";
import userLock from "../media/icons/fontawesome/solid/user-lock.svg";
import stopCircle from "../media/icons/fontawesome/solid/stop-circle.svg";
import syncAlt from "../media/icons/fontawesome/solid/sync-alt.svg";
import cubeLight from "../media/icons/fontawesome/light/cube.svg";
import creditCard from "../media/icons/fontawesome/solid/credit-card.svg";
import envelopeSquare from "../media/icons/fontawesome/solid/envelope-square.svg";
import phoneSquareAlt from "../media/icons/fontawesome/solid/phone-square-alt.svg";
import creditCardFront from "../media/icons/fontawesome/solid/credit-card-front.svg";
import signalStream from "../media/icons/fontawesome/solid/signal-stream.svg";
import chevronLeft from "../media/icons/fontawesome/solid/chevron-left.svg";
import chevronRight from "../media/icons/fontawesome/solid/chevron-right.svg";
import times from "../media/icons/fontawesome/solid/times.svg";
import plus from "../media/icons/fontawesome/solid/plus.svg";
import minus from "../media/icons/fontawesome/solid/minus.svg";
import addressCard from "../media/icons/fontawesome/solid/address-card.svg";

export const M_Icons = {
	"kv-elektro-logo": kvElektroLogo,
	motiv,
	"keyboard-down": keyboardDown,
	backspace,
	cog,
	construction,
	telescope,
	stopwatch,
	server,
	eraser,
	check,
	save,
	home,
	"times-circle": timesCircle,
	"arrow-alt-square-up-regular": arrowAltSquareUpRegular,
	"arrow-alt-square-up-solid": arrowAltSquareUpSolid,
	"exclamation-triangle": exclamationTriangle,
	"lock-open": lockOpen,
	"user-lock": userLock,
	"stop-circle": stopCircle,
	"sync-alt": syncAlt,
	"cube-light": cubeLight,
	"credit-card": creditCard,
	"envelope-square": envelopeSquare,
	"phone-square-alt": phoneSquareAlt,
	"credit-card-front": creditCardFront,
	"signal-stream": signalStream,
	"chevron-left": chevronLeft,
	"chevron-right": chevronRight,
	times,
	plus,
	minus,
	"address-card": addressCard,
};
