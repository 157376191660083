import React from "react";
import { TranslationContext } from "./TranslationProvider";

/**
 * A public higher-order component to access the imperative API
 */
export const withTranslationContext = Component => {
	class HOC extends React.Component {
		render() {
			return (
				<TranslationContext.Consumer>
					{(translationContext) => {
						return (
							<Component
								translationContext={translationContext}
								translate={translationContext.translate}
								{...this.props}
							/>
						)
					}}
				</TranslationContext.Consumer>
			);
		}

		static get displayName() {
			return "withTranslationContext(" + (Component.displayName || Component.name) + ")";
		}

		static get wrappedComponent() {
			return Component;
		}

		static get propTypes() {
			return {
				...Component.propTypes,
			}
		}
	}

	return HOC;
};
