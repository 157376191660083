import { StoreThunk } from "../../../shared/store/thunk/StoreThunk";
import { afnOrderServices } from "../../services/afn-order-services";
import { orderActions } from "../../../shared/store/actions/actions";

export class Thunk_AFN_Order extends StoreThunk {
	//TODO: Remove
	set(orderData) {
		this.dispatch(orderActions.getFetchFulfilled(orderData));
	}

	getOrder(orderID, orderType, simulate = false) {
		this.dispatch(orderActions.getFetchPending());

		return new Promise((resolve, reject) => {
			afnOrderServices.getOrder(orderID, orderType, simulate).then(order => {
				this.dispatch(orderActions.getFetchFulfilled(order));

				resolve(order);
			}, (res) => {
				this.dispatch(orderActions.getFetchRejected(res));

				reject(res);
			});
		});
	}

	clear() {
		this.dispatch(orderActions.getItemClear());
	}
}
