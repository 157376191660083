import { uID } from "@green24/js-utils";
import { APP_CONSTANTS } from "../models/constants/AppConstants";

export class AppUtils {
	static get isDev() {
		if(typeof window !== "undefined") {
			return !window.isProduction;
		}
		return false;
	}

	static get isProduction() {
		if(typeof window !== "undefined") {
			return window.isProduction;
		}
		return true;
	}

	static get hasWindow() {
		return typeof window !== "undefined";
	}

	static get locationSearch() {
		if(this.hasWindow) {
			return window.location.search;
		}
		return '';
	}

	static withID(target, IDDescription) {
		Object.defineProperty(target, "id", {value: uID(IDDescription)});
		return target;
	}

	static get simulatePaymentProcess() {
		if(this.isDev) {
			return localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_SIMULATE_PAYMENT_PROCESS);
		}
		return false
	}

	static get simulateOrderValidation() {
		if(this.isDev) {
			return localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_SIMULATE_ORDER_VALIDATION);
		}
		return false;
	}

	static get keepTokenOnReload() {
		if(this.isDev) {
			return !!localStorage.getItem(APP_CONSTANTS.LOCAL_STORAGE_KEY_KEEP_TOKEN);
		}
		return false;
	}

	static get noRedirectOnIdle() {
		if(this.isDev) {
			return !!localStorage.getItem("noRedirectOnIdle");
		}
		return false;
	}

	static get logIdleTimeoutChange() {
		if(this.isDev) {
			return !!localStorage.getItem("logIdleTimeoutChange");
		}
		return false;
	}
}
