import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import PinInput from "../../../shared/components/Input/PinInput";
import { connect } from "react-redux";
import { orderSelector } from "../../../shared/store/selectors/selectors";
import Loading from "../../../shared/components/Loading";
import AfeHeader from "../../components/other/AfeHeader";
import ButtonsConstructor from "../../../shared/components/Input/Button/ButtonsConstructor";
import Interpret from "../../../shared/components/Localization/Interpret";
import { afeOrderThunk } from "../../../shared/store/store";
import MessageModal from "../../../shared/components/Modal/Instances/MessageModal";
import { route } from "preact-router";
import { AFE_ROUTE } from "../../models/constants/AfeRoutes";
import MaintenanceTrigger from "../../../shared/components/MaintenanceTrigger";

class EnterOrderNumberPage extends Component {
	constructor() {
		super();

		this.state = {
			orderNumber: '',
		}
	}

	render({className, style, fetching, fetched, failed, error, order}, {orderNumber}) {
		return (
			<section className={combineClasses("enter-order-number-page", className)} style={style}>
				{
					fetching &&
					<Loading title={"validatingOrderNumber"} className={"with-background big"}/>
				}

				<AfeHeader/>

				<h1><Interpret id={"enterOrderNumberTitle"}/></h1>

				<PinInput
					value={orderNumber}
					onModify={v => this.setState({orderNumber: v})}
					onSubmit={() => this._getOrder()}
				/>

				<nav className={"navigation-buttons"}>
					<ButtonsConstructor buttons={[
						{
							text: "continue",
							action: () => this._getOrder()
						}
					]}/>
				</nav>

				<MaintenanceTrigger/>
			</section>
		);
	}

	_getOrder() {
		const {orderNumber} = this.state;

		afeOrderThunk.getOrder(orderNumber).then(order => {
			route(AFE_ROUTE.ORDER_WIZARD_DETAIL(order.orderNumber));
		}, () => {
			window.modal.open(
				<MessageModal
					title={"error_orderFetch"}
					closeButtonProps={{
						action: () => window.modal.close(true),
					}}
				/>,
			)
		});
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			//Store
			order: PropTypes.object,
			fetching: PropTypes.bool,
			fetched: PropTypes.bool,
			failed: PropTypes.bool,
			error: PropTypes.object,
		}
	}
}

const mapStateToProps = state => {
	const {error, failed, fetching, fetched} = orderSelector.selectStates(state);

	return {
		order: orderSelector.selectData(state),
		error,
		failed,
		fetching,
		fetched,
	};
};

export default connect(mapStateToProps)(EnterOrderNumberPage);
