import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses, Jiffy } from "@green24/js-utils";
import Interpret from "../../../shared/components/Localization/Interpret";
import ButtonsConstructor from "../../../shared/components/Input/Button/ButtonsConstructor";
import { AFE_ROUTE } from "../../models/constants/AfeRoutes";
import ThemeHeader from "../../../shared/components/Theme/ThemeHeader";
import { applicationThunk } from "../../../shared/store/store";

class AfeOrderWizardComplete extends Component {
	componentDidMount() {
		applicationThunk.setIdleTimeout(Jiffy.formatToMs("15s"));
	}

	render({className, style, boxes}, {}) {
		return (
			<section className={combineClasses("order-wizard-complete", className)} style={style}>
				<ThemeHeader/>

				<h1><Interpret id={"done"}/></h1>
				<h2><Interpret id={"retrieveItemsFromBoxes"}/></h2>

				<div className={"boxes"}>
					{
						boxes.map(box => box.physicalReference).join(', ')
					}
				</div>

				<nav className={"navigation-buttons"}>
					<ButtonsConstructor buttons={[
						{
							text: "end",
							href: AFE_ROUTE.BASE,
						},
					]}/>
				</nav>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			boxes: PropTypes.array.isRequired,
		}
	}
}

export default AfeOrderWizardComplete;
