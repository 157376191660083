import { M_LanguageISOCodes } from "../models/L10N/Models_Language";

/**
 * @deprecated - Use RegionalLanguage class for a full toolset
 */
export class LanguageResolver {
	/**
	 * Resolve language code
	 * ---
	 * Returns language key appropriate to the provided language ISO code
	 * @param {String} code
	 * @returns {E_Language} Language key
	 * @see E_Language
	 */
	static resolveLanguageCode(code) {
		return Object.keys(M_LanguageISOCodes).find(languageKey => M_LanguageISOCodes[languageKey].includes(code.split('-')[0]));
	}

	/**
	 * Resolve language
	 * ---
	 * Tries to return language appropriate to the provided **identifier**. This can be something like language code, language key, etc.
	 * @param {String} identifier
	 * @returns {String}
	 */
	static resolveLanguage(identifier) {
		return (
			LanguageResolver.resolveLanguageCode(identifier)
			||
			Object.keys(M_LanguageISOCodes).find(
				languageKey => new RegExp(`(${languageKey})`, 'i').test(identifier)
			)
		);
	}

	/**
	 * Get ISO codes
	 * ---
	 * Returns ISO codes for provided language key
	 * @param {E_Language} languageKey
	 * @returns {String[]} Language ISO codes
	 * @see E_Language
	 */
	static getISOCodes(languageKey) {
		return M_LanguageISOCodes[languageKey];
	}
}
