import { Component as PComponent } from "preact";
import PropTypes from "prop-types";
import { E_OrderWizard_PaymentMethod } from "../../../models/constants/Enums_OrderWizard";
import { orderPaymentServices } from "../../../services/order-payment-services";
import { withTranslationContext } from "../../Localization/withTranslationContext";
import root from "window-or-global";
import { E_Payment_ResponseType } from "../../../../AFN/models/constants/Enums_Payment";
import MessageModal from "../../Modal/Instances/MessageModal";
import Interpret from "../../Localization/Interpret";
import withThemeContext from "../../Theme/withThemeContext";
import { applicationThunk } from "../../../store/store";
import { Jiffy } from "@green24/js-utils";
import { APP_ROUTE } from "../../../models/constants/AppConstants";
import { route } from "preact-router";
import { ValueUpdateListeners } from "../../../utils/ValueUpdateListeners";

/**
 * A public higher-order component to access the imperative API
 */
export const withPayment = (Component) => {
	class HOC extends PComponent {
		constructor(props) {
			super();

			this.state = {
				paymentInProgress: false,
				paymentData: null,
				paymentMethod: props.paymentMethod,
				cancelled: false,
				cancelling: false,
				somethingWasReturned: false,
			};

			this._redirectWhenCompleted = false;
			this._paymentCancelled = false;
			this._valueUpdateListeners = new ValueUpdateListeners(this);
		}

		componentDidMount() {
			const {startPaymentOnMount, cost, onPaymentSuccess, onPaymentError} = this.props;

			//Prolong the redirect time if there was a user input during the cash payment
			this._valueUpdateListeners.add("state.paymentData", (data) => {
				if(this.state.paymentMethod == E_OrderWizard_PaymentMethod.CASH) {
					switch (data.type) {
						case E_Payment_ResponseType.AMOUNT_INSERTED:
						case E_Payment_ResponseType.AMOUNT_RETURNED:
						case E_Payment_ResponseType.ERROR:
							applicationThunk.setIdleTimeout(Jiffy.formatToMs("2m"));
					}
				}
			});

			if(startPaymentOnMount) {
				this._initializePayment(cost).then(
					(res) => onPaymentSuccess(res),
					(res) => onPaymentError(res)
				);
			}
		}

		componentDidUpdate(previousProps, previousState, snapshot) {
			this._valueUpdateListeners.componentDidUpdate(previousProps, previousState);
		}

		render(props, {paid, paymentInProgress, paymentMethod, paymentData, cancelling, cancelled, somethingWasReturned}) {
			return (
				<Component
					{...props}
					paymentInProgress={paymentInProgress}
					paymentData={paymentData}
					startPayment={(cost) => this._initializePayment(cost)}
					cancelPayment={(showConfirm = true) => showConfirm ? this._openCancelPaymentConfirm() : this._cancelPayment()}
					paymentMethod={paymentMethod}
					cancelled={cancelled}
					cancelling={cancelling}
					somethingWasReturned={somethingWasReturned}
				/>
			);
		}

		_initializePayment(amount) {
			const {paymentMethod} = this.state;

			applicationThunk.setIdleTimeout(Jiffy.formatToMs("2m"));
			applicationThunk.setIdleEffect(() => {
				this._cancelPayment().then(() => {
					this._redirectWhenCompleted = true;
				});
			});

			return new Promise((resolve, reject) => {
				const __onPromise = promise => {
					const {onPaymentSuccess, onPaymentError} = this.props;

					promise.then(res => {
						onPaymentSuccess(res);
						resolve(res);
					}, res => {
						onPaymentError(res);
						reject(res);
					});
				};

				switch (paymentMethod) {
					case E_OrderWizard_PaymentMethod.CARD:
						return __onPromise(this._initializeCardPayment(amount));
					case E_OrderWizard_PaymentMethod.CASH:
						return __onPromise(this._initializeCashPayment(amount));
					case E_OrderWizard_PaymentMethod.COMBINED:
						return __onPromise(this._initializeCombinedPayment(...amount));
				}
			})
		}

		_initializeCashPayment(amount, disablePaymentInProgressOnSuccess = true) {
			const {orderID, translationContext} = this.props;
			if(!orderID || !amount) return;

			return this._wrapInPaymentPromise(
				() => orderPaymentServices.initCashTerminal(orderID, amount, translationContext.activeLanguage.codes[0]),
				orderPaymentServices.getCashTerminalSocketUrl(orderID),
				disablePaymentInProgressOnSuccess
			);
		}

		_wrapInPaymentPromise(service, socketDestination, disablePaymentInProgressOnSuccess = true) {
			this.setState({paymentInProgress: true});

			return new Promise((resolve, reject) => {
				new Promise((innerResolve, innerReject) => {
					let removeListener = root.greenSocket.onDestination(socketDestination, ({data}) => {
						this.setState({paymentData: data});

						const __cancelSubscription = () => {
							removeListener();
							root.greenSocket.unsubscribeTopic(socketDestination);
						}

						switch (data.type) {
							case E_Payment_ResponseType.FATAL_ERROR:
								__cancelSubscription();

								if(this._paymentCancelled) {
									innerReject([undefined, undefined]);
								}
								else {
									this._handleFatalError(data);

									innerReject([undefined, data]);
								}
								break;
							case E_Payment_ResponseType.ERROR:
								this._handleError(data);
								break;
							case E_Payment_ResponseType.CANCELLED:
								this._setCancelledPayment();
								break;
							case E_Payment_ResponseType.COMPLETED:
								__cancelSubscription();

								if(this._paymentCancelled) {
									innerReject([undefined, undefined]);

									if(this._redirectWhenCompleted) {
										route(APP_ROUTE.BASE);
									}
								}
								else {
									innerResolve(data);
								}
								break;
							case E_Payment_ResponseType.AMOUNT_RETURNED:
								if(data.amount >= 100) {
									this.setState({somethingWasReturned: true});
								}
								break;
						}
					});

					root.greenSocket.subscribeTopic(socketDestination);

					service().catch((res) => {
						//TODO: Error handling?
						innerReject([res, undefined]);
					});
				}).then(
					res => {
						disablePaymentInProgressOnSuccess && this.setState({paymentInProgress: false});
						resolve(res);
					},
					res => {
						this.setState({paymentInProgress: false});
						reject(res);
					},
				);
			});
		}

		_initializeCardPayment(amount) {
			const {orderID, translationContext} = this.props;
			if(!orderID || !amount) return;

			return this._wrapInPaymentPromise(
				() => orderPaymentServices.initCardTerminal(orderID, amount, translationContext.activeLanguage.codes[0]),
				orderPaymentServices.getCardTerminalSocketUrl(orderID)
			);
		}

		_initializeCombinedPayment(cashAmount, cardAmount) {
			return new Promise((resolve, reject) => {
				this.setState({paymentMethod: E_OrderWizard_PaymentMethod.CASH});

				this._initializeCashPayment(cashAmount, false).then(cashRes => {
					this.setState({paymentMethod: E_OrderWizard_PaymentMethod.CARD});

					this._initializeCardPayment(cardAmount).then(
						cardRes => resolve([cashRes, cardRes]),
						res => reject([undefined, res])
					);
				}, res => reject([res, undefined]));
			});
		}

		_openCancelPaymentConfirm() {
			const {paymentMethod} = this.state;

			root.modal.open(
				<MessageModal
					title={"cancelPayment"}
					message={paymentMethod == E_OrderWizard_PaymentMethod.CASH ? "cancelCashPaymentConfirmMessage" : "cancelPaymentConfirmMessage"}
					actionButtons={[
						{
							text: "cancelPayment",
							action: () => {
								root.modal.close();
								this._cancelPayment();
							},
						}
					]}
				/>
			);
		}

		_cancelPayment() {
			const {orderID} = this.props;
			const {paymentMethod} = this.state;

			if(this._paymentCancelled) return Promise.reject();

			this.setState({cancelling: true});
			applicationThunk.setIdleTimeout(Jiffy.formatToMs("2m"));

			return new Promise((resolve, reject) => {
				orderPaymentServices.cancelPayment(paymentMethod, orderID).then(() => {
					resolve();
				}, () => {
					//TBD: Error handling
					reject();
				}).finally(() => {
					this.setState({cancelling: false});
				});
			})
		}

		_setCancelledPayment() {
			this._paymentCancelled = true;
			this.setState({cancelled: true});
		}

		_handleError(data) {//TODO
			root.modal.open(
				<MessageModal
					title={"error_payment_unexpectedTitle"}
					message={data.errorMessage || "error_payment_unexpectedMessage"}
					messageTranslated={!!data.errorMessage}
				/>
			)
		}

		_handleFatalError(data) {//TODO
			const {boxID, themeContext, paymentSelectRoute} = this.props;
			const isPayingWithCash = this.state.paymentMethod === E_OrderWizard_PaymentMethod.CASH;

			root.modal.open(
				<MessageModal
					title={
						<div>
							<h1>
								<Interpret id={"error_payment_unexpectedTitle"}/>
							</h1>
							{
								boxID &&
								<h2><Interpret id={"boxIDText"} params={{boxID}}/></h2>
							}
						</div>
					}
					message={(
						<div style={{fontSize: "2em"}}>
							<Interpret id={isPayingWithCash ? "error_payment_unexpectedFatalMessage_blocking" : "error_payment_unexpectedFatalMessage"}/>
							<br/>
							{data.errorMessage}
						</div>
					)}
					closeButtonProps={{
						text: isPayingWithCash ? "back" : "close",
						href: paymentSelectRoute || themeContext.getPaymentSelectRoute(),
						className: isPayingWithCash && "soft-hidden",
					}}
				/>
			)
		}

		static get displayName() {
			return "withPayment(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}

		static get propTypes() {
			return {
				orderID: PropTypes.string,
				paymentMethod: PropTypes.oneOf(Object.values(E_OrderWizard_PaymentMethod)),

				//Override to themeContext.getPaymentSelectRoute()
				paymentSelectRoute: PropTypes.string,

				//Optional, if provided, the payment can be initialized instantly when the component mounts
				cost: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),

				onPaymentSuccess: PropTypes.func,
				onPaymentError: PropTypes.func,

				//withTranslationContext
				translationContext: PropTypes.object,

				startPaymentOnMount: PropTypes.bool,

				boxID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			}
		}

		static get defaultProps() {
			return {
				startPaymentOnMount: false,
				onPaymentSuccess: () => null,
				onPaymentError: () => null,
			}
		}
	}

	return withTranslationContext(withThemeContext(HOC));
};

export default withPayment;
