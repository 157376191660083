import { Component } from 'preact';
import PropTypes from "prop-types";
import MessageModal from "../../components/Modal/Instances/MessageModal";
import { hasamMaintenanceServices } from "../../services/maintenance-services";
import { ErrorUtils } from "../../utils/ErrorUtils";

class MaintenanceActionFillingProcessModal extends Component {
	constructor() {
		super();

		this.state = {
			stopping: false,
		}
	}

	render({...props}, {stopping}) {
		return (
			<MessageModal
				{...props}
				title={"fillingProcessActive"}
				message={"fillingProcessActiveMessage"}
				actionButtons={() => {
					return [{
						text: stopping ? "stopping" : "stop",
						disabled: stopping,
						action: () => this._stopFillingProcess(),
					}]
				}}
			/>
		);
	}

	_stopFillingProcess() {
		this.setState({stopping: true});

		hasamMaintenanceServices.stopFillingOrPaymentProcess().then(() => {
			const {modalContext} = this.props;

			modalContext.close();
		}, (res) => {
			const {title, titleTranslated, icon} = ErrorUtils.getErrorFromResponse(res);

			window.modal.open(
				<MessageModal
					icon={icon}
					title={title}
					titleTranslated={titleTranslated}
				/>
			);

			this.setState({stopping: false});
		});
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			//Inherited from Modal component
			modalContext: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			stopping: PropTypes.bool,
		}
	}
}

export default MaintenanceActionFillingProcessModal;
