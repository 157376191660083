import React, { Fragment } from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { ArrayUtils, resolvePolymorphVar, StoreHelpers } from "@green24/js-utils";
import Modal from "../Modal/Modal";
import { M_Modal_OptionsDefaults } from "../../models/Models_Modal";
import root from "window-or-global";

class GlobalModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			content: [],
			toDestroy: [],
		};

		root.modal = this;
	}

	render({}, {content, toDestroy}) {
		if(!content || content.length == 0) return null;

		return content.map((modalData) => {
			const {content, options, id} = modalData;
			const {accent} = options || {};

			return <Fragment key={id}>
				<Modal
					opened={!toDestroy.includes(id)}
					accent={accent}
					onClosed={() => this._removeModal(id)}
				>
					{content}
				</Modal>
			</Fragment>;
		});
	}

	/**
	 * Open
	 * ---
	 * @param {*} content
	 * @param {M_Modal_OptionsDefaults} options
	 */
	open(content, options) {
		if(content) {
			this.setState(state => {
				let newContent = state.content;
				let mergedOptions = StoreHelpers.deepMerge(M_Modal_OptionsDefaults, options, true);

				newContent = ArrayUtils.push(newContent, true, {
					content,
					options: mergedOptions,
					id: Math.random() + Date.now(),
				});

				return {
					content: newContent,
					toDestroy: mergedOptions.replaceCurrent && state.content.length > 0 ? [...state.toDestroy, ArrayUtils.lastItem(state.content).id] : state.toDestroy,
				}
			});
		}
		else {
			throw Error("No dialog content provided");
		}
	}

	close(target) {
		if(this.state.content.length > 0) {
			if(target) {
				this.setState(state => {
					let toDestroy = resolvePolymorphVar(
						target,
						{
							string: s => state.content.filter(modal => modal.options.namespace === s),
							boolean: b => b ? state.content : [],
							array: arr => state.content.filter(modal => arr.includes(modal.options.namespace)),
							function: f => f(state.content),
							number: n => [state.content[n]],
						},
						[]
					);

					return {
						toDestroy: [...state.toDestroy, ...ArrayUtils.mapValid(toDestroy, modal => modal.id)],
					};
				});
			}
			else {
				this.setState(state => ({
					toDestroy: [...state.toDestroy, ArrayUtils.lastItem(state.content).id],
				}));
			}
		}
	}

	closeSpecific(id) {
		this.setState(state => ({
			toDestroy: [...state.toDestroy, id],
		}));
	}

	_removeModal(id) {
		this.setState(state => ({
			content: ArrayUtils.removeID(state.content, id, true, true),
			toDestroy: ArrayUtils.removeItem(state.toDestroy, id, true, true),
		}));
	}

	static get stateTypes() {
		return {
			content: PropTypes.element,
		}
	}
}

export default GlobalModal;
