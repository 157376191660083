import { IS } from "../IS";
import { StoreHelpers } from "../StoreHelpers";
import { Process } from "../Process";
import { TimeFormatProcessor } from "./TimeFormatProcessor";
import { E_WeekDay } from "../../models/constants/JiffyContants";

export const E_Duration_FormatOptions = {
	AUTO: true,
	//Always displayed with padding e.g. (1d 00h 00m 00s 0000ms)
	FULL: "FULL",
	//Displayed only if not null, without padding e.g. (1d)
	SHORT: "SHORT",
	//Not displayed at all even if the value is not 0
	NONE: false,
};

export class Jiffy {
	/**
	 * @returns {{
	 *      i18n: {
	 *          days: String,
	 *          hours: String,
	 *          minutes: String,
	 *          seconds: String,
	 *          milliseconds: String,
	 *      },
	 *      format: {
	 *          days: E_Duration_FormatOptions,
	 *          hours: E_Duration_FormatOptions,
	 *          minutes: E_Duration_FormatOptions,
	 *          seconds: E_Duration_FormatOptions,
	 *          milliseconds: E_Duration_FormatOptions,
	 *      },
	 *      returnObject: Boolean,
	 * }}
	 */
	static get defaultDurationOptions() {
		//Options = "full" | "short" | (false | "none")
		return {
			format: {
				days: E_Duration_FormatOptions.SHORT,
				hours: E_Duration_FormatOptions.SHORT,
				minutes: E_Duration_FormatOptions.SHORT,
				seconds: E_Duration_FormatOptions.SHORT,
				milliseconds: false,
			},
			suffixes: {
				days: true,
				hours: true,
				minutes: true,
				seconds: true,
				milliseconds: true,
			},
			i18n: {
				days: "d",
				hours: "h",
				minutes: "m",
				seconds: "s",
				milliseconds: "ms",
			}
		}
	}

	static get enum() {
		return {
			weekday: E_WeekDay,
		}
	}

	static parseDuration(ms) {
		ms = Math.floor(ms);
		let secs = Math.floor(ms / 1000);
		ms = ms % 1000;
		let minutes = Math.floor(secs / 60);
		secs = secs % 60;
		let hours = Math.floor(minutes / 60);
		minutes = minutes % 60;

		let days = Math.floor(hours / 24);
		hours = hours % 24;

		return {
			days,
			hours,
			minutes,
			seconds: secs,
			milliseconds: ms,
		};
	}

	/**
	 * Readable duration
	 * ---
	 * @param {Number|Date} ms Duration in milliseconds
	 * @param {Jiffy.defaultDurationOptions} options Result options
	 * @return {String|Object}
	 */
	static readableDuration(ms, options) {
		const parsedDuration = Jiffy.parseDuration(ms);
		const {days, hours, minutes, seconds, milliseconds} = parsedDuration;
		const mergedOptions = StoreHelpers.deepMerge(this.defaultDurationOptions, options, true);

		const __getDisplayValue = (key, value) => {
			switch (mergedOptions.format[key]) {
				case E_Duration_FormatOptions.AUTO:
				case E_Duration_FormatOptions.FULL:
					return Process.pad(value, 2, 0);
				case E_Duration_FormatOptions.SHORT:
					return value > 0 ? value : null;
				case E_Duration_FormatOptions.NONE:
					return null;
				default:
					return value;
			}
		};
		const __getResult = (key, value) => {
			let displayValue = __getDisplayValue(key, value);
			return IS.valid(displayValue) ? `${displayValue}${mergedOptions.suffixes[key] ? mergedOptions.i18n[key] : ''}` : null;
		};

		const data = {
			days: __getResult("days", days),
			hours: __getResult("hours", hours),
			minutes: __getResult("minutes", minutes),
			seconds: __getResult("seconds", seconds),
			milliseconds: __getResult("milliseconds", milliseconds),
		};

		if(mergedOptions.returnObject) {
			data._raw_ = parsedDuration;
			return data;
		}
		return Object.values(data).filter(item => IS.valid(item)).join(" ");
	}

	static formatToMs(time) {
		return new TimeFormatProcessor(time).ms;
	}

	static formatFromMs(milliseconds, format, decimals = 2) {
		return new TimeFormatProcessor(milliseconds).formatted(format, decimals);
	}

	static msToDuration(milliseconds, options) {
		return new TimeFormatProcessor(milliseconds).toDuration(options);
	}
}
