import React from "react";
import { combineClasses, IS, resolvePolymorphVar } from "@green24/js-utils";
import { RIS } from "./ReactIS";
import { AppUtils } from "./AppUtils";

export class ComponentUtils {
	/**
	 * Resolve polymorph prop
	 * ---
	 * Handles prop data based on options.
	 *
	 * **Warning!** All options must be defined according "[type]: () => ..." formula
	 * @param {*} prop
	 * @param {Object} options
	 * @param {*} fallback
	 * @return {*}
	 */
	static resolvePolymorphProp(prop, options, fallback = () => null) {
		return resolvePolymorphVar(prop, {
			...options,
			object: (o) => {
				if(RIS.component(o)) {
					return IS.property(options, "component") ? options.component(o) : fallback();
				}

				return IS.property(options, "object") ? options.object(o) : fallback();
			}
		}, fallback, true);
	}

	static propagateProps(component, props, suppressWarning = false) {
		if(!component) return component;
		if(RIS.customComponent(component)) {
			return React.cloneElement(component, props);
		}
		if(!suppressWarning && AppUtils.isDev) {
			console.warn("Cannot propagate props to component:", component);
		}
		let {className, style} = props;
		return React.cloneElement(component, {className, style});
	}

	static wrapWithWrapper(body, wrapper, props = {}) {
		if(wrapper) {
			return React.cloneElement(wrapper, {
				...props,
				className: combineClasses(props.className, wrapper.props.className),
				style: {
					...wrapper.props.style,
					...props.style
				},
				children: body,
			});
		}
		return body;
	}
}
