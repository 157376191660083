import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses, Jiffy } from "@green24/js-utils";
import Loading from "../../../shared/components/Loading";
import { afeOrderServices } from "../../services/afe-order-services";
import { withTranslationContext } from "../../../shared/components/Localization/withTranslationContext";
import ErrorBlock from "../../../shared/components/ErrorBlock";
import PinInput from "../../../shared/components/Input/PinInput";
import ButtonsConstructor from "../../../shared/components/Input/Button/ButtonsConstructor";
import { AFE_ROUTE } from "../../models/constants/AfeRoutes";
import MessageModal from "../../../shared/components/Modal/Instances/MessageModal";
import ThemeHeader from "../../../shared/components/Theme/ThemeHeader";
import Interpret from "../../../shared/components/Localization/Interpret";
import { applicationThunk } from "../../../shared/store/store";
import { ErrorUtils } from "../../../shared/utils/ErrorUtils";

class AfeOrderWizardPin extends Component {
	constructor() {
		super();

		this.state = {
			sending: false,
			error: null,
			targetPin: null,
			pin: '',
		};
	}

	componentDidMount() {
		const {contact, order} = this.props;

		applicationThunk.setIdleTimeout(-1);

		contact && order && this._sendPin();
	}

	_renderContent() {
		const {order} = this.props;
		const {pin} = this.state;

		return (
			<>
				<ThemeHeader/>

				<h1><Interpret id={"enterPin"}/></h1>

				<PinInput
					value={pin}
					onModify={pin => this.setState({pin})}
					maxLength={4}
					autoSubmit={true}
					onSubmit={() => this._verifyPin()}
				/>

				<nav className={"navigation-buttons"}>
					<ButtonsConstructor buttons={[
						{
							text: "back",
							href: AFE_ROUTE.ORDER_WIZARD_PARTNER_CHECK(order.orderNumber),
						},
						{
							text: "continue",
							action: () => this._verifyPin(),
						}
					]}/>
				</nav>
			</>
		)
	}

	render({className, style, order}, {sending, error, pin}) {
		return (
			<section className={combineClasses("order-wizard-pin", className)} style={style}>
				{
					sending &&
					<Loading title={"pin.sending"} className={"with-background full-page big"} style={{zIndex: 2}}/>
				}
				{
					error &&
					<ErrorBlock error={error} retry={true} onRetry={() => this._sendPin()}/>
					||
					this._renderContent()
				}
			</section>
		);
	}

	_sendPin() {
		const {order, contact, translationContext} = this.props;

		this.setState({
			sending: true,
			error: null,
			targetPin: null,
		});
		afeOrderServices.sendPinToContact(order.orderNumber, contact, translationContext.getActiveLanguageISOCode()).then(({pin}) => {
			this.setState({targetPin: pin});

			applicationThunk.setIdleTimeout(Jiffy.formatToMs("4m"));
		}, (error) => {
			this.setState({error});

			if(error.error === "POPUP_ERROR") {
				ErrorUtils.handlePopupErrorResponse(error);
			}
		}).finally(() => {
			this.setState({sending: false});
		});
	}

	_verifyPin() {
		const {onContinue} = this.props;
		const {pin, targetPin} = this.state;

		if(pin == targetPin) {
			onContinue();
		}
		else {
			applicationThunk.setIdleTimeout();

			window.modal.open(
				<MessageModal
					title={"pin.incorrect.title"}
					message={"pin.incorrect.message"}
				/>
			)
		}
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			order: PropTypes.object.isRequired,
			contact: PropTypes.object.isRequired,

			onContinue: PropTypes.func.isRequired,

			//withTranslationContext
			translationContext: PropTypes.object,
		}
	}
}

export default withTranslationContext(AfeOrderWizardPin);
