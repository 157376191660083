import { Provider } from "react-redux";
import TranslationProvider from "../shared/components/Localization/TranslationProvider";
import { E_Language } from "@green24/js-utils";
import store from "../shared/store/store";
import AfeConfigurationLoader from "./components/other/AfeConfigurationLoader";
import AfeApp from "./components/AfeApp";
import "./styles/afe.scss";

import csShared from "../shared/i18n/cs.json";
import enShared from "../shared/i18n/en.json";
import cs from "./i18n/cs.json";
import en from "./i18n/en.json";

window.store = store;

export default () => (
	<Provider store={store}>
		<TranslationProvider translations={{
			[E_Language.CZECH]: {...csShared, ...cs},
			[E_Language.ENGLISH]: {...enShared, ...en},
		}}>
			<AfeConfigurationLoader>
				<AfeApp/>
			</AfeConfigurationLoader>
		</TranslationProvider>
	</Provider>
)
