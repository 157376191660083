import React from "react";
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import Spinner from "./Spinner";
import { ComponentUtils } from "../utils/ComponentUtils";
import Interpret from "./Localization/Interpret";

class Loading extends React.Component {
	render({className, style, title, block}) {
		return (
			<section
				className={combineClasses(
					"loading",
					block && "block",
					className
				)}
				style={style}
			>
				<Spinner block={true}/>

				<p>
					{ComponentUtils.resolvePolymorphProp(
						title,
						{
							component: c => c,
							function: f => f(),
							string: s => <Interpret id={s}/>
						}
					)}
				</p>
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			title: PropTypes.any,
			block: PropTypes.bool,
		}
	}

	static get defaultProps() {
		return {
			block: false,
		}
	}
}

export default Loading;
