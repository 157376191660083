import { StoreThunk } from "../../../shared/store/thunk/StoreThunk";
import { AFN_DEFAULT_CONFIGURATION } from "../../models/Models_AfnConfiguration";
import { StoreHelpers } from "@green24/js-utils";
import { configurationServices } from "../../../shared/services/configuration-services";
import { M_Configuration } from "../../../shared/models/Models_Configuration";
import { configurationActions } from "../../../shared/store/actions/actions";

export class Thunk_AFN_Configuration extends StoreThunk {
	getConfiguration() {
		this.dispatch(configurationActions.getFetchPending());

		configurationServices.getItem(`./assets/configuration.json`, AFN_DEFAULT_CONFIGURATION).then(data => {
			data = StoreHelpers.deepMerge(M_Configuration, data, true);

			this.dispatch(configurationActions.getFetchFulfilled(data));
		}, (res) => {
			this.dispatch(configurationActions.getFetchRejected(res));
		});
	}

	saveConfiguration(data) {
		this.dispatch(configurationActions.getEditPending());

		let newConfiguration = StoreHelpers.deepMerge(M_Configuration, data, true);

		configurationServices.saveItem(newConfiguration).then(() => {
			this.dispatch(configurationActions.getEditFulfilled(newConfiguration));
		}, (res) => {
			this.dispatch(configurationActions.getEditRejected(res));
		});
	}

	clearConfiguration() {
		return this.saveConfiguration(AFN_DEFAULT_CONFIGURATION);
	}
}
