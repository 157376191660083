import { IS } from "./IS";
import { get, resolvePolymorphVar } from "../functions/generic";
import { ObjectUtils } from "./ObjectUtils";

export class ArrayUtils {
	/**
	 * Select
	 * ---
	 * Returns items from within the provided range
	 * ```
	 *  [1,2,3].select(0,1) => [1]
	 *  [1,2,3,4,5,6].select(1,4) => [2,3,4,5]
	 *
	 *  //Special Case
	 *  [1,2,3].select() => [1,2,3]
	 * ```
	 * @param {Array} array Source array
	 * @param {Number} start Selection start
	 * @param {Number|String|Function} count End selector (Number = count | Function = {Boolean} true => i as a result)
	 * @returns {Array} Selection Array
	 */
	static select(array, start = 0, count) {
		count = IS.valid(count) ? count : array.length;

		if (IS.string(count)) {
			if (count.includes("len-")) {
				count = array.length - count.split("len-")[1];
			} else {
				return [...array].splice(start, array.indexOf(count) + 1);
			}
		}
		else if(IS.number(count) && count < 0) {
			let stackedArray = [...array, ...array];
			start = array.length + start - Math.abs(count) + 1;
			return stackedArray.splice(start, Math.abs(count)).reverse();
		}
		else if (IS.fnc(count)) {
			for (let i = start; i < array.length; i++) {
				if (count(array[i], i)) {
					return [...array].splice(start, i - start);
				}
			}
		}
		else {
			!IS.number(count) && console.warn("Unknown end format. Use {Number} or {Function}", count);
		}
		return [...array].splice(start, count);
	}

	/**
	 * Push
	 * ---
	 * Push items
	 * @param {Array} array Source array
	 * @param {Boolean} immutable handles if the array should be modified or modify a new one instead
	 * @param {*} items
	 * @returns {Array}
	 */
	static push(array, immutable = false, ...items) {
		let target = immutable ? [...array] : array;
		target.push(...items);
		return target;
	}

	/**
	 * Push Unique
	 * ---
	 * Pushes unique item(s) into the array
	 * ```
	 *  [1].pushUnique(2,3) => [1,2,3]
	 *  [1,2].pushUnique(2,3) => [1,2,3]
	 *  [1].pushUnique(1,1,1,1,1) => [1]
	 * ```
	 * @param {Array} array Source array
	 * @param {*} items Items
	 */
	static pushUnique(array, ...items) {
		for (let i = 0; i < items.length; i++) {
			if(!array.includes(items[i])) {
				array.push(items[i]);
			}
		}
		return array;
	}

	/**
	 * Move Item
	 * ---
	 * Moves an item within the array
	 * ```
	 *  ["a","b","c"].moveItem("a", 2) => ["b","c","a"]
	 *  ["a","b","c"].moveItem(0, 2) => ["b","c","a"]
	 *  [1,2,3].moveItem(1, 2, false) => [2,3,1]
	 * ```
	 * @param {Array} array Source array
	 * @param {*|Number} itemOrIndex Item or Index
	 * @param {Number} newPosition New position
	 * @param numberIsIndex
	 * @returns {Array} Returns this array
	 */
	static moveItem(array, itemOrIndex, newPosition, numberIsIndex = true) {
		let index = (IS.number(itemOrIndex) && numberIsIndex) ? itemOrIndex : array.indexOf(itemOrIndex);
		if (index != -1) {
			array.splice(newPosition, 0, array.splice(index, 1)[0]);
		}
		return array;
	}

	/**
	 * Remove Item
	 * ---
	 * Removes and returns the item
	 * ```
	 *  ["a","b","c"].removeItem("a") => ["b","c"]
	 * ```
	 * @param {Array} array Source array
	 * @param {*} item Item
	 * @param {Boolean} immutable handles if the array should be modified or modify a new one instead
	 * @param {Boolean} returnArray Returns an array even if the immutable is false to keep the consistency
	 * @returns {*} Removed Item
	 * @see ArrayUtils.removeIndex
	 */
	static removeItem(array, item, immutable = false, returnArray = false) {
		const index = array.findIndex(arrItem => (arrItem === item));

		if(index > -1) {
			return ArrayUtils.removeIndex(array, index, immutable, returnArray);
		}

		if(returnArray) {
			return array;
		}
		return undefined;
	}

	/**
	 * Remove Index
	 * ---
	 * Removes and returns an item on the specified index
	 * @param {Array} array Source array
	 * @param {Number} index target index
	 * @param {Boolean} immutable handles if the array should be modified or modify a new one instead
	 * @param {Boolean} returnArray Returns an array even if the immutable is false to keep the consistency
	 * @return {Array} Based on immutable && returnArray arguments, returns either the new filtered array (immutable == true) or the item that was removed (immutable == false && returnArray == false)
	 * @see removeItem()
	 */
	static removeIndex(array, index, immutable= false, returnArray= false) {
		let splicedItem;
		let newArray = null;

		if(immutable) {
			newArray = [...array];
			splicedItem = newArray.splice(parseInt(index), 1);
		}
		else {
			splicedItem = array.splice(parseInt(index), 1);
		}

		if(returnArray) {
			return newArray || array;
		}
		return splicedItem;
	}

	/**
	 * Remove last item
	 * ---
	 * Removes last item of the **array**, it has the same behavior as .pop().
	 * The only difference is that this can perform .pop() operation on new array (if **immutable** = true) and can also return one (**returnArray** = true)
	 * @param array
	 * @param {Boolean} immutable handles if the **array** should be modified or modify a new one instead
	 * @param {Boolean} returnArray Returns an array even if the immutable is false to keep the consistency
	 * @returns {Array|*} Based on immutable && returnArray arguments, returns either array without the last item (if immutable == true, returns new array) or the item that was removed (immutable == false && returnArray == false)
	 * @see Array.pop
	 */
	static removeLastItem(array, immutable= false, returnArray= false) {
		let arr = array;
		let poppedItem;

		if(immutable) {
			arr = [...array];
			poppedItem = arr.pop();
		}
		else {
			poppedItem = arr.pop();
		}

		if(returnArray) {
			return arr;
		}
		return poppedItem;
	}

	/**
	 * Last Item
	 * ---
	 * Returns last item of an array
	 * @param {Array} array Source array
	 * @returns {*} Last Item
	 */
	static lastItem(array) {
		return array[array.length - 1];
	}

	/**
	 * Return previous before match
	 * ---
	 * Returns previous entry before the matching condition IS fulfilled
	 * ```
	 *  [1,2,3].returnPreviousBeforeMatch(item => item == 2) => 1
	 *
	 *  //Clamp
	 *  [1,2,3].returnPreviousBeforeMatch(item => item == 1) => null
	 *  [1,2,3].returnPreviousBeforeMatch(item => item == 1, true) => 1
	 * ```
	 * @param {Array} array Source array
	 * @param {function(item: *)} condition Condition function
	 * @param {Boolean} clamp Clamp (if index < 0 returns 0)
	 * @returns {*|null}
	 */
	static returnPreviousBeforeMatch(array, condition, clamp = false) {
		for (let i = 0; i < array.length; i++) {
			if (condition(array[i])) {
				return i > 0 ? array[i-1] : (clamp && array[0] || null);
			}
		}
		return null;
	}

	/**
	 * Subtract
	 * ---
	 * ```
	 *  [1,2,3].subtract([1,2]) => [3]
	 * ```
	 * Subtracts one array from another
	 * @param {Array} sourceArray Source array
	 * @param {Array} targetArray Array
	 * @param {function(item1:*, item2:*, i:Number, j:Number)} solver Solver function if necessary for deeper subtract conditions (true = subtract)
	 * @returns {Array}
	 */
	static subtract(sourceArray, targetArray, solver = null) {
		if (IS.empty(sourceArray) || IS.empty(targetArray)){return sourceArray;}

		if (IS.valid(solver)) {
			let result = [];
			sourceArray.forEach((item1, i) => {
				for (let j = 0; j < targetArray.length; j++) {
					let item2 = targetArray[j];

					let match = resolvePolymorphVar(solver, {
						function: f => f({item1, item2, i, j}),
						string: path => IS.equal(get(item1, path), get(item2, path)),
					}, false);

					if (match) {
						return;
					}
				}
				result.push(item1);
			});
			return result;
		}

		return sourceArray.filter(solver || (item => !targetArray.includes(item)));
	}

	/**
	 * Extract
	 * ---
	 * Extracts values from an array of any depth
	 * @param {Array} array Source array
	 * @param {string} path Extraction path
	 * @returns {Array}
	 */
	static extract(array, path = '') {
		if(path === '') return array;

		const FALLBACK_SYMBOL = Symbol("Fallback symbol");
		const pathStages = IS.array(path) ? path : path.split(/\.?\*\.?/);

		return array.map(item => {
			let target = IS.empty(pathStages[0]) ? item : get(item, pathStages[0], FALLBACK_SYMBOL);

			if(target !== FALLBACK_SYMBOL) {
				if(pathStages.length > 1) {
					return resolvePolymorphVar(
						target,
						{
							object: o => ObjectUtils.extract(o, pathStages.slice(1)),
							array: arr => ArrayUtils.extract(arr, pathStages.slice(1)),
						}
					);
				}

				return target;
			}
		}).flat(1);
	}

	/**
	 * Merge
	 * ---
	 * Merges two Arrays into one while overriding {sourceArray} with {targetArray} items
	 * ```
	 *  [1,2,3].merge([3,4]) => [3,4,3]
	 *  [1].merge([2,3,4]) => [2,3,4]
	 * ```
	 * @param {Array} sourceArray Source array
	 * @param {Array} targetArray Array
	 * @param {function(*,*):null} iteratorCallback Callback for each item (currentItem, mergedItem)
	 * @return {Array} {this}
	 */
	static merge(sourceArray, targetArray, iteratorCallback = null) {
		if (IS.empty(sourceArray) || IS.empty(targetArray)) {
			return IS.empty(sourceArray) ? [...(targetArray || [])] : [...(sourceArray || [])];
		}

		let result = [];
		const length = Math.max(sourceArray.length, targetArray.length);

		for (let i = 0; i < length; i++) {
			result.push(targetArray[i] || sourceArray[i]);
			iteratorCallback && iteratorCallback(sourceArray[i], result[i]);
		}
		return result;
	}

	/**
	 * To object
	 * ---
	 * Maps an array of objects (key - value pairs) into an object.
	 *
	 * Can process any contents of the array, even though the main purpose is to remap the key-value pairs.
	 * @param {Array} array Target array
	 * @param {function(item, i, self, skip)} mapper Mapping function, should return {key: *, value: *} pair or a **skip** symbol when the entry needs to be skipped
	 * @param {boolean} useIndexIfUndefinedKey If true, when the **key** is missing from the mapper, an Array index will be used instead.
	 * @return {Object}
	 */
	toObject(array, mapper, useIndexIfUndefinedKey) {
		return ObjectUtils.arrayToObject(array, mapper, useIndexIfUndefinedKey);
	}

	/**
	 * Find by ID
	 * ---
	 * Finds an item by id inside a shallow object
	 * ```
	 *  [{id: 1}, {id: 2, test: true}, {id: 3}].findByID(2) => {id: 2, test: true}
	 * ```
	 * @param {Array} array Source array
	 * @param {Number|String} id ID
	 * @param {Boolean} returnIndex Return Index
	 * @returns {Number|*}
	 */
	static findByID(array, id, returnIndex = false) {
		if (returnIndex) {
			return array.findIndex(item => item.id == id);
		}
		return array.find(item => item.id == id);
	}

	/**
	 * Categorize
	 * ---
	 * Categorizes items based on conditions into one or multiple categories
	 * @param {Array} array
	 * @param {Object<string, function(item, {i: number, array: Array, key: string}): boolean>} conditions
	 * @param {boolean} [categorizeOnlyOnce]
	 * @return {Object<string, Array> & {uncategorized: Array}}
	 */
	static categorize(array, conditions, categorizeOnlyOnce = false) {
		let categories = {
			uncategorized: [],
			...ObjectUtils.mapAsObject(conditions, key => ({
				key,
				value: [],
			})),
		};

		array.forEach((item, i) => {
			let categorized = false;

			ObjectUtils.forEach(conditions, (key, condition, ci) => {
				if(categorized && categorizeOnlyOnce) return;

				if(condition(item, {i, array, key, conditionIndex: ci})) {
					categories[key].push(item);

					categorized = true;
				}
			});

			if(!categorized) {
				categories.uncategorized.push(item);
			}
		});

		return categories;
	}

	/**
	 * Sort with order
	 * ---
	 * Sorts an Array according to the predetermined order
	 * ```
	 *  [1,5,9,1,5,6].sortWithOrder([1,5,6,9]) => [1,1,5,5,6,9]
	 *
	 *  // Watch out for values that aren't in {order} array!
	 *  // They will be just appended at the end of the resulting array unless defined otherwise in the {leftoverProcessor}.
	 *  [1,5,9,1,5,6,8].sortWithOrder([1,5,6,9]) => [1,1,5,5,6,9,8]
	 * ```
	 * @param {Array} array Source array
	 * @param {Array} order Sort order array. Mostly in form of String|Number keys.
	 * @param {Function} matcher Item match function
	 * @param {Function} leftoverProcessor Processor function for leftover items after sort (by default, they will be placed at the end)
	 * @returns {Array}
	 */
	static sortWithOrder(array, order, matcher, leftoverProcessor) {
		if (array.length < 2 || !IS.valid(order)) return array; // Nothing to be sorted || no sort rule

		const matcherFnc = resolvePolymorphVar(
			matcher,
			{
				function: f => f,
				string: s => ({item1, item2}) => get(item1, s) == get(item2, s),
			},
			() => (({item1, item2: orderItem}) => item1 == orderItem),
			true
		);
		const leftoverResolver = resolvePolymorphVar(
			leftoverProcessor,
			{
				function: f => (sorted, leftovers) => f({sorted, original: array, order, leftovers}),
				string: s => (sorted, leftovers) => {
					if(s == "prepend") return [...leftovers, ...sorted];
					return [...sorted, ...leftovers];
				},
			},
			() => (sorted, leftovers) => [...sorted, ...leftovers],
			true
		);

		let sorted = [];
		order.forEach(orderItem => {
			sorted.push(
				...array.filter(
					item => matcherFnc({item1: item, item2: orderItem})
				)
			);
		});

		let leftovers = array.filter(item => !sorted.includes(item));

		return leftoverResolver(sorted, leftovers);
	}

	/**
	 * Fill in order
	 * ---
	 * Creates a new array with index + startIndex (further only iteration value) offset values
	 * ```
	 *  Array().fillInOrder(5) => [0,1,2,3,4]
	 *  Array().fillInOrder(5, 2) => [2,3,4,5,6]
	 *  Array().fillInOrder(5, 2, 4) => [2,3,4]
	 * ```
	 * @param {Array} array Source array
	 * @param {Number} length Length of the array (Might be limited by {max} value)
	 * @param {Number} startIndex Start index from which start iteration values
	 * @param {Number} max Max possible iteration value. Will affect length of the array if the value IS reached.
	 * @returns {Array}
	 */
	static fillInOrder(array, length, startIndex = 0, max = null) {
		for (let i = 0; i < length; i++) {
			if(max && startIndex + i + 1 > max + 1) {
				return array;
			}

			array.push(startIndex + i);
		}
		return array;
	}

	/**
	 * Remove by ID
	 * ---
	 * Removes an item by id
	 * @param {Array} array Source array
	 * @param {Number} id target id
	 * @param {Boolean} immutable handles if the array should be modified or modify a new one instead
	 * @param {Boolean} returnArray Returns an array even if the immutable is false to keep the consistency
	 * @return {Array} Based on immutable && returnArray arguments, returns either the new filtered array (immutable == true) or the item that was removed (immutable == false && returnArray == false)
	 */
	static removeID(array, id, immutable = true, returnArray = false) {
		if(immutable) {
			return array.filter(item => item.id != id);
		}
		else {
			let splicedItem = null;
			let spliceIndex = ArrayUtils.findByID(array, id, true);

			if(spliceIndex >= 0) {
				splicedItem = array.splice(spliceIndex, 1);
			}

			if(returnArray) {
				return array;
			}
			return splicedItem;
		}
	}

	/**
	 * Contains
	 * ---
	 * Returns if provided target is contained within source array
	 * @param {Array} array Source array
	 * @param {*} target
	 * @param {String|Function} solver
	 * @return {Boolean}
	 */
	static contains(array, target, solver = null) {
		if(!IS.array(array)) { return false; }

		if(solver) {
			if(IS.fnc(solver)) {
				if(solver.length == 1) {
					return array.findIndex((item, i) => solver({
						item: item,
						target: target,
						item1: target,
						item2: item,
						i: i,
					})) > -1;
				}
				return array.findIndex(item => solver(item, target)) > -1;
			}

			if(IS.string(solver)) {
				return array.findIndex(item => get(item, solver) == target) > -1;
			}
		}
		return array.includes(target);
	}

	/**
	 * Overlap
	 * ---
	 * Returns overlap of two arrays (returns items that are equal [fully or computed by solver] and present in both arrays)
	 * @param {Array} array1 Source array
	 * @param {Array} array2 Target array
	 * @param {String|Function} solver
	 * @return {Array}
	 * @see ArrayUtils.contains
	 */
	static overlap(array1, array2, solver = null) {
		return array1.filter(item => ArrayUtils.contains(array2, item, solver));
	}

	/**
	 * Find index
	 * ---
	 * More advanced search for index in an Array. Returns index or -1 if not found
	 * @param {Array} array Source array
	 * @param {Number} target Target
	 * @param {String|Function} solver Solver instructions
	 * @return {Number} Found index or -1 if not found
	 * @see Array.findIndex
	 */
	static findIndex(array, target, solver) {
		if(solver) {
			if(IS.fnc(solver)) {
				return array.findIndex(item => solver(item, target));
			}

			if(IS.string(solver)) {
				return array.findIndex(item => get(item, solver) == target);
			}
		}
		return array.findIndex(item => item == target);
	}

	/**
	 * Filter by black and white list
	 * ---
	 * Returns an Array filtered by blacklist and whitelist
	 * @param {Array} array Source array
	 * @param {Array} blacklist Blacklist
	 * @param {Array} whitelist Whitelist
	 * @param {String|Function} solver
	 * @return {Array}
	 */
	static filterByBlackAndWhiteList(array, blacklist, whitelist, solver) {
		return array.filter(item => (
			(blacklist ? !ArrayUtils.contains(blacklist, item, solver) : true) &&
			(whitelist ? ArrayUtils.contains(whitelist, item, solver) : true)
		));
	}

	/**
	 * @deprecated - Use ArrayUtils.diff
	 * Compare
	 * ---
	 * Compare two arrays and returns which items are same, added and missing.
	 *
	 * For better understanding of which is which, imagine [array] as a new array that was modified from [compareWith].
	 * Which means that items that are categorized as "added" are items that are in [array] but weren't in [compareWith]
	 * and "removed" as items that were in [compareWith] but are no longer present in [array].
	 *
	 * @param {Array} array Source array
	 * @param {Array} compareWith Array to compare source array with
	 * @param {String | function({item1, item2, i, j})} solver
	 * @param {Boolean} invert Inverts added and removed
	 * @return {{same: Array, removed: Array, added: Array}}
	 * @see ArrayUtils.diff
	 */
	static compare(array, compareWith = [], solver, invert = false) {
		/*
		 * If performance will be a concern, replace .filter and ArrayUtils.subtract with a single for loop and sort the items manually.
		 */

		if(IS.array(array)) {
			if(IS.array(compareWith)) {
				//Compare two arrays
				if(invert) {
					return {
						same: compareWith.filter(item => ArrayUtils.contains(array, item, solver)),
						added: ArrayUtils.subtract(compareWith, array, solver),
						removed: ArrayUtils.subtract(array, compareWith, solver),
					}
				}
				return {
					same: array.filter(item => ArrayUtils.contains(compareWith, item, solver)),
					added: ArrayUtils.subtract(array, compareWith, solver),
					removed: ArrayUtils.subtract(compareWith, array, solver),
				};
			}
			//compareWith is null/undefined/not an array, ...
			if(invert) {
				return {
					same: [],
					added: [],
					removed: [...array],
				}
			}
			return {
				same: [],
				added: [...array],
				removed: [],
			}
		}
		//array is null/undefined/not an array, ...
		if(invert) {
			return {
				same: [],
				added: [...compareWith],
				removed: [],
			}
		}
		return {
			same: [],
			added: [],
			removed: [...compareWith],
		}
	}

	/**
	 * Diff
	 * ---
	 * Compare differences between two arrays and returns which items are the same, added and removed/missing.
	 *
	 * Items that are categorized as "added" are items that are in **newArray** but weren't in **oldArray**
	 * and "removed" as items that were in **oldArray** but are no longer present in **newArray**.
	 *
	 * @param {Array} newArray Source array with a new data
	 * @param {Array} oldArray Array to compare source array with
	 * @param {String | function({item1, item2, i, j})} solver
	 * @param {Boolean} invert Inverts added and removed states
	 * @return {{same: Array, removed: Array, added: Array}}
	 */
	static diff(newArray, oldArray, solver, invert = false) {
		//Quickly solve diff when one of the arrays is empty
		if(IS.empty(newArray)) {
			return {
				removed: invert ? [] : [...oldArray],
				added: invert ? [...oldArray] : [],
				same: [],
			}
		}
		else if(IS.empty(oldArray)) {
			return {
				removed: invert ? [...newArray] : [],
				added: invert ? [] : [...newArray],
				same: [],
			}
		}

		const __handleSolver = (item1, item2, i, j) => {
			let fallback = Symbol("Fallback");
			let solverResult = resolvePolymorphVar(solver, {
				function: f => f({item1, item2, i, j}),
				string: path => IS.equal(get(item1, path), get(item2, path)),
			}, fallback);

			if(solverResult === fallback) {
				return IS.equal(item1, item2);
			}
			return solverResult;
		};

		let added, same, removed;

		same = oldArray.filter((item1, i) => (
			newArray.some((item2, j) => __handleSolver(item1, item2, i, j))
		));

		added = newArray.filter((item1, i) => (
			!oldArray.some((item2, j) => __handleSolver(item1, item2, i, j)) &&
			!same.some((item2, j) => __handleSolver(item1, item2, i, j))
		));

		removed = oldArray.filter((item1, i) => (
			!newArray.some((item2, j) => __handleSolver(item1, item2, i, j)) &&
			!same.some((item2, j) => __handleSolver(item1, item2, i, j))
		));

		if(invert) {
			return {
				added: removed,
				removed: added,
				same,
			}
		}
		return {
			added,
			removed,
			same
		}
	}

	/**
	 * @deprecated - Replace with .filterFirst()
	 */
	static extractUnique(array, solver) {
		return ArrayUtils.filterFirst(array, solver);
	}

	/**
	 * Filter unique
	 * ---
	 * Returns an Array of only unique values.
	 * @param {Array} array
	 * @param {function(value, i, array)|string} solver
	 * @returns {Array}
	 */
	static filterUnique(array, solver) {
		const comparator = resolvePolymorphVar(
			solver,
			{
				string: s => (value, i, j, self) => self.findIndex((item, k) => k != i && get(value, s, Symbol()) == get(item, s, Symbol())) === j,
				function: f => f,
			},
			() => (value, i, j, self) => self.indexOf(value, i) === j,
			true
		);

		return array.filter((item, i) => {
			return !array.some((value, j) => comparator(value, i, j, array) && j != i);
		});
	}

	/**
	 * Filter first
	 * ---
	 * Reruns an Array of every first occurrence of a value.
	 * Difference between .filterFirst() and .filterUnique() is that this function returns even the non-unique value, but only once.
	 * @param {Array} array
	 * @param {function(value, i, array)|string} solver
	 * @return {Array} Unique values array
	 */
	static filterFirst(array, solver) {
		const comparator = resolvePolymorphVar(
			solver,
			{
				string: s => (value, i, self) => self.findIndex(item => get(value, s, Symbol()) == get(item, s, Symbol())) === i,
				function: f => f,
			},
			() => (value, i, self) => self.indexOf(value) === i,
			true
		);

		return array.filter(comparator);
	}

	/**
	 * Conditional split
	 * ---
	 * Splits array according to the defined conditions
	 * @param {Array} array Source array
	 * @param {Object} conditions Split conditions
	 * @param {Boolean} canSplitIntoMultipleConditions If true, the item can be tested for multiple passing conditions instead of only one
	 */
	static conditionalSplit(array, conditions, canSplitIntoMultipleConditions = true) {
		let result = {rest: []};

		//Populate result so there won't be any undefined array for any condition
		Object.keys(conditions).forEach(conditionKey => {
			result[conditionKey] = [];
		});

		//Test every item in an array
		(array || []).forEach((item, i) => {
			let passed = false;

			//Test every condition for a certain item
			Object.keys(conditions || {}).forEach(conditionKey => {
				if(!canSplitIntoMultipleConditions && passed) {return;}

				//Test condition
				if(conditions[conditionKey](item, i)) {
					passed = true;
					//Condition passed, add item into the appropriate result array
					result[conditionKey].push(item);
				}
			});

			if(!passed) {
				result.rest.push(item);
			}
		});

		return result;
	}

	/**
	 * Min
	 * ---
	 * Min array value; returns the lowest value within the array.
	 * @param array
	 * @returns {number}
	 */
	static min(array) {
		return Math.max.apply(Math, array);
	}

	/**
	 * Max
	 * ---
	 * Max array value; returns the highest value within the array.
	 * @param array
	 * @returns {number}
	 */
	static max(array) {
		return Math.max.apply(Math, array);
	}

	/**
	 * Sum
	 * ---
	 * Summarizes all array values into one a number
	 * @param {Array<number>} array
	 * @param {function(value: number, index: number, array: Array<number>, sum: number)} solver Custom solver
	 * @returns {number} Sum
	 */
	static sum(array, solver) {
		let sum = 0;
		array.forEach((value, i) => {
			sum += solver ? solver(value, i, array, sum) : value;
		});
		return sum;
	}

	/**
	 * Map valid
	 * ---
	 * Maps only the valid items into the array.
	 * Validity is recognized according to the **invalidIdentifier**
	 * @param {Array} array Source array
	 * @param {function(item, i, arr)} mapper Mapper function
	 * @param {...*} invalidIdentifiers An identifier that will be used to compare the item if is invalid
	 * @return {Array}
	 */
	static mapValid(array, mapper = item => item, ...invalidIdentifiers) {
		let result = [];

		(array || []).forEach((item, i, arr) => {
			if(item) {
				let value = mapper(item, i, arr);
				if(invalidIdentifiers.length == 0 ? value !== undefined : !invalidIdentifiers.includes(value)) {
					result.push(value);
				}
			}
		});

		return result;
	}

	/**
	 * Shift
	 * ---
	 * Shifts an array items according to the velocity
	 * @param {Array} array Source array
	 * @param {number} velocity Direction and amount of how much the array will shift
	 * @param {boolean} immutable Handles if the array should be modified or modify a new one instead
	 * @return {Array}
	 */
	static shift(array, velocity = 1, immutable = true) {
		if(IS.empty(array)) return array;

		let arr = immutable ? [...array] : array;
		for(let i = 0; i < (Math.abs(velocity) % arr.length); i++) {
			if(velocity < 0) {
				let item = arr.shift();
				arr = [...arr, item];
			}
			else {
				let item = arr.pop();
				arr = [item, ...arr];
			}
		}

		return arr;
	}

	/**
	 * Find and retrieve
	 * ---
	 * Unlike .find(), .findAndRetrieve() returns a value returned from the predicate (if the validValueCheck return is not falsy)
	 * @param {Array} array
	 * @param {function(item, index)} predicate
	 * @param {function(result): boolean} validValueCheck Returns if the value for a predicate is valid, falsy = not valid
	 * @return {*}
	 */
	static findAndRetrieve(array, predicate, validValueCheck = v => IS.defined(v) && v !== false) {
		for(let i = 0; i < array.length; i++) {
			let result = predicate(array[i], i);

			if(validValueCheck(result)) return result;
		}
	}

	/**
	 * Split to chunks
	 * ---
	 * Split an array to chunks based on **chunkSize**
	 * @param {Array} array
	 * @param {number} chunkSize
	 * @return {Array}
	 */
	static splitToChunks(array, chunkSize = 1) {
		if(IS.empty(array)) return [];

		let result = [[]];
		let currentChunkIndex = 0;

		array.forEach((item, i) => {
			let currentChunk = result[currentChunkIndex];

			currentChunk.push(item);

			if(currentChunk.length >= chunkSize && i < array.length - 1) {
				currentChunkIndex++;
				result[currentChunkIndex] = [];
			}
		});

		return result;
	}
}
