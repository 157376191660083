import { Component, createContext } from 'preact';
import PropTypes from "prop-types";

export const ThemeContext = createContext();

class ThemeContextProvider extends Component {
	render({children, onHeader, onPaymentSelectRoute}) {
		const themeData = {
			getHeader: (props) => onHeader(props),
			getPaymentSelectRoute: () => onPaymentSelectRoute(),
		}

		return (
			<ThemeContext.Provider value={themeData}>
				{children}
			</ThemeContext.Provider>
		);
	}

	static get propTypes() {
		return {
			children: PropTypes.any,

			onHeader: PropTypes.func.isRequired,
			onPaymentSelectRoute: PropTypes.func.isRequired,
		}
	}
}

export default ThemeContextProvider;
