import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import Keyboard from "../../../shared/components/other/Keyboard/Keyboard";
import { E_Keyboard_Type } from "../../../shared/models/constants/Enums_Keyboard";
import CombineComponentsFromTranslation from "../../../shared/components/Localization/CombineComponentsFromTranslation";
import Price from "../../../shared/components/Price/Price";
import ButtonsConstructor from "../../../shared/components/Input/Button/ButtonsConstructor";
import { E_OrderWizard_PaymentMethod } from "../../../shared/models/constants/Enums_OrderWizard";
import ThemeHeader from "../../../shared/components/Theme/ThemeHeader";
import { AFE_ROUTE } from "../../models/constants/AfeRoutes";
import Interpret from "../../../shared/components/Localization/Interpret";

class AfeOrderWizardCashAmountSelect extends Component {
	constructor() {
		super();

		this.state = {
			amount: 0,
		}
	}

	render({className, style, order}, {amount}) {
		return (
			<section className={combineClasses("order-wizard-cash-amount-select", className)} style={style}>
				<ThemeHeader homeButton={true} motiv={false}/>

				<h1>
					<Interpret id={"enterCashAmount"}/>
				</h1>

				<Keyboard
					type={E_Keyboard_Type.NUMERIC}
					block={true}
					value={amount}
					onModify={amount => this.setState({amount: amount || 0})}
					options={{
						max: Math.round(order.price),
					}}
				/>

				<h3>
					<CombineComponentsFromTranslation id={"cardAmountToPay"}>
						{{
							price: <Price value={Math.round(order.price) == amount ? 0 : Math.max(0, order.price - amount)}/>
						}}
					</CombineComponentsFromTranslation>
				</h3>

				<nav className={"navigation-buttons"}>
					<ButtonsConstructor buttons={[
						{
							text: "back",
							href: AFE_ROUTE.BASE,
						},
						{
							text: "continue",
							href: this._getHrefFromAmount(amount, order.price),
						}
					]}/>
				</nav>
			</section>
		);
	}

	_getHrefFromAmount(amount, price) {
		const {order} = this.props;

		if(amount >= price || amount == Math.round(price)) {
			return AFE_ROUTE.ORDER_WIZARD_PAYMENT(order.orderNumber, E_OrderWizard_PaymentMethod.CASH);
		}
		else if(amount <= 0) {
			return AFE_ROUTE.ORDER_WIZARD_PAYMENT(order.orderNumber, E_OrderWizard_PaymentMethod.CARD);
		}

		return AFE_ROUTE.ORDER_WIZARD_PAYMENT_COMBINED(order.orderNumber, amount);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			order: PropTypes.object,
		}
	}

	static get stateTypes() {
		return {
			amount: PropTypes.number,
		}
	}
}

export default AfeOrderWizardCashAmountSelect;
