import React from "react";
import PropTypes from "prop-types";
import { combineClasses, Refresher } from "@green24/js-utils";
import Fa from "../../../shared/components/Fa";
import Interpret from "../../../shared/components/Localization/Interpret";
import withConfiguration from "../../../shared/components/hoc/withConfiguration";
import { locationThunk } from "../../../shared/store/store";

class OutOfOrderPage extends React.Component {
	componentDidMount() {
		this._locationRefresher = new Refresher(() => {
			locationThunk.getLocation(!!this._locationRefresher).catch(() => null).finally(() => {
				this._locationRefresher.trigger();
			});
		}, 5000);
	}

	componentWillUnmount() {
		this._locationRefresher && this._locationRefresher.stop();
	}

	//TBD: Split into a separate method so it can cover multiple error states
	_renderMessage() {
		return <>
			<Fa className={"logo"} icon={"kv-elektro-logo"}/>

			<div className={"error"}>
				<div className={"icon-wrapper"}>
					<Fa icon={"exclamation-triangle"}/>
				</div>

				<h1><Interpret id={"locationOutOfOrder"}/></h1>
			</div>
		</>;
	}

	render({className, style}) {
		return (
			<section className={combineClasses("out-of-order-page", className)} style={style}>
				{this._renderMessage()}
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			error: PropTypes.object,

			configuration: PropTypes.object,
		}
	}
}

export default withConfiguration(OutOfOrderPage);
