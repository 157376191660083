import { KVElektroGenericServices } from "../../shared/services/api-connector";
import { CONSOLE_WARN_BOLD, QueryStringManager } from "@green24/js-utils";
import { AppUtils } from "../../shared/utils/AppUtils";
import { ServiceSimulator } from "../../shared/services/ServiceSimulator";
import { E_Order_Type } from "../../shared/models/constants/Enums_Order";

class OrderServices extends KVElektroGenericServices {
	getOrder(orderNumber, type = E_Order_Type.ORDER, simulate = false) {
		if(AppUtils.isDev && simulate) {
			console.log("%cSimulating order data...", CONSOLE_WARN_BOLD);

			this._orderProcessSimulator = new ServiceSimulator(
				{
					resolved: {
						orderID: "69a3928b-f4e2-4dce-a3b3-0a94c14e0420",
						currency: "CZK",
						customerName: "Bagr Testový",
						totalPrice: 69.420,
						ticketCreated: false,
						paymentTriggered: false,
					},
					rejected: {error: "TEST", statusCode: -1, errorMessage: "Simulated error"},
				},
				simulate == "resolve" || simulate === true,
				2000,
				() => this._orderProcessSimulator = null
			);
			return this._orderProcessSimulator;
		}

		return this.sendRequest({
			url: `${this.url}${new QueryStringManager({
				orderNumber,
				type,
			})}`,
		});
	}

	openForProcessing(orderNumber, type = E_Order_Type.ORDER, simpleInvoice, vouchers) {
		return this.sendRequest({
			url: `${this.url}${new QueryStringManager({
				orderNumber,
				type,
			}).toValidDataString()}`,
			method: "POST",
			body: JSON.stringify({
				simpleInvoice,
				vouchers,
			}),
		});
	}

	finalizePayment(orderID) {
		return this.sendRequest({
			url: `${this.url}/${orderID}/payment`,
			method: "POST",
		});
	}
}

export const afnOrderServices = new OrderServices(`/afn/order`);
