import { Component } from "preact";
import PropTypes from "prop-types";
import { RouteUtils } from "../../utils/RouteUtils";
import { IdleManager, Jiffy } from "@green24/js-utils";
import root from "window-or-global";
import withConfiguration from "../hoc/withConfiguration";
import { APP_CONSTANTS, APP_ROUTE } from "../../models/constants/AppConstants";
import { applicationThunk } from "../../store/store";
import Router from "preact-router";

class RouteListener extends Component {
	constructor(props) {
		super(props);

		this._location = root.location;
		this._updateIdle = new IdleManager(
			() => this._triggerUpdate(),
			Jiffy.formatToMs(props.configuration.updateWaitTime)
		);

		this._lastInteraction = Date.now();
	}

	componentDidMount() {
		const {configuration} = this.props;

		//Display update handler
		root.addEventListener(APP_CONSTANTS.IDLE_RESET, () => {
			//Update last interaction time
			this._lastInteraction = Date.now();

			if(root[APP_CONSTANTS.DISPLAY_UPDATE]) {
				//User has interacted, update will have to wait...
				this._setIdleForUpdate();
			}
		});

		root.addEventListener(APP_CONSTANTS.DISPLAY_UPDATE, () => {
			//If the last user interaction was more than updateWaitTime ago, update immediately
			if(this._lastInteraction + Jiffy.formatToMs(configuration.updateWaitTime) <= Date.now()) {
				this._updateIdle.forceTrigger();
			}
			else {
				this._setIdleForUpdate();

				//Listen for idle redirect from <IdleTimeout/>
				root.addEventListener("idle-redirect", () => {
					//If the idle redirect has redirected back to the index (means no interaction for a while, assume that the user has left), update immediately
					this._updateIdle.forceTrigger();
				});
			}
		});
	}

	render() {
		return (
			<Router onChange={(e) => {
				this._setIdleTimeouts(e.url);
			}}/>
		);
	}

	_setIdleTimeouts(url) {
		if(url == APP_ROUTE.BASE) applicationThunk.setIdleTimeout(-1);
	}

	_setIdleForUpdate() {
		this._updateIdle.trigger();
	}

	_triggerUpdate() {
		if(this._matchRoute(APP_ROUTE.BASE)) {
			root.location.reload();
		}
	}

	_matchRoute(target, location = this._location) {
		return RouteUtils.matchRoute(target, location);
	}

	static get propTypes() {
		return {
			//withConfiguration
			configuration: PropTypes.object,
		}
	}
}

export default withConfiguration(RouteListener);
