// noinspection SpellCheckingInspection

import { E_Currency } from "../models/constants/Enums_Tariff";

/**
 * @typedef {{
 *  compactDisplay: "short"|"long",
 *  currency: E_Currency,
 *  currencyDisplay: "symbol"|"narrowSymbol"|"code"|"name",
 *  currencySign: "standard"|"accounting",
 *  localeMatcher: "lookup"|"best fit",
 *  notation: "standard"|"scientific"|"engineering"|"compact",
 *  numberingSystem: "arab"|"arabext"|"bali"|"beng"|"deva"|"fullwide"|" gujr"|"guru"|"hanidec"|"khmr"|"knda"|"laoo"|"latn"|"limb"|"mlym"|"mong"|"mymr"|"orya"|"tamldec"|"telu"|"thai"|"tibt",
 *  signDisplay: "auto"|"never"|"always"|"exceptZero",
 *  styles: "decimal"|"currency"|"percent"|"unit",
 *  unit: Unit,
 *  unitDisplay: "long"|"short"|"narrow",
 *  useGrouping: boolean,
 *  minimumIntegerDigits: number,
 *  minimumFractionDigits: number,
 *  maximumFractionDigits: number,
 *  minimumSignificantDigits: number,
 *  maximumSignificantDigits: number,
 * }} NumberFormatOptions
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */

/**
 * @typedef {E_Format_Unit} Unit The unit to use in unit formatting. Pairs of simple units can be concatenated with "-per-" to make a compound unit.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 * @see https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
 */

export class FormatUtils {
	/**
	 * Format price
	 * ---
	 * @param {number} value
	 * @param {string} locale
	 * @param {E_Currency} currency
	 * @param {NumberFormatOptions} options
	 * @returns {string}
	 */
	static formatPrice(value, locale = "cs", currency = E_Currency.CZK, options = {}) {
		return new Intl.NumberFormat(
			locale,
			{
				...(currency && {style: "currency", currency}),
				...options,
			}
		).format(value);
	}

	/**
	 * Format with unit
	 * ---
	 * @param {number} value
	 * @param {string} locale
	 * @param {Unit} unit The unit to use in unit formatting. Pairs of simple units can be concatenated with "-per-" to make a compound unit.
	 * @param {NumberFormatOptions} options
	 * @returns {string}
	 */
	static formatWithUnit(value, locale = "cs", unit, options ={}) {
		return new Intl.NumberFormat(
			locale,
			{
				style: "unit",
				unit,
				...options,
			}
		).format(value);
	}

	static formatDate(value, locale = "cs", options = {}) {
		return Intl.DateTimeFormat(locale, options).format(value);
	}
}
