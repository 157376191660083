import { Jiffy } from "@green24/js-utils";

export const APP_CONSTANTS = {
	LOCATION_UPDATE_TIME: Jiffy.formatToMs("20s"),
	LOCAL_STORAGE_NO_REDIRECT_EVENT_NAME: "no-redirect-storage-update",
	ROUTE_UPDATE_EVENT_NAME: "route-update",
	IDLE_RESET: "idle-reset",
	IDLE_TICK_EVENT_NAME: "idle-tick",
	IDLE_REDIRECT_EVENT_NAME: "idle-redirect",
	DISPLAY_UPDATE: "display-update",
	LOCAL_STORAGE_TOKEN_KEY: "token",
	LOCAL_STORAGE_SIMULATE_PAYMENT_PROCESS: "simulatePaymentProcess",
	LOCAL_STORAGE_SIMULATE_ORDER_VALIDATION: "simulateOrderValidation",
	LOCAL_STORAGE_KEY_KEEP_TOKEN: "keepToken",
};

/**
 * @readonly
 * @enum {String}
 */
export const APP_ROUTE = {
	BASE: '/',
	CONFIGURATION: "/configuration",
	MAINTENANCE: `/maintenance`,
};
