import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { Accelerator, Calc, combineClasses, IS } from "@green24/js-utils";
import withInput from "./hoc/withInput";
import ButtonComponent from "./Button/ButtonComponent";

class NumberSpinner extends React.Component {
	constructor(props) {
		super(props);

		this._accelerator = new Accelerator(
			(speed) => this._handleAcceleratorUpdate(speed),
			{
				0: 1,
				5000: 10,
				10000: 100,
			}
		);
	}

	componentDidMount() {
		const {value} = this.props;

		this._modifyValue(value);
	}

	render({className, style, value, unit, prefix, suffix, step, max}) {
		return (
			<section className={combineClasses("number-spinner", className)} style={style}>
				{prefix}
				<ButtonComponent
					elevation
					icon={"minus"}
					onClick={() => this._modifyValue(value - step)}
					buttonProps={{
						onTouchStart: () => this._accelerate(-1),
						onTouchEnd: () => this._resetAcceleration(),
					}}
				/>

				<div className={"value"} style={{"--letter-width": (`${IS.valid(max) ? max :value}${unit}`.length * 0.7) + "em"}}>
					<span>{value || 0}</span>
					<span>{unit}</span>
				</div>

				<ButtonComponent
					elevation
					icon={"plus"}
					onClick={() => this._modifyValue(value + step)}
					buttonProps={{
						onTouchStart: () => this._accelerate(1),
						onTouchEnd: () => this._resetAcceleration(),
					}}
				/>
				{suffix}
			</section>
		);
	}

	_modifyValue(newValue) {
		const {onModify, min, max} = this.props;

		onModify(Calc.clamp(newValue, min, max));
	}

	_accelerate(direction) {
		this._direction = direction;
		const delay = 150;

		clearInterval(this._holdInterval);
		this._holdInterval = setInterval(() => {
			this._accelerator.accelerate(delay);
		}, delay);
	}

	_resetAcceleration() {
		clearInterval(this._holdInterval);
		this._accelerator.reset();

		this.props.onBlur();
	}

	_handleAcceleratorUpdate(speed) {
		const {step} = this.props;
		const {value} = this.props;

		this._modifyValue(value + this._direction * step * speed);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			unit: PropTypes.any,
			prefix: PropTypes.any,
			suffix: PropTypes.any,

			value: PropTypes.number,
			step: PropTypes.number,
			min: PropTypes.number,
			max: PropTypes.number,

			onModify: PropTypes.func,
			onBlur: PropTypes.func,
		}
	}

	static get defaultProps() {
		return {
			step: 1,
			onModify: (value, modificationType) => null,
			onBlur: () => null,
		}
	}
}

export default withInput(NumberSpinner, {idleDelay: 0});
