import { get, IS } from "@green24/js-utils";
import { AppUtils } from "./AppUtils";

export class InputUtils {
	static resolveValue(path, value, root) {
		if(IS.valid(value)) {
			return value;
		}

		return get(root, path);
	}

	static isDeveloperClick(e, disabled, callback) {
		if(
			AppUtils.isDev &&
			disabled &&
			e.ctrlKey
		) {
			IS.fnc(callback) && callback(e);
			return true;
		}
		return false;
	}
}
