import { APP_ROUTE } from "../../../shared/models/constants/AppConstants";
import { QS, QueryStringManager } from "@green24/js-utils";
import { E_OrderWizard_PaymentMethod } from "../../../shared/models/constants/Enums_OrderWizard";

export const E_AF_Order_Sections = {
	CONFIRM: "CONFIRM",
	DETAIL: "DETAIL",
	PAYMENT_SELECT: "PAYMENT_SELECT",
	PAYMENT_CASH_AMOUNT_SELECT: "PAYMENT_CASH_AMOUNT_SELECT",
	PAYMENT: "PAYMENT",
	RECEIPT: "RECEIPT",
	COMPLETE: "COMPLETE",
	VOUCHERS: "VOUCHERS",
};

/**
 * @readonly
 * @enum {String}
 */
export const AF_ROUTE = {
	...APP_ROUTE,

	LOCATION: `/location`,
	ORDER_WIZARD: (section = ":section*", query) => `/af/order/${section}${QS(query)}`,
	ORDER_WIZARD_CONFIRM: (boxID = ":boxID", query) => `${AF_ROUTE.ORDER_WIZARD(E_AF_Order_Sections.CONFIRM)}${new QueryStringManager({boxID, ...query}).toString()}`,
	ORDER_WIZARD_PAYMENT: (paymentMethod = ":paymentMethod") => `${AF_ROUTE.ORDER_WIZARD(E_AF_Order_Sections.PAYMENT)}/${paymentMethod}`,
	ORDER_WIZARD_PAYMENT_COMBINED: (cashAmount) => `${AF_ROUTE.ORDER_WIZARD_PAYMENT(E_OrderWizard_PaymentMethod.COMBINED)}${new QueryStringManager({cashAmount}).toString()}`,
}
