import { combineReducers } from "redux";
import { Reducer_Generic_Item } from "./Reducer_Generic_Item";
import { applicationActions, configurationActions, orderActions, locationActions } from "../actions/actions";
import { M_Application } from "../../models/Models_Application";

export default combineReducers({
	application: Reducer_Generic_Item(applicationActions.key, undefined, true, undefined, {edit: M_Application}),
	configuration: Reducer_Generic_Item(configurationActions.key, undefined, true, undefined, {fetching: true}),
	location: Reducer_Generic_Item(locationActions.key),
	order: Reducer_Generic_Item(orderActions.key),
});
