import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { E_Currency } from "../../models/constants/Enums_Tariff";

export const CurrencyContext = React.createContext({});

class CurrencyProvider extends React.Component {
	render({children, currency}) {
		return <CurrencyContext.Provider value={{
			currency
		}}>
			{children}
		</CurrencyContext.Provider>;
	}

	static get propTypes() {
		return {
			children: PropTypes.any,
			currency: PropTypes.oneOf(Object.values(E_Currency)),
		}
	}

	static get defaultProps() {
		return {
			currency: E_Currency.CZK
		}
	}
}

export default CurrencyProvider;
