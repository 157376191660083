export const E_GenericAction = {
	FETCH_PENDING: "FETCH_PENDING",
	FETCH_FULFILLED: "FETCH_FULFILLED",
	FETCH_REJECTED: "FETCH_REJECTED",
	BACKGROUND_FETCH_PENDING: "BACKGROUND_FETCH_PENDING",

	EDIT_INIT: "EDIT_INIT",
	EDIT_CANCEL: "EDIT_CANCEL",
	EDIT_MODIFY: "EDIT_MODIFY",
	EDIT_PENDING: "EDIT_PENDING",
	EDIT_FULFILLED: "EDIT_FULFILLED",
	EDIT_REJECTED: "EDIT_REJECTED",

	ITEM_CLEAR: "ITEM_CLEAR",
	CACHE_CLEAR: "CACHE_CLEAR",
};

export const Action_Generic_Type = (type) => {
	return {
		type
	}
};

export const Action_Generic_Payload = (type, payload) => {
	return {
		type,
		payload
	}
};

export const Action_Generic_Modification = (type, value, path, modificationType) => ({
	type,
	modification: {
		value,
		path,
		type: modificationType,
	},
});
