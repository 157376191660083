import { resolvePolymorphVar } from "@green24/js-utils";

export class RouteUtils {
	static matchRoute(target, location) {
		let pathnameParts = location.pathname.split('/');
		let targetParts = target.split('/');

		return pathnameParts.every((pathnamePart, i) => {
			let routePart = targetParts[i] || '';

			if(routePart.charAt(0) === ':') {
				return true;
			}
			return routePart === pathnamePart;
		});
	}

	static getParams(target, location) {
		let pathnameParts = location.pathname.split('/');
		let targetParts = target.split('/');
		let params = {};

		pathnameParts.forEach((pathnamePart, i) => {
			let routePart = targetParts[i] || '';

			if(routePart.charAt(0) === ':') {
				params[routePart.replace(/[:?]/g, '')] = pathnamePart;
			}
		});

		return params;
	}

	static isGraylisted(value, blacklist, whitelist, location) {
		blacklist = resolvePolymorphVar(
			blacklist,
			{
				string: s => [s],
				array: arr => arr,
			},
			[],
		);
		whitelist = resolvePolymorphVar(
			whitelist,
			{
				string: s => [s],
				array: arr => arr,
			},
		);

		let isBlacklisted = blacklist.some(item => RouteUtils.matchRoute(item, location));
		if(isBlacklisted) {
			return false;
		}
		return whitelist ? whitelist.some(item => RouteUtils.matchRoute(item, location)) : true;
	}
}
