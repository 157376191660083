import { cloneElement, Component, createContext } from 'preact';
import PropTypes from "prop-types";
import { ComponentUtils } from "../../utils/ComponentUtils";
import withPayment from "./hoc/withPayment";

export const PaymentContext = createContext();

class PaymentContextProvider extends Component {
	render({className, style, children, paymentInProgress, paid, startPayment, cancelPayment, paymentMethod, paymentData, cancelling, somethingWasReturned, cancelled}, {}) {
		const contextData = {
			paymentInProgress,
			paymentMethod,
			paid,
			startPayment,
			cancelPayment,
			paymentData,
			cancelling,
			somethingWasReturned,
			cancelled,
		};

		return (
			<PaymentContext.Provider value={contextData}>
				{
					ComponentUtils.resolvePolymorphProp(
						children,
						{
							component: c => cloneElement(c, {paymentContext: contextData}),
							function: f => f(contextData),
						},
						() => children,
					)
				}
			</PaymentContext.Provider>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			//Override to themeContext.getPaymentSelectRoute()
			paymentSelectRoute: PropTypes.string,

			boxID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		}
	}
}

export default withPayment(PaymentContextProvider);
