import { Action_Generic_Modification, Action_Generic_Payload, Action_Generic_Type, E_GenericAction } from "./genericActions";

export class StoreActions {
	constructor(key) {
		this._key = key;
	}

	get key() {
		return this._key;
	}

	static get enum() {
		return {
			action: E_GenericAction,
		};
	}

	getBackgroundFetchPending() {
		return Action_Generic_Type(`${this.key}_${E_GenericAction.BACKGROUND_FETCH_PENDING}`);
	}

	getFetchPending() {
		return Action_Generic_Type(`${this.key}_${E_GenericAction.FETCH_PENDING}`);
	}

	getFetchFulfilled(payload) {
		return Action_Generic_Payload(`${this.key}_${E_GenericAction.FETCH_FULFILLED}`, payload);
	}

	getFetchRejected(payload) {
		return Action_Generic_Payload(`${this.key}_${E_GenericAction.FETCH_REJECTED}`, payload);
	}

	getItemClear() {
		return Action_Generic_Type(`${this.key}_${E_GenericAction.ITEM_CLEAR}`);
	}

	getCacheClear() {
		return Action_Generic_Type(`${this.key}_${E_GenericAction.CACHE_CLEAR}`);
	}

	getEditCancel() {
		return Action_Generic_Type(`${this.key}_${E_GenericAction.EDIT_CANCEL}`);
	}

	getEditPending() {
		return Action_Generic_Type(`${this.key}_${E_GenericAction.EDIT_PENDING}`);
	}

	getEditInit(payload) {
		return Action_Generic_Payload(`${this.key}_${E_GenericAction.EDIT_INIT}`, payload);
	}

	getEditFulfilled(payload) {
		return Action_Generic_Payload(`${this.key}_${E_GenericAction.EDIT_FULFILLED}`, payload);
	}

	getEditRejected(payload) {
		return Action_Generic_Payload(`${this.key}_${E_GenericAction.EDIT_REJECTED}`, payload);
	}

	getEditModify(value, path, modificationType) {
		return Action_Generic_Modification(`${this.key}_${E_GenericAction.EDIT_MODIFY}`, value, path, modificationType);
	}

	generateAsyncFetchActions(background = false) {
		if(background) return this.generateAsyncBackgroundFetchActions();

		return [
			(...props) => this.getFetchPending(...props),
			(...props) => this.getFetchFulfilled(...props),
			(...props) => this.getFetchRejected(...props),
		];
	}

	generateAsyncBackgroundFetchActions() {
		return [
			(...props) => this.getBackgroundFetchPending(...props),
			(...props) => this.getFetchFulfilled(...props),
			(...props) => this.getFetchRejected(...props),
		];
	}

	generateAsyncSendEditActions() {
		return [
			(...props) => this.getEditPending(...props),
			(...props) => this.getEditFulfilled(...props),
			(...props) => this.getEditRejected(...props),
		];
	}
}
