import { ArrayUtils, uID } from "@green24/js-utils";

export class EventListeners {
	constructor() {
		this._listeners = [];
	}

	/**
	 * Add
	 * ---
	 * @param {string} eventName
	 * @param {function(Event)} callback
	 * @param {HTMLElement} target
	 * @param {boolean|AddEventListenerOptions} options
	 * @return {data.remove}
	 */
	add(target, eventName, callback, options) {
		if(target) {
			const id = uID();
			const listener = e => callback(e);

			target.addEventListener(eventName, listener, options);
			const remove = () => {
				target.removeEventListener(eventName, listener, options);
				ArrayUtils.removeID(this._listeners, id, false);
			};

			this._listeners.push({
				id,
				eventName,
				options,
				remove,
			});

			return remove;
		}

		console.error("Target is undefined for the event:", eventName, options);
		return null;
	}

	/**
	 * Remove for target
	 * ---
	 * @param {HTMLElement} target
	 */
	removeForTarget(target) {
		this._listeners.forEach(listener => {
			if(listener.target == target) {
				listener.remove();
			}
		});
	}

	/**
	 * Remove for event
	 * ---
	 * @param {string} eventName
	 */
	removeForEvent(eventName) {
		this._listeners.forEach(listener => {
			if(listener.eventName == eventName) {
				listener.remove();
			}
		});
	}

	clear() {
		this._listeners.forEach(listener => listener.remove());
	}
}
