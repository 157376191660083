import { Component, Fragment } from 'preact';
import PropTypes from "prop-types";
import { combineClasses, get } from "@green24/js-utils";
import ButtonsConstructor from "../../components/Input/Button/ButtonsConstructor";
import { E_MaintenanceAction } from "../../models/constants/Enums_Maintenance";
import ButtonComponent from "../../components/Input/Button/ButtonComponent";
import root from "window-or-global";
import { E_Keyboard_Type } from "../../models/constants/Enums_Keyboard";
import Interpret from "../../components/Localization/Interpret";
import Price from "../../components/Price/Price";
import { boxMaintenanceServices, hasamMaintenanceServices } from "../../services/maintenance-services";
import Loading from "../../components/Loading";
import { ErrorUtils } from "../../utils/ErrorUtils";
import CurrencyProvider from "../../components/Price/CurrencyProvider";
import MessageModal from "../../components/Modal/Instances/MessageModal";
import MaintenanceContentMoveSome from "./MaintenanceContentMoveSome";
import MaintenanceActionFillingProcessModal from "./MaintenanceActionFillingProcessModal";

const wrapWithConfirm = (action, actionName) => {
	return (...args) => window.modal.open(
		<MessageModal
			title={actionName}
			message={"action.generic.confirm.message"}

			actionButtons={[
				{
					text: "yes",
					action: () => {
						window.modal.close();
						action(...args);
					},
				}
			]}
		/>
	)
}

class MaintenanceActions extends Component {
	constructor(props) {
		super();

		this.state = {
			...this._getInitialActionStates(props, E_MaintenanceAction.DEVICES_STATUS),
			executing: false,
			selectedAction: E_MaintenanceAction.DEVICES_STATUS,
		}
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		if(previousState.selectedAction != this.state.selectedAction) {
			root.keyboard.close();
		}
	}

	_renderDevicesSelection() {
		const {devices} = this.props;
		const {selectedAction, selectedDevice, executing} = this.state;

		let relevantDevices = this._getAvailableDevices(devices, selectedAction);

		return (
			<div className={"devices-selection"}>
				<ButtonsConstructor buttons={relevantDevices.map(device => {
					return {
						text: (
							<span>
								<Interpret id={`device.type.${device.deviceType}`} fallback={device.deviceType}/> (#{device.deviceID})
							</span>
						),
						active: device.deviceID == get(selectedDevice, "deviceID", Symbol()),
						disabled: executing,
						action: () => this.setState({selectedDevice: device}),
					}
				})}/>
			</div>
		)
	}

	_renderDeviceContentItem(item) {
		return (
			<>
				<span className={"content-item"}>
					<Price value={item.value} currency={item.currency}/>
				</span>
				<span>{item.count}x</span>
				<span>{item.countReal}x</span>
				<span><Price value={item.totalValue} currency={item.currency}/></span>
			</>
		)
	}

	_renderDevice(device) {
		return (
			<div className={"device"}>
				<h2>
					<span className={"device-id"}>#{device.deviceID}</span>
					<small><Interpret id={`device.type.${device.deviceType}`} fallback={device.deviceType}/></small>

					<b>(<Price value={device.total}/>)</b>
				</h2>

				<h4><Interpret id={"dispensable"}/></h4>
				<div className={"grid grid-table-device-contents"}>
					<span/>
					<label><Interpret id={"count"}/></label>
					<label><Interpret id={"countReal"}/></label>
					<label><Interpret id={"totalValue"}/></label>

					{
						(device.dispensable || []).map(item => {
							return (
								<Fragment key={item.value + item.currency}>
									{this._renderDeviceContentItem(item)}
								</Fragment>
							);
						})
					}

					<div className={"price-total"}>
						<Interpret id={"priceTotal"}/>:&nbsp;
						<b><Price value={device.dispensableTotal || 0}/></b>
					</div>
				</div>


				<h4><Interpret id={"maintenanceDeviceContentsCashBox"}/></h4>
				<div className={"grid grid-table-device-contents"}>
					<span/>
					<label><Interpret id={"count"}/></label>
					<label><Interpret id={"countReal"}/></label>
					<label><Interpret id={"totalValue"}/></label>

					{
						(device.cashBox || []).map(item => {
							return (
								<Fragment key={item.value + item.currency}>
									{this._renderDeviceContentItem(item)}
								</Fragment>
							);
						})
					}

					<div className={"price-total"}>
						<Interpret id={"priceTotal"}/>:&nbsp;
						<b><Price value={device.cashBoxTotal || 0}/></b>
					</div>
				</div>
			</div>
		)
	}

	_renderContentForAction(action) {
		const {devicesStatus} = this.props;
		const {response, executing, selectedDevice} = this.state;

		switch (action) {
			case E_MaintenanceAction.DEVICES_STATUS:
				if(!devicesStatus) return null;

				return (
					<div className={"devices-status"}>
						<div className={"grid"}>
							<span><Interpret id={"status"}/>:</span>
							<span>{devicesStatus.statusId}</span>
						</div>

						<hr/>

						<div>
							<label><Interpret id={"devices"}/></label>
							<div className={"devices"}>
								{devicesStatus.devices.map((device, i) => {
									return (
										<Fragment key={device.deviceID}>
											<div className={"device grid"}>
												<label><Interpret id={"deviceID"}/></label>
												<span>{device.deviceID}</span>

												<label><Interpret id={"deviceType"}/></label>
												<span><Interpret id={`device.type.${device.deviceType}`} fallback={device.deviceType}/></span>

												<label><Interpret id={"communicationOk"}/></label>
												<span><Interpret id={device.communicationOk ? "yes" : "no"}/></span>

												<label><Interpret id={"jamAccept"}/></label>
												<span><Interpret id={device.jamAccept ? "yes" : "no"}/></span>

												<label><Interpret id={"jamReturn"}/></label>
												<span><Interpret id={device.jamReturn ? "yes" : "no"}/></span>

												<label><Interpret id={"lastError"}/></label>
												<span>{device.lastError || '-'}</span>
											</div>
										</Fragment>
									);
								})}
							</div>
						</div>


						{
							devicesStatus.lastError != "Ok" &&
							<>
								<hr/>

								<div className={"grid"}>
									<span><Interpret id={"lastError"}/>:</span>
									<span>{devicesStatus.lastError || '-'}</span>
								</div>
							</>
						}
					</div>
				);
			case E_MaintenanceAction.QUICK_ACTIONS:
				return (
					<div className={"quick-actions"}>
						<ButtonsConstructor buttons={[
							{
								text: "stopProcess",
								action: wrapWithConfirm(() => this._execute(hasamMaintenanceServices.stopFillingOrPaymentProcess()), "stopProcess"),
								disabled: executing,
							},
							{
								text: "resetDevice",
								action: wrapWithConfirm(() => this._execute(hasamMaintenanceServices.resetDevice()), "resetDevice"),
								disabled: executing,
							},
							{
								text: "printTestTicket",
								action: wrapWithConfirm(() => this._execute(hasamMaintenanceServices.printTestTicket()), "printTestTicket"),
								disabled: executing,
							},
							{
								text: "emergencyOpen",
								action: () => this._openEmergencyOpenConfirm(),
							}
						]}/>
					</div>
				)
			case E_MaintenanceAction.MANUAL_PAYMENT:
				return (
					<div className={"manual-payment"}>
						<p className={"description"}><Interpret id={"manualPayment.description"}/></p>

						<h1><Interpret id={"cost"}/></h1>
						<ButtonComponent className={"cost"} onClick={() => this._openCostKeyboard()} text={`${this.state.cost} Kč`} textTranslated={true}/>
					</div>
				);
			case E_MaintenanceAction.MOVE_CASH_TO_CASHBOX:
			case E_MaintenanceAction.RESET_CASHBOX_COUNTER:
				if(!response) {
					if(action == E_MaintenanceAction.MOVE_CASH_TO_CASHBOX) {
						return (
							<div className={"clear-cashbox-counter"}>
								<p className={"description"}><Interpret id={"maintenance.action.MOVE_CAS_TO_CASHBOX.description"}/></p>
							</div>
						)
					}
					else {
						return (
							<div className={"clear-cashbox-counter"}>
								<p className={"description"}><Interpret id={"maintenance.action.RESET_CASHBOX_COUNTER.description"}/></p>
							</div>
						)
					}

					return null;
				}

				return (
					<div className={"clear-cashbox-counter"}>
						<div className={"grid"}>
							<label><Interpret id={"type"}/></label>
							<span>{response.type}</span>

							<label><Interpret id={"amount"}/></label>
							<span>{response.amount}</span>

							<label><Interpret id={"totalAccepted"}/></label>
							<span>{response.totalAccepted}</span>

							<label><Interpret id={"totalReturned"}/></label>
							<span>{response.totalReturned}</span>

							<label><Interpret id={"leftToAccept"}/></label>
							<span>{response.leftToAccept}</span>

							<label><Interpret id={"leftToReturn"}/></label>
							<span>{response.leftToReturn}</span>

							<label><Interpret id={"errorMessage"}/></label>
							<span>{response.errorMessage}</span>
						</div>
					</div>
				);
			case E_MaintenanceAction.MOVE_SOME_CASH_TO_CASHBOX:
				if(!response) return (
					<div className={"clear-cashbox-counter"}>
						<p className={"description"}><Interpret id={"maintenance.action.MOVE_CAS_TO_CASHBOX.description"}/></p>
					</div>
				);

				return (
					<MaintenanceContentMoveSome
						data={response}
						selectedDevice={selectedDevice}
						onMoveSome={wrapWithConfirm(
							(mustRemain) => this._startAction(E_MaintenanceAction.MOVE_SOME_CASH_TO_CASHBOX, {mustRemain}),
							"moveSomeCashToCashBox"
						)}
					/>
				)
			case E_MaintenanceAction.GET_DEVICES_CONTENTS:
				if(!response) return null;

				return (
					<CurrencyProvider currency={response.currency}>
						<div className={"devices-contents"}>
							<div className={"price-total"}>
								<Interpret id={"priceTotal"}/>:&nbsp;
								<b><Price value={response.total}/></b>
							</div>

							<hr/>

							{
								(response.deviceCash || []).map(device => {
									return (
										<Fragment key={device.deviceID}>
											{this._renderDevice(device)}

											<hr/>
										</Fragment>
									);
								})
							}
						</div>
					</CurrencyProvider>
				);
			case E_MaintenanceAction.INITIATE_FILLING_PROCESS:
				return (
					<p className={"description"}><Interpret id={"maintenance.action.INITIATE_FILLINT_PROCESS.description"}/></p>
				)
			case E_MaintenanceAction.RESET_COIN_COUNTER:
				return (
					<p className={"description"}><Interpret id={"maintenance.action.RESET_COIN_COUNTER.description"}/></p>
				)
		}
	}

	render({className, style, devices}, {selectedAction, selectedDevice, executing}) {
		return (
			<section className={combineClasses("maintenance-actions", className)} style={style} data-selected-action={selectedAction}>
				<div className={"actions"}>
					<ButtonsConstructor buttons={[
						{
							key: E_MaintenanceAction.DEVICES_STATUS,
							title: "devicesStatus",
						},
						{
							key: E_MaintenanceAction.QUICK_ACTIONS,
							title: "actions",
						},
						{
							key: E_MaintenanceAction.MANUAL_PAYMENT,
							title: "manualPayment",
						},
						{
							key: E_MaintenanceAction.MOVE_CASH_TO_CASHBOX,
							title: "moveCashToCashBox",
						},
						{
							key: E_MaintenanceAction.MOVE_SOME_CASH_TO_CASHBOX,
							title: "moveSomeCashToCashBox",
						},
						{
							key: E_MaintenanceAction.INITIATE_FILLING_PROCESS,
							title: "fillingProcess",
						},
						{
							key: E_MaintenanceAction.RESET_CASHBOX_COUNTER,
							title: "resetCashBoxCounter",
						},
						{
							key: E_MaintenanceAction.RESET_COIN_COUNTER,
							title: "resetCoinBoxCounter",
						},
						{
							key: E_MaintenanceAction.GET_DEVICES_CONTENTS,
							title: "paymentDevicesContents",//TBD: This is probably not a method of the device...
						},
					].map(action => {
						return {
							text: action.title,
							action: () => this._changeSelectedAction(action.key),
							active: selectedAction == action.key,
							disabled: executing,
						}
					})}/>
				</div>

				{
					[
						E_MaintenanceAction.MOVE_CASH_TO_CASHBOX,
						E_MaintenanceAction.MOVE_SOME_CASH_TO_CASHBOX,
						E_MaintenanceAction.INITIATE_FILLING_PROCESS,
						E_MaintenanceAction.RESET_CASHBOX_COUNTER,
						E_MaintenanceAction.RESET_COIN_COUNTER,
					].includes(selectedAction) &&
					this._renderDevicesSelection()
				}

				<div className={"action-context"}>
					<div className={"content"}>
						{
							executing &&
							<Loading title={"executing"} className={"with-background"} style={{zIndex: 1}}/>
						}
						{this._renderContentForAction(selectedAction)}
					</div>

					<nav className={"action-buttons"}>
						<ButtonsConstructor buttons={this._getActionButtonsForAction(selectedAction)}/>
					</nav>
				</div>
			</section>
		);
	}

	_getInitialActionStates(props = this.props, action) {
		return {
			selectedDevice: this._getAvailableDevices(props.devices, action)[0],
			devicesStatus: props.devicesStatus,
			cost: 0,
			response: null,
		}
	}

	_changeSelectedAction(action) {
		const {devices} = this.props;

		this.setState({
			...this._getInitialActionStates({devices}, action),
			selectedAction: action,
		});
	}

	_getActionButtonsForAction(action) {
		const {executing, response} = this.state;

		switch (action) {
			case E_MaintenanceAction.DEVICES_STATUS:
				return [
					{
						text: "refresh",
						action: () => this._startAction(E_MaintenanceAction.DEVICES_STATUS),
						disabled: executing,
					},
				];
			case E_MaintenanceAction.MANUAL_PAYMENT:
				return [
					{
						text: "start",
						action: () => this._startAction(E_MaintenanceAction.MANUAL_PAYMENT),
						disabled: executing,
					},
					{
						text: "stop",
						action: () => this._execute(hasamMaintenanceServices.stopFillingOrPaymentProcess(), true),
					},
				];
			case E_MaintenanceAction.MOVE_CASH_TO_CASHBOX:
				return [
					{
						text: "move",
						action: wrapWithConfirm(() => this._startAction(E_MaintenanceAction.MOVE_CASH_TO_CASHBOX), "moveCashToCashBox"),
						disabled: executing,
					},
				];
			case E_MaintenanceAction.MOVE_SOME_CASH_TO_CASHBOX:
				return [
					!response &&
					{
						text: "continue",
						action: () => this._execute(hasamMaintenanceServices.getDevicesContents()).then(contents => {
							this.setState({
								response: contents,
							});
						}),
						disabled: executing,
					},
				];
			case E_MaintenanceAction.INITIATE_FILLING_PROCESS:
				return [
					{
						text: "start",
						action: () => this._startAction(E_MaintenanceAction.INITIATE_FILLING_PROCESS),
						disabled: executing,
					},
					{
						text: "stop",
						action: () => this._execute(hasamMaintenanceServices.stopFillingOrPaymentProcess(), true),
					},
				];
			case E_MaintenanceAction.RESET_COIN_COUNTER:
				return [
					{
						text: "clear",
						action: wrapWithConfirm(() => this._startAction(action), "resetCoinBoxCounter"),
						disabled: executing,
					},
				];
			case E_MaintenanceAction.RESET_CASHBOX_COUNTER:
				return [
					{
						text: "clear",
						action: wrapWithConfirm(() => this._startAction(action), "resetCashBoxCounter"),
						disabled: executing,
					},
				];
			case E_MaintenanceAction.GET_DEVICES_CONTENTS:
				return [
					{
						text: "print",
						action: () => this._execute(hasamMaintenanceServices.printDeviceContents()),
					},
					{
						text: "load",
						action: () => this._startAction(E_MaintenanceAction.GET_DEVICES_CONTENTS),
						disabled: executing,
					},
				];
			default:
				return [];
		}
	}

	_openCostKeyboard() {
		root.keyboard.open(E_Keyboard_Type.NUMERIC, this.state.cost, undefined, (cost) => this.setState({cost: cost || 0}));
	}

	_getServiceForAction(action, ...actionParams) {
		const {cost, selectedDevice} = this.state;

		switch (action) {
			case E_MaintenanceAction.DEVICES_STATUS:
				return hasamMaintenanceServices.getDevicesStatus();
			case E_MaintenanceAction.MANUAL_PAYMENT:
				return hasamMaintenanceServices.initiateManualPayment(cost);
			case E_MaintenanceAction.MOVE_CASH_TO_CASHBOX:
				return hasamMaintenanceServices.moveCash(selectedDevice.deviceID);
			case E_MaintenanceAction.MOVE_SOME_CASH_TO_CASHBOX:
				return hasamMaintenanceServices.moveSomeCash(selectedDevice.deviceID, ...actionParams);
			case E_MaintenanceAction.INITIATE_FILLING_PROCESS:
				return hasamMaintenanceServices.initiateFillProcess(selectedDevice.deviceID);
			case E_MaintenanceAction.RESET_CASHBOX_COUNTER:
				return hasamMaintenanceServices.clearDeviceCounter(selectedDevice.deviceID);
			case E_MaintenanceAction.RESET_COIN_COUNTER:
				return hasamMaintenanceServices.clearCoinDeviceCounter(selectedDevice.deviceID);
			case E_MaintenanceAction.GET_DEVICES_CONTENTS:
				return hasamMaintenanceServices.getDevicesContents();
		}
	}

	_startAction(action, ...actionParams) {
		this._execute(this._getServiceForAction(action, ...actionParams)).then((response) => {
			this.setState({response});

			switch (action) {
				case E_MaintenanceAction.DEVICES_STATUS:
					this.props.onDevicesStatus(response);
					break;
				case E_MaintenanceAction.MOVE_SOME_CASH_TO_CASHBOX:
					this.setState({response: null});
				// eslint-disable-next-line no-fallthrough
				case E_MaintenanceAction.MOVE_CASH_TO_CASHBOX:
					this._openResetCounterWarningPopup();
					break;
				case E_MaintenanceAction.INITIATE_FILLING_PROCESS:
					this._openFillingProcessPopup();
					break;
			}
		});
	}

	_execute(servicePromise, onlyExecute = false) {
		!onlyExecute && this.setState({executing: true});

		return new Promise((resolve, reject) => {
			servicePromise.then(
				res => resolve(res),
				res => {
					//TODO: Handle error
					if(res.error === "POPUP_ERROR") {
						ErrorUtils.handlePopupErrorResponse(res);
					}
					reject(res);
				}
			).finally(() => {
				!onlyExecute && this.setState({executing: false});
			});
		})
	}

	_openEmergencyOpenConfirm() {
		root.modal.open(
			<MessageModal
				title={"emergencyOpen"}
				message={"emergencyOpenMessage"}
				closeButtonProps={{text: "no"}}
				actionButtons={[
					{
						text: "open",
						action: () => {
							this._emergencyOpen();
							root.modal.close();
						}
					}
				]}
			/>
		)
	}

	_emergencyOpen() {
		return this._execute(boxMaintenanceServices.emergencyOpen());
	}

	_openResetCounterWarningPopup() {
		root.modal.open(
			<MessageModal
				title={"moveCashResetCounterReminder"}
				closeButtonProps={{text: "ok"}}
			/>
		)
	}

	_getAvailableDevices(devices, action) {
		switch (action) {
			case E_MaintenanceAction.MOVE_CASH_TO_CASHBOX:
			case E_MaintenanceAction.MOVE_SOME_CASH_TO_CASHBOX:
				return devices.filter(device => device.deviceType == "SmartPayout");
			case E_MaintenanceAction.RESET_COIN_COUNTER:
				return devices.filter(device => device.deviceType == "CoinChanger")
			default:
				return devices || [];
		}
	}

	_openFillingProcessPopup() {
		window.modal.open(
			<MaintenanceActionFillingProcessModal/>
		)
	}

	static get sharedTypes() {
		return {
			devicesStatus: PropTypes.object,
		}
	}

	static get propTypes() {
		return {
			...this.sharedTypes,

			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			devices: PropTypes.array,

			onDevicesStatus: PropTypes.func.isRequired,
		}
	}

	static get stateTypes() {
		return {
			...this.sharedTypes,

			selectedAction: PropTypes.oneOf(Object.values(E_MaintenanceAction)),
			selectedDevice: PropTypes.object,
			response: PropTypes.any,
			cost: PropTypes.number,
		}
	}
}

export default MaintenanceActions;
