import { Component } from 'preact';
import PropTypes from "prop-types";
import { combineClasses } from "@green24/js-utils";
import Keyboard from "../other/Keyboard/Keyboard";
import WidestContent from "../WidestContent";
import { E_Keyboard_Type } from "../../models/constants/Enums_Keyboard";

class PinInput extends Component {
	componentDidUpdate(previousProps, previousState, snapshot) {
		const {value, maxLength, autoSubmit, onSubmit} = this.props;

		if(autoSubmit && previousProps.value != value && value.length == maxLength) {
			onSubmit(value);
		}
	}

	render({className, style, value, onModify, maxLength, keyboardProps, placeholderCharacter, children, onSubmit}, {}) {
		return (
			<section className={combineClasses("pin-input", className)} style={style}>
				<div className={"pin-field"}>
					{(
						value + Array(Math.max(maxLength - `${value}`.length, 0)).fill(placeholderCharacter).join('')
					).split('').map((char, i) => {
						return (
							<WidestContent className={char == placeholderCharacter ? "placeholder" : ''} key={i}>
								<span visible={char == placeholderCharacter} style={{fontWeight: 300}}>
									{placeholderCharacter}
								</span>

								{Array(10).fill(undefined).map((_, j) => <span key={j} visible={char == j}>{j}</span>)}
							</WidestContent>
						)
					})}
				</div>

				<Keyboard
					block={true}
					type={E_Keyboard_Type.EMAIL}
					view={E_Keyboard_Type.NUMERIC}
					valueDisplay={false}
					value={value}

					onModify={v => onModify(v)}
					onSubmit={() => onSubmit(value)}

					{...keyboardProps}
					options={{
						...keyboardProps.options,
						maxLength
					}}
				/>

				{children}
			</section>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			value: PropTypes.string,
			onModify: PropTypes.func.isRequired,
			maxLength: PropTypes.number.isRequired,

			keyboardProps: PropTypes.object,

			placeholderCharacter: PropTypes.string,

			//Auto-submit when the last character is entered
			autoSubmit: PropTypes.bool,

			//Called when ENTER is pressed
			onSubmit: PropTypes.func,
		}
	}

	static get defaultProps() {
		return {
			value: '',
			placeholderCharacter: 'X',
			maxLength: 10,
			keyboardProps: {},
			onSubmit: () => null,
			autoSubmit: false,
		}
	}
}

export default PinInput;
