import { KVElektroGenericServices } from "./api-connector";
import { QueryStringManager, ResponseHandler } from "@green24/js-utils";
import { E_OrderWizard_PaymentMethod } from "../models/constants/Enums_OrderWizard";

class OrderPaymentServices extends KVElektroGenericServices {
	getCardTerminalSocketUrl(orderID) {
		return `/kv${this.url}/${orderID}/payment/terminal`;
	}

	getCashTerminalSocketUrl(orderID) {
		return `/kv${this.url}/${orderID}/payment/cash`;
	}

	initCardTerminal(orderID, cost, language = "cs") {
		return this.sendRequest({
			url: `${this.url}/${orderID}/payment/terminal${new QueryStringManager({
				cost,
				language,
			}).toValidDataString()}`,
			method: "POST",
		}, ResponseHandler.plain);
	}

	cancelCardPayment(orderID) {
		return this.sendRequest({
			url: `${this.url}/${orderID}/payment/terminal`,
			method: "DELETE",
		}, ResponseHandler.plain);
	}

	initCashTerminal(orderID, cost, language = "cs") {
		return this.sendRequest({
			url: `${this.url}/${orderID}/payment/cash${new QueryStringManager({
				cost,
				language,
			}).toValidDataString()}`,
			method: "POST",
		}, ResponseHandler.plain);
	}

	cancelCashPayment(orderID) {
		return this.sendRequest({
			url: `${this.url}/${orderID}/payment/cash`,
			method: "DELETE",
		}, ResponseHandler.plain);
	}

	cancelPayment(paymentMethod, orderID) {
		switch (paymentMethod) {
			case E_OrderWizard_PaymentMethod.CARD:
				return this.cancelCardPayment(orderID);
			case E_OrderWizard_PaymentMethod.CASH:
				return this.cancelCashPayment(orderID);
			case E_OrderWizard_PaymentMethod.COMBINED:
				return Promise.all([
					this.cancelCashPayment(orderID),
					this.cancelCardPayment(orderID)
				]);
		}
	}
}

export const orderPaymentServices = new OrderPaymentServices(`/order`);
