import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { combineClasses, Jiffy } from "@green24/js-utils";
import Fa from "../../Fa";
import Interpret from "../../Localization/Interpret";
import { ComponentUtils } from "../../../utils/ComponentUtils";

class Toast extends React.Component {
	componentDidMount() {
		const {lifespan} = this.props.data;
		const timeoutDuration = Jiffy.formatToMs(lifespan);

		if(timeoutDuration > -1) {
			this._timeout = setTimeout(() => this._close(), timeoutDuration);
		}
	}

	render({className, style, accent, data}) {
		return (
			<section
				className={combineClasses(
					"toast",
					accent,
					className,
				)}
				style={style}

				onClick={() => this._close()}
			>
				{data.icon && <Fa icon={data.icon}/>}

				<div className={"toast-content"}>
					{
						data.title &&
						ComponentUtils.resolvePolymorphProp(
							data.title,
							{
								component: c => c,
								string: s => <h3>{data.titleTranslated ? s : <Interpret id={s}/>}</h3>,
							},
							() => <h3>{data.title}</h3>,
						)
					}
					{
						data.message &&
						ComponentUtils.resolvePolymorphProp(
							data.message,
							{
								component: c => c,
								string: s => <p>{data.messageTranslated ? s : <Interpret id={s}/>}</p>,
							},
							() => <p>{data.message}</p>,
						)
					}
				</div>
			</section>
		);
	}

	_close() {
		const {onClose} = this.props;

		clearTimeout(this._timeout);
		onClose();
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			accent: PropTypes.string,
			data: PropTypes.object,

			onClose: PropTypes.func.isRequired,
		}
	}
}

export default Toast;
