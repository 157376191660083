import root from "window-or-global";
import MessageModal from "../components/Modal/Instances/MessageModal";

export class ErrorUtils {
	static getErrorFromResponse(response, errorOverride = {}, dataOverride) {
		if(!response) return null;

		const __getData = (res) => {
			switch (res.statusCode) {
				case 400: //Bad Request
					return {
						icon: "construction",
						title: res.message,
						titleTranslated: true,
						retry: false,
					};
				case 404: //Not found
					return {
						icon: "telescope",
						title: "error_network_notFound",
						retry: false,
					};
				case 408: //Timed out
					return {
						icon: "stopwatch",
						title: "error_network_connectionTimedOut",
						retry: true,
					};
				case 500: //Server error
				case 501:
				case 502:
				case 503:
					return {
						icon: "server",
						title: "error_network_serverError",
						retry: true,
					};
				default: //Other
					return {
						icon: "exclamation-triangle",
						title: "error_unexpectedError",
						retry: true,
					};
			}
		}

		return {
			...__getData(response),
			...errorOverride[response.statusCode],
			...dataOverride,
		}
	}

	static handlePopupErrorResponse(res, props) {
		const {message} = res;
		/** @type {GlobalModal} */
		const modal = root.modal;

		modal.open(
			<MessageModal
				message={message}
				messageTranslated={true}
				{...props}
			/>
		);
	}

	static handleUnexpectedError(res) {
		const error = this.getErrorFromResponse(res);
		/** @type {GlobalModal} */
		const modal = root.modal;

		modal.open(
			<MessageModal
				title={error.title}
				titleTranslated={error.titleTranslated}
			/>
		);
	}
}
