/**
 * @deprecated
 */
export const M_MsToDuration_DefaultOptions = {
	returnString: true,
	hideEmpty: true,
	showSec: true,
	daysPadding: 1,
	daysSuffix: 'd',
	hoursPadding: 1,
	hoursSuffix: 'h',
	minutesPadding: 1,
	minutesSuffix: 'm',
	secondsPadding: 1,
	secondsSuffix: 's',
};

export const M_ClearInvalid_DefaultOptions = {
	null: true,
	undefined: true,
	emptyString: true,
	false: false,
};
