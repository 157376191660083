import configureStore from './storeConfiguration';
import { Thunk_Application } from "./thunk/Thunk_Application";
import { Thunk_AF_Configuration } from "../../AF/store/thunk/Thunk_AF_Configuration";
import { Thunk_Location } from "../../AF/store/thunk/Thunk_Location";
import { Thunk_AF_Order } from "../../AF/store/thunk/Thunk_AF_Order";
import { Thunk_AFN_Order } from "../../AFN/store/thunk/Thunk_AFN_Order";
import { Thunk_AFN_Configuration } from "../../AFN/store/thunk/Thunk_AFN_Configuration";
import { Thunk_AFE_Configuration } from "../../AFE/store/thunk/Thunk_AFE_Configuration";
import { Thunk_AFE_Order } from "../../AFE/store/thunk/Thunk_AFE_Order";

const store = configureStore();

//Shared

export const locationThunk = new Thunk_Location(store);
export const applicationThunk = new Thunk_Application(store);

//AF

export const afConfigurationThunk = new Thunk_AF_Configuration(store);
export const afOrderThunk = new Thunk_AF_Order(store);

//AFN

export const afnConfigurationThunk = new Thunk_AFN_Configuration(store);
export const afnOrderThunk = new Thunk_AFN_Order(store);

//AFE

export const afeConfigurationThunk = new Thunk_AFE_Configuration(store);
export const afeOrderThunk = new Thunk_AFE_Order(store);

export default store;
