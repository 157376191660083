import { IS } from "./IS";
import { get, resolvePolymorphVar } from "../functions/generic";

export class TextTransform {
	static uppercase(str) {
		if(IS.string(str)) {
			return str.toUpperCase();
		}
		console.warn("Provided value is not of type 'string'", str);
		return str;
	}

	static lowercase(str) {
		if(IS.string(str)) {
			return str.toLowerCase();
		}
		console.warn("Provided value is not of type 'string'", str);
		return str;
	}

	static capitalize(str) {
		if(IS.string(str)) {
			return str.charAt(0).toUpperCase() + str.slice(1);
		}
		console.warn("Provided value is not of type 'string'", str);
		return str;
	};

	/**
	 * Process - Replace variables
	 * ---
	 * Replaces path pointers in string with provided data or solved by passed solver
	 * @param {String} str
	 * @param {Object|function(String)|Array|string} dataOrSolver
	 * @param {String} variableOpeningIdentifier Variable start/end identifier string (default '%') used as "%{variableName}%"
	 * @param {String} variableClosingIdentifier Variable start/end identifier string (default '%') used as "%{variableName}%"
	 * @return {String}
	 */
	static replaceVariables(str, dataOrSolver, variableOpeningIdentifier = "%{", variableClosingIdentifier = "}%") {
		if(str) {
			return str.replace(new RegExp(variableOpeningIdentifier + `.*?` + variableClosingIdentifier, 'g'), (substring) => {
				let path = substring.replace(variableOpeningIdentifier, '').replace(variableClosingIdentifier, '');

				return resolvePolymorphVar(
					dataOrSolver,
					{
						function: f => f(path),
						object: o => get(o, path, substring),
						array: arr => arr[path],
					},
					IS.valid(dataOrSolver) ? dataOrSolver : substring,
				);
			});
		}
		return str;
	};
}
