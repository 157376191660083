import { StoreThunk } from "../../../shared/store/thunk/StoreThunk";
import { afBoxServices } from "../../services/af-box-services";
import { orderActions } from "../../../shared/store/actions/actions";

export class Thunk_AF_Order extends StoreThunk {
	set(data) {
		this.dispatch(orderActions.getFetchFulfilled(data));
	}

	findFromBoxID(boxID) {
		this.dispatch(orderActions.getFetchPending());

		return new Promise((resolve, reject) => {
			afBoxServices.getOrderForBox(boxID).then(order => {
				this.dispatch(orderActions.getFetchFulfilled(order));

				resolve(order);
			}, res => {
				this.dispatch(orderActions.getFetchRejected(res));

				reject(res);
			});
		});
	}

	clear() {
		this.dispatch(orderActions.getItemClear());
	}
}
