import React from "react";
// eslint-disable-next-line no-unused-vars
import PropTypes from "prop-types";
import { E_Currency } from "../../models/constants/Enums_Tariff";
import { TranslationContext } from "../Localization/TranslationProvider";
import { CurrencyContext } from "./CurrencyProvider";
import { FormatUtils } from "../../utils/FormatUtils";
import { IS } from "@green24/js-utils";

class Price extends React.Component {
	render({currency: propsCurrency, value, locale: localeOverride, options, fractionDigits}) {
		return (
			<CurrencyContext.Consumer>
				{({currency: contextCurrency}) => {
					const currency = propsCurrency || contextCurrency;

					return (
						<TranslationContext.Consumer>
							{(translationContext) => FormatUtils.formatPrice(
								value,
								localeOverride || translationContext.getActiveLanguageISOCode(),
								currency,
								{
									maximumFractionDigits: (IS.number(fractionDigits) || !!fractionDigits) ? fractionDigits : 0,
									...options,
								},
							)}
						</TranslationContext.Consumer>
					)
				}}
			</CurrencyContext.Consumer>
		);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			value: PropTypes.number,
			currency: PropTypes.oneOf(Object.values(E_Currency)),
			locale: PropTypes.string,
			/**
			 * @type NumberFormatOptions
			 */
			options: PropTypes.object,
			fractionDigits: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
		}
	}

	static get defaultProps() {
		return {
			value: 0,
			currency: E_Currency.CZK,
			options: {},
			fractionDigits: 2,
		}
	}
}

export default Price;
