import React from "react";
import PropTypes from "prop-types";
import { Component } from "preact";
import { route } from "preact-router";
import { Debouncer, IS, Jiffy } from "@green24/js-utils";
import { AppUtils } from "../../../utils/AppUtils";
import root from "window-or-global";
import withConfiguration from "../../hoc/withConfiguration";
import { APP_CONSTANTS, APP_ROUTE } from "../../../models/constants/AppConstants";
import { connect } from "react-redux";
import { applicationSelector } from "../../../store/selectors/selectors";
import { applicationThunk } from "../../../store/store";
import { ValueUpdateListeners } from "../../../utils/ValueUpdateListeners";

class IdleTimeout extends Component {
	constructor(props) {
		super();

		this._debouncer = new Debouncer(1000);
		this._idleTimeout = null;
		this._lastValue = Jiffy.formatToMs(props.configuration.redirectTimeout);
		this._lastInteraction = Date.now();
		this._valueUpdateListeners = new ValueUpdateListeners(this);
	}

	componentDidMount() {
		this._valueUpdateListeners.add(
			"props.application.idleTimeout",
			value => this._registerTimeout(value ? value.time : 0),
			false,
			(a,b) => a && b ? a.signature == b.signature : false
		);

		root.addEventListener(APP_CONSTANTS.LOCAL_STORAGE_NO_REDIRECT_EVENT_NAME, () => this._registerTimeout());
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this._valueUpdateListeners.componentDidUpdate(prevProps, prevState);
	}

	render() {
		return null;
	}

	_registerTimeout(time = this._lastValue) {
		this._clearTimeout();

		if(AppUtils.noRedirectOnIdle) return;

		this._lastInteraction = Date.now();

		time = Jiffy.formatToMs(time);

		if(time > 0) {
			//Debug
			if(AppUtils.logIdleTimeoutChange) {
				console.log(`Timeout set to ${Jiffy.readableDuration(time)} (${time}ms)`);
			}

			let remainingTime = time;
			const frequency = 1000;
			const __intervalEvent = () => {
				const {application} = this.props;

				remainingTime-= frequency;

				root.dispatchEvent(new CustomEvent(APP_CONSTANTS.IDLE_TICK_EVENT_NAME, {
					detail: {
						remaining: remainingTime,
						total: time,
						frequency,
					},
				}));

				if(remainingTime <= 0) {
					if(IS.fnc(application.idleEffect)) {
						applicationThunk.setIdleEffect();
						application.idleEffect();
					}
					else {
						route(APP_ROUTE.BASE);
					}

					//Notify that the display was inactive
					root.dispatchEvent(new CustomEvent(APP_CONSTANTS.IDLE_REDIRECT_EVENT_NAME, {
						detail: {
							waitedFor: time,
							lastInteraction: this._lastInteraction,
						}
					}));

					this._clearTimeout();
				}
			}

			this._idleTimeout = setInterval(__intervalEvent, frequency);

			this._lastValue = time;

			__intervalEvent();
		}
	}

	_clearTimeout() {
		clearInterval(this._idleTimeout);

		//Notify that the user has interacted with the display
		root.dispatchEvent(new Event(APP_CONSTANTS.IDLE_RESET));
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			//withConfiguration
			configuration: PropTypes.object,

			//Store
			application: PropTypes.object,
		}
	}
}

const mapStateToProps = state => {
	return {
		application: applicationSelector.selectData(state),
	};
};

export default connect(mapStateToProps)(withConfiguration(IdleTimeout));
