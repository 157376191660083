import { Component, Fragment } from 'preact';
import PropTypes from "prop-types";
import { combineClasses, get, ObjectUtils } from "@green24/js-utils";
import CurrencyProvider from "../../components/Price/CurrencyProvider";
import NumberSpinner from "../../components/Input/NumberSpinner";
import Price from "../../components/Price/Price";
import Interpret from "../../components/Localization/Interpret";
import ButtonsConstructor from "../../components/Input/Button/ButtonsConstructor";
import { ValueUpdateListeners } from "../../utils/ValueUpdateListeners";

class MaintenanceContentMoveSome extends Component {
	constructor() {
		super();

		this.state = {
			bills: {},
		};

		this._valueUpdateListeners = new ValueUpdateListeners(this);
	}

	componentDidMount() {
		this._valueUpdateListeners.add("props.selectedDevice.deviceID", () => {
			this.setState({
				bills: {}
			});
		});
	}

	componentDidUpdate(previousProps, previousState, snapshot) {
		this._valueUpdateListeners.componentDidUpdate(previousProps, previousState);
	}

	render({className, style, data, selectedDevice}, {bills}) {
		const dispensables = this._getDispensables(data, selectedDevice);

		return (
			<section className={combineClasses("maintenance-content-move-some", className)} style={style}>
				<div className={"bills"}>
					<CurrencyProvider currency={data.currency}>
						{
							!!dispensables.length &&
							<Interpret id={"moveSomeCashAmountsToKeepMessage"}/>
						}
						{
							dispensables.map(bill => {
								return (
									<Fragment key={bill.value}>
										<div className={"bill"}>
											<b className={"price"}><Price value={bill.value}/></b>

											<NumberSpinner
												root={bills}
												value={(bills[bill.value] || {count: 0}).count}
												min={0}
												max={bill.count}
												unit={'/' + bill.count}
												onImmediateModify={(value, path) => this._modifyBillCount({bill, count: value}, bill.value)}
											/>
										</div>
									</Fragment>
								);
							})
						}
						{
							!dispensables.length &&
							<h1><Interpret id={"deviceContentsEmpty"}/></h1>
						}
					</CurrencyProvider>
				</div>

				<nav className={"action-buttons"}>
					<ButtonsConstructor buttons={[
						{
							text: "move",
							action: () => this._moveSome(),
						}
					]}/>
				</nav>
			</section>
		);
	}

	_getDispensables(data, selectedDevice) {
		const device = get(data, "deviceCash", []).find(cash => cash.deviceID == selectedDevice.deviceID);
		return get(device, "dispensable", []);
	}

	_modifyBillCount(value, path) {
		this.setState(state => {
			const bills = {...state.bills};
			bills[path] = value;

			return {
				bills
			}
		});
	}

	_moveSome() {
		const {onMoveSome, data, selectedDevice} = this.props;
		const {bills} = this.state;

		const mustRemain = this._getDispensables(data, selectedDevice).map(dispensableBill => {
			const billData = ObjectUtils.find(bills, (_, {bill}) => bill.value == dispensableBill.value && bill.currency == dispensableBill.currency) || {};

			return {
				value: dispensableBill.value,
				currency: dispensableBill.currency,
				count: dispensableBill.count - billData.count,
			}
		});

		onMoveSome(mustRemain);
	}

	static get propTypes() {
		return {
			className: PropTypes.string,
			style: PropTypes.object,
			children: PropTypes.any,

			data: PropTypes.object.isRequired,
			selectedDevice: PropTypes.object.isRequired,
			onMoveSome: PropTypes.func.isRequired,
		}
	}

	static get stateTypes() {
		return {
			bills: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			onMoveSome: (bills) => null,
		}
	}
}

export default MaintenanceContentMoveSome;
