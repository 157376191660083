import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslationContext } from "./withTranslationContext";
import { IS, resolvePolymorphVar, StringUtils } from "@green24/js-utils";

const REGEX_VARIABLE_OPENING = /\\?[%@]{/;
const REGEX_VARIABLE_CLOSING = /}[%@]\??/;
const REGEX_VARIABLE_CONTENTS = /.*?/;
const REGEX_VARIABLE = new RegExp(REGEX_VARIABLE_OPENING.source + REGEX_VARIABLE_CONTENTS.source + REGEX_VARIABLE_CLOSING.source);

class CombineComponentsFromTranslation extends React.Component {
	_renderPart(part) {
		const {children, onString, translateContext} = this.props;

		switch (part.type) {
			case "VARIABLE":
				return resolvePolymorphVar(
					children,
					{
						object: o => o[part.value],
						array: arr => arr[part.value],
						function: f => f(part.value),
					},
					null
				);
			case "TEXT":
				return onString(part.value);
			case "TRANSLATE":
				return translateContext.translate(part.value);
		}
	}

	render({id, translationContext}) {
		const translation = translationContext.translate(id);
		let parts = this._splitIntoParts(translation);

		return (
			<bdi>
				{
					parts.map((part, i) => (
						<Fragment key={i}>
							{this._renderPart(part)}
						</Fragment>
					))
				}
			</bdi>
		);
	}

	get regexes() {
		return {
			variable: REGEX_VARIABLE,
			variableOpening: REGEX_VARIABLE_OPENING,
			variableClosing: REGEX_VARIABLE_CLOSING,
		};
	}

	_splitIntoParts(str) {
		let parts = StringUtils.splitToParts(str, this.regexes.variable);
		if(!IS.empty(parts)) {
			return parts.map(substring => this._processSubstring(substring));
		}

		return [
			{
				type: "TEXT",
				value: str,
			}
		];
	}

	_processSubstring(substring) {
		if(substring.charAt(0) == '\\') return {
			type: "TEXT",
			value: substring.slice(1),
		};

		const opening = this.regexes.variableOpening.source;
		const closing = this.regexes.variableClosing.source;
		const value = substring.replace(new RegExp(`(${opening})|(${closing})`, 'g'), '');

		switch (substring.charAt(0)) {
			case '%':
				return {
					type: "VARIABLE",
					value,
				};
			case '@':
				return {
					type: "TRANSLATE",
					value,
				};
			default:
				return {
					type: "TEXT",
					value,
				}
		}
	}

	static get propTypes() {
		return {
			children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.array]),

			id: PropTypes.string,

			//Pure string formatter
			onString: PropTypes.func,

			//Context
			translationContext: PropTypes.object,
		}
	}

	static get defaultProps() {
		return {
			onString: s => s,
		}
	}
}

export default withTranslationContext(CombineComponentsFromTranslation);
