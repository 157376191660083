import { Component as PComponent } from "preact";
import { ThemeContext } from "./ThemeContextProvider";

/**
 * A public higher-order component to access the imperative API
 */
export const withThemeContext = Component => {
	class HOC extends PComponent {
		render(props) {
			return (
				<ThemeContext.Consumer>
					{(themeContext) => (
						<Component
							{...props}
							themeContext={themeContext}
						/>
					)}
				</ThemeContext.Consumer>
			);
		}

		static get displayName() {
			return "withThemeContext(" + (Component.displayName || Component.name) + ")"
		}

		static get wrappedComponent() {
			return Component;
		}
	}

	return HOC;
};

export default withThemeContext;
