import { IS } from "./IS";
import { QueryStringManager } from "./QueryStringManager";
import { get, resolvePolymorphVar } from "../functions/generic";
import { M_ClearInvalid_DefaultOptions, M_MsToDuration_DefaultOptions } from "../models/Models_Process";

export class Process {
	/**
	 * Pad value
	 * ---
	 * Appends characters (pad) before the value until the desired character count is reached
	 * @param {*} value
	 * @param {Number} padding Padding options (length, content)
	 * @return {string|*}
	 */
	static pad(value, ...padding) {
		if(IS.empty(padding)) {return value;}
		let {length, content} = padding[0];
		if(Array.isArray(padding[0]) || padding.length > 1) {
			[length, content] = padding.length > 1 ? padding : padding[0];
		}

		if(IS.valid(value) && content) {
			let result = ``;
			let len = `${value}`.length;
			for (let i = 0; i < length - len; i++) {
				result+= content;
			}
			return result + value;
		}
		return content ? Array(length).fill(content).join('') : value;
	};

	/**
	 * Format string for SQL LIKE selector
	 * ```
	 * //Example
	 * formatLikeQuerySelector('test') => %t%%e%%s%%t%
	 * ```
	 * @param {String} str
	 * @returns {String}
	 */
	static formatLIKEQuerySelector(str) {
		if(IS.string(str)) {
			return str.replace(new RegExp(/ /, 'g'), '').split('').map(item => `%${item}%`).join('');
		}
		console.warn("Provided value is not a string", str);
		return '';
	}

	/**
	 * Format object to query string
	 * ---
	 * Converts the object structure to the url encoded string
	 * @param {Object} data
	 * @return {string}
	 */
	static objectToQueryString(data) {
		return new QueryStringManager(data).toValidDataString();
	}

	/**
	 * Format pure filter
	 * ---
	 * Retrieves only relevant data for query filter
	 * @param filterData
	 * @return {{field: String, join: String, value: *, operator: String}}
	 */
	static formatPureFilter(filterData) {
		const { field, operator, value, join } = filterData || {};
		return { field, operator, value, join }
	};

	/**
	 * @deprecated - Use TextTransform.capitalize
	 * @see TextTransform.capitalize
	 */
	static capitalize(str) {
		if(IS.string(str)) {
			return str.charAt(0).toUpperCase() + str.slice(1);
		}
		console.warn("Provided value is not a string", str);
		return str;
	};

	/**
	 * @deprecated - Use TextTransform.replaceVariables
	 * Process - Replace variable in string
	 * ---
	 * Replaces path pointers in string with provided data or solved by passed solver
	 * @param {String} str
	 * @param {Object|function(String)} dataOrSolver
	 * @param {String} variableIdentifier Variable start/end identifier string (default '%') used as "%{variableName}%"
	 * @return {String}
	 * @see TextTransform.replaceVariables
	 */
	static replaceVariablesInString(str, dataOrSolver, variableIdentifier = '%') {
		if(str) {
			return str.replace(new RegExp(variableIdentifier + `{.*?}` + variableIdentifier, 'g'), (substring) => {
				let path = substring.replace(variableIdentifier + '{', '').replace('}' + variableIdentifier, '');

				if(IS.fnc(dataOrSolver)) {
					return dataOrSolver(path);
				}
				return get(dataOrSolver, path, substring);
			});
		}
		return str;
	};

	/**
	 * @deprecated
	 * Ms to duration
	 * ---
	 * Converts milliseconds to readable duration format
	 * @param {Number} ms
	 * @param {M_MsToDuration_DefaultOptions} options
	 * @returns {string|{seconds: number, hours: number, ms: *, minutes: number, days: number}}
	 * @see Jiffy.msToDuration - Replacement
	 */
	static msToDuration(ms, options) {
		if(!IS.number(ms)) return;

		options = {
			...M_MsToDuration_DefaultOptions,
			...options,
		};
		let {returnString, hideEmpty, showSec, daysSuffix, hoursSuffix, minutesSuffix, secondsSuffix, daysPadding, hoursPadding, minutesPadding, secondsPadding} = options;

		let seconds = Math.floor(ms / 1000);
		let minutes = Math.floor(seconds / 60);
		seconds = seconds % 60;
		let hours = Math.floor(minutes / 60);
		minutes = minutes % 60;
		let days = Math.floor(hours / 24);
		hours = hours % 24;

		if(returnString) {
			let resultString = '';
			if(days > 0 || !hideEmpty) resultString+= Process.pad(days, daysPadding, '0') + daysSuffix + ' ';
			if(hours > 0 || !hideEmpty) resultString+= Process.pad(hours, hoursPadding, '0') + hoursSuffix + ' ';
			if(minutes > 0 || !hideEmpty) resultString+= Process.pad(minutes, minutesPadding, '0') + minutesSuffix + ' ';
			if((seconds > 0 || !hideEmpty) && showSec) resultString+= Process.pad(seconds, secondsPadding, '0') + secondsSuffix + ' ';
			return resultString;
		}

		return {
			ms, seconds, minutes, hours, days,
		};
	}

	/**
	 * Clear invalid
	 * ---
	 * Deletes invalid items (null, undefined, false, '', ... . According to the options) within an array/object.
	 * @param {Array|Object} value
	 * @param {M_ClearInvalid_DefaultOptions} options
	 * @param {function(item: *, opt: M_ClearInvalid_DefaultOptions): Boolean} customEvaluator Custom evaluator if further logic is necessary. True = delete item
	 * @returns {Object|Array}
	 */
	static clearInvalid(value, options, customEvaluator = () => false) {
		const opt = {
			...M_ClearInvalid_DefaultOptions,
			...options,
		};
		const __shouldDelete = item => {
			if(opt.null && item === null) return true;
			if(opt.false && IS.boolean(item)) return true;
			if(opt.undefined && item === undefined) return true;
			if(opt.emptyString && IS.string(item) && IS.empty(item)) return true;
			return customEvaluator(item, opt);
		};

		return resolvePolymorphVar(
			value,
			{
				object: o => {
					let data = {...o};
					Object.keys(o).forEach(key => {
						if(__shouldDelete(data[key])) {
							delete data[key];
						}
					});
					return data;
				},
				array: a => a.filter(item => !__shouldDelete(item)),
			},
			value
		)
	}

	static encodeObject(object) {
		let data = [];

		Object.keys(object).forEach(key => {
			const item = object[key];
			if (IS.array(item)) {
				data.push(item.map((_, i) => {
					return key + "=" + encodeURIComponent(item[i]);
				}).join("&"));
			} else {
				IS.valid(item) && data.push(key + "=" + encodeURIComponent(item));
			}
		});

		return data.join("&");
	}

	/**
	 * Data URI to Blob
	 * ---
	 * @param {string} dataURI
	 * @returns {Blob}
	 */
	static dataURIToBlob(dataURI) {
		 // convert base64 to raw binary data held in a string
		// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
		const byteString = atob(dataURI.split(',')[1]);

		// separate out the mime component
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to an ArrayBuffer
		const ab = new ArrayBuffer(byteString.length);

		// create a view into the buffer
		const ia = new Uint8Array(ab);

		// set the bytes of the buffer to the correct values
		for (let i = 0; i < byteString.length; i++) {
		  ia[i] = byteString.charCodeAt(i);
		}

		// write the ArrayBuffer to a blob, and you're done
		return new Blob([ab], {type: mimeString});
	}
}
