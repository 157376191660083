import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { combineClasses, IS } from "@green24/js-utils";
import { ComponentUtils } from "../../../utils/ComponentUtils";
import { M_PropTypes_Shared } from "../../../models/Models_PropTypes";
import Interpret from "../../Localization/Interpret";
import Fa from "../../Fa";
import { InputUtils } from "../../../utils/InputUtils";
import Spinner from "../../Spinner";

//FFS: Prepared for a future use if a progress will be required
const Progress = <div/>;

class ButtonComponent extends React.Component {
	_renderIcon() {
		const {icon} = this.props;

		return (
			ComponentUtils.resolvePolymorphProp(
				icon,
				{
					string: s => <Fa icon={s}/>,
					function: f => f(icon),
				},
				() => icon,
			)
		)
	}

	_renderButtonContent() {
		const {children, spinner, spinnerProps, progress, progressProps, text, textTranslated, translationParams, prefix, suffix} = this.props;

		if(spinner) {
			return <Spinner block {...spinnerProps}/>;
		}
		else if((IS.number(progress) || progress === true)) {//Can be 0
			return <Progress progress={progress} displayState={IS.number(progress)} {...progressProps}/>;
		}
		else {
			if(children) {
				return children;
			}

			const translator = s => <span>{textTranslated ? s : <Interpret id={s} wrap={null} params={translationParams}/>}</span>;
			return (
				<Fragment>
					{prefix}
					{this._renderIcon()}
					{
						ComponentUtils.propagateProps(
							progress &&
							<Progress progress={progress} displayState={IS.number(progress)} {...progressProps}/>
							||
							ComponentUtils.resolvePolymorphProp(
								text,
								{
									string: translator,
									array: translator,
									function: translator,
								},
								() => text,
							),
							{
								className: "button-content"
							},
							true
						)
					}
					{suffix}
				</Fragment>
			)
		}
	}

	_renderButton() {
		const {disabled, buttonProps, onClick, confirm, type, tabIndex} = this.props;

		return (
			<button
				disabled={disabled}
				onClick={confirm ? undefined : onClick}
				type={type}
				tabIndex={tabIndex}
				{...buttonProps}
			>
				{this._renderButtonContent()}
			</button>
		)
	}

	render({className, style, elevation, outline, accent, disabled, spinner, progress, onClick}) {
		return (
			<div
				className={combineClasses(
					"btn",
					className,
					elevation && (elevation >= 0 || elevation === true ? `contained elevation-${elevation}` : "text"),
					outline && "outline",
					accent ? accent : "highlight",
					disabled && "disabled",
					IS.number(progress) || progress === true && "with-progress",
					spinner && "with-spinner",
				)}
				style={style}
				onClick={e => disabled && InputUtils.isDeveloperClick(e, disabled, (e) => onClick(e))}
			>
				{this._renderButton()}
			</div>
		);
	}

	static get propTypes() {
		return {
			...M_PropTypes_Shared,
			disabled: PropTypes.bool,
			progress: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]), // 0 - 100; undefined|null|false = not activated;
			progressProps: PropTypes.object,
			spinner: PropTypes.bool,
			spinnerProps: PropTypes.object,
			icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array, PropTypes.func]),
			textTranslated: PropTypes.bool,
			type: PropTypes.string,
			tabIndex: PropTypes.number,
			translationParams: PropTypes.any,
			prefix: PropTypes.any,
			suffix: PropTypes.any,

			onClick: PropTypes.func,
		}
	}

	static get defaultProps() {
		return {
			type: "button",
			accent: "primary",
		}
	}
}

export default ButtonComponent;
