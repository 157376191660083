import { ArrayUtils } from "./ArrayUtils";
import { resolvePolymorphVar } from "../functions/generic";
import { Calc } from "./Math/Calc";
import { Vec2 } from "./Math/Vector2";
import { ObjectUtils } from "./ObjectUtils";
import { IS } from "./IS";

export class Accelerator {
	constructor(callback, curve = {0: 0, 1: 1}, transition) {
		this.callback = callback;

		this.curve = curve;
		this._transition = transition;

		//Tick count
		this.time = 0;
		this.speed = 0;
	}

	set curve(curveData) {
		this._curve = this._processCurve(curveData);

		if(this._curve.length < 2) throw Error("There is not enough curve points for the Accelerator to work property. Must be at least 2.");
	}

	get curve() {
		return this._curve;
	}

	set time(time) {
		let {time: timeLimits} = this._getLimits();

		this._time = Calc.clamp(time, Math.min(timeLimits.x, timeLimits.y), Math.max(timeLimits.x, timeLimits.y));
	}

	get time() {
		return this._time;
	}

	set speed(speed) {
		let {speed: speedLimits} = this._getLimits();

		this._speed = Calc.clamp(speed, Math.min(speedLimits.x, speedLimits.y), Math.max(speedLimits.x, speedLimits.y));
	}

	get speed() {
		return this._speed;
	}

	get isStopped() {
		return this._time == this.curve[0].time;
	}

	accelerate(step) {
		this.time+= step;

		this._changeSpeed(this._findCurrentSpeed());
	}

	decelerate(step) {
		this.time-= step;

		this._changeSpeed(this._findCurrentSpeed());
	}

	reset(triggerCallback = false) {
		this.time = 0;

		if(triggerCallback) {
			this._changeSpeed(0);
		}
		else {
			this.speed = 0;
		}
	}

	_processCurve(curve) {
		return resolvePolymorphVar(
			curve,
			{
				array: arr => {
					if(IS.property(arr[0], "time", "speed")) return arr;

					return arr.map((speed, time) => ({time, speed}));
				},
				object: o => ObjectUtils.map(o, (time, speed) => ({
					time: parseFloat(time),
					speed
				})),
				function: f => f(),
			},
			() => [],
			true
		);
	}

	_getLimits() {
		let first = this.curve[0];
		let last = ArrayUtils.lastItem(this.curve);

		return {
			time: Vec2.static(first.time, last.time),
			speed: Vec2.static(first.speed, last.speed),
		}
	}

	_findCurrentSpeed() {
		for(let i = 0; i < this.curve.length; i++) {
			if(this.curve[i].time > this.time) {
				return this.curve[Math.max(i - 1, 0)].speed;
			}
		}
		return ArrayUtils.lastItem(this.curve).speed;
	}

	_changeSpeed(speed) {
		if(this._transition) {
			let low = ArrayUtils.returnPreviousBeforeMatch(this.curve, ({time}) => time > this.time, true) || this.curve[0];
			let high = this.curve.find(({time}) => time > this.time) || ArrayUtils.lastItem(this.curve);

			let timeDiffAlpha = Calc.invLerp(this.time, low.time, high.time);
			this.speed = (high.speed - low.speed) * this._transition(timeDiffAlpha);
		}
		else {
			this.speed = speed;
		}

		this.callback(this.speed);
	}
}
