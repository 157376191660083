import { E_GreenSocket_MessageTypes } from "./constants/GreenSocketConstants";

/**
 * @type {{
 *  destination: String,
 *  id: Number,
 *  type: String
 *  data: *,
 * }}
 */
export const M_GreenSocketMessage = {
	type: E_GreenSocket_MessageTypes.MESSAGE,
	id: undefined,
	destination: undefined,
	data: null,
};

export const M_GreenSocketOptions = {
	disconnectedRefreshTime: 5000,
};
